<template>
	<loading-storefront v-if="state.loading" />
	<storefront v-else />

</template>
<script lang="ts">
import { computed, defineComponent, onMounted, PropType, reactive } from "vue";
import Storefront from "@/components/Storefront.vue";
import LoadingStorefront from "@/components/LoadingStorefront.vue";
import { useStorefrontStore } from '@/stores/StorefrontStore';
import { useRoute } from "vue-router";
import sleep from "@/util/sleep";

export default defineComponent({
	components: { Storefront, LoadingStorefront },
	setup() {
		const state = reactive({
			loading: true,
		});

		const route = useRoute();

		
		const storeStore = useStorefrontStore();

		onMounted(async() => {
			state.loading = true;
			const wait = sleep(.75);
			/* @ts-ignore */
			await storeStore.loadStore(route.params.slug);
			wait.then(() => state.loading = false);
		})

		
		

		return {
			state,
		}
	}
})
</script>
