<template>
	<div class="bg-slate-100 rounded-lg
		text-lg text-gray-800
		flex items-stretch self-center">
		<button id="show-modal" @click="newWalletStore.toggleBalanceModal()"
			class="hover:bg-slate-400 rounded-l-lg">
			<div class="leading-none space-x-2 py-1 px-3 rounded-l-lg hover:bg-slate-400
				flex items-baseline
				cursor-pointer
				border-r border-slate-300">
				<span><i class="fa fa-wallet text-xl" /></span>
				<span class="hidden md:block">
					<span v-if="readableBpxBalance > 0">
						{{ $format(readableBpxBalance) }}
						{{ $token }}
					</span>
					<span v-else-if="readablePendingAuctionCredit > 0">
						${{ $format(
							readablePendingAuctionCredit / 100
						) }} USD<sup>*</sup>
					</span>
					<span v-else-if="readableAuctionCredit > 0">
						${{ $format(
							readableAuctionCredit
						) }} {{ $token }}
					</span>
				</span>
			</div>
		</button>
		<router-link
			:to="{ name: 'account' }"
			class="flex items-center space-x-3 px-3 py-2 rounded-r-lg hover:bg-slate-400">
				<div class="leading-none flex justify-center items-center">
					@{{ authStore.user.username }}
				</div>
				<div class="bg-gray-500 w-7 h-7 rounded-full"></div>
		</router-link>
		<DepositModal v-if="newWalletStore.balanceModalVisible" />
	</div>
</template>
<script lang="ts">
import { useAuthStore } from "@/stores/AuthStore";
import { useNewWalletStore } from "@/stores/NewWalletStore";
import { defineComponent, PropType, computed, onMounted, reactive, watch, ref } from "vue";
import DepositModal from './DepositModal.vue';
import { BN } from "bn.js";
import Currency from "@/types/Currency";
export default defineComponent({
	components: { DepositModal },
	setup(props) {
		const authStore = useAuthStore()
		const newWalletStore = useNewWalletStore();

		const readableBpxBalance = computed(() => {
			return (new Currency(newWalletStore.balances.reduce(function(bnBalance, bal) {
				if (bal.slug == 'bpx' || bal.slug == 'vbpx') {
					return bnBalance.add( new BN(bal.available) );
				}

				return bnBalance;
			}, new BN("0")))).toDecimal();
		});

		const readablePendingAuctionCredit = computed(() => {
			return newWalletStore.balances.find((b) => b.slug == 'usd-auction-credit')?.available ?? 0;
		});

		const readableAuctionCredit = computed(() => {
			return (new Currency(
				newWalletStore.balances.find((b) => b.slug == 'auction-credit')?.available ?? new BN("0")
			)).toDecimal();
		});

		return {
			authStore,
			newWalletStore,

			Currency,
			BN,

			readableBpxBalance,
			readablePendingAuctionCredit,
			readableAuctionCredit,
		}
	},
})
</script>
