<template>
	<div>
		<h2 class="font-bold text-2xl text-left">Withdraw BPX</h2>

		<div
			v-if="state.txInProgress"
			class="my-6 grid grid-cols-[auto,1fr] gap-4 items-start p-4 bg-sky-200 text-slate-950 rounded-lg"
		>
			<div class="text-4xl animate-spin text-blue-600">
				<i class="far fa-spinner-third"></i>
			</div>
			<div>
				<div class="leading-10">Waiting on blockchain...</div>
			</div>
		</div>
		<div
			v-else-if="state.txComplete"
			class="my-6 grid grid-cols-[auto,1fr] gap-4 items-start p-4 bg-emerald-200 text-slate-950 rounded-lg"
		>
			<div class="text-4xl text-emerald-600">
				<i class="far fa-circle-check"></i>
			</div>
			<div>
				<div class="leading-10 font-bold">Transaction complete!</div>
				<div>
					<a
						:href="`https://etherscan.io/tx/${state.tx.transactionHash}`"
						class="underline hover:no-underline"
						target="_blank"
						><i class="far fa-external-link"></i> View your
						transaction</a
					>
				</div>
			</div>
		</div>
		<div
			v-else-if="state.txError"
			class="my-6 grid grid-cols-[auto,1fr] gap-4 items-start p-4 bg-red-200 text-slate-950 rounded-lg"
		>
			<div class="text-4xl text-red-600">
				<i class="far fa-circle-exclamation"></i>
			</div>
			<div>
				<div class="leading-10">Something went wrong!</div>

				<div>
					Try again in a few minutes. If you're still having trouble,
					reach out to Blokpax support at
					<a
						href="mailto:help@blokpax.com"
						class="underline hover:no-underline"
						>help@blokpax.com</a
					>.
				</div>
			</div>
		</div>

		<div class="my-6">
			<wallet-connected>
				<template #default>
					<div class="mt-12">
						<button
							class="w-full text-xl font-medium bg-slate-400 text-slate-600 enabled:bg-blue-600 enabled:text-slate-50 enabled:hover:saturate-150 p-4 text-center rounded-lg transition-all"
							@click="metamask.connect"
						>
							Connect Wallet
						</button>
					</div>
				</template>

				<template #connected>
					<div>
						<div
							class="text-center bg-slate-200 text-slate-800 py-4 rounded-lg my-6"
						>
							<div class="text-base font-bold">
								You are withdrawing to:
							</div>
							<div class="text-lg font-mono">
								<wallet-address
									:address="metamask.state.wallet"
								/>
							</div>
						</div>

						<div
							class="flex items-center my-12 text-center border border-slate-400 rounded-md w-full group"
						>
							<input
								class="flex-grow font-medium text-left text-4xl/10 border-0 focus:border-0 focus:ring-0 bg-transparent placeholder:text-slate-400"
								type="tel"
								v-model="state.currentWithdrawalAmount"
								placeholder="1337"
								@keyup.enter="executeWithdrawal"
							/>

							<span class="text-2xl/10 font-bold flex-none px-2"
								>BPX</span
							>
						</div>

						<div class="my-6">
							<button
								class="w-full text-xl font-medium bg-slate-400 text-slate-600 enabled:bg-blue-600 enabled:text-slate-50 enabled:hover:saturate-150 p-4 text-center rounded-lg transition-all"
								:disabled="!state.currentWithdrawalAmount"
								@click="executeWithdrawal"
							>
								Submit
							</button>
						</div>
					</div>
				</template>
			</wallet-connected>

			<div class="mt-6 text-center">
				<button
					class="text-base underline hover:no-underline text-slate-700 mx-auto"
					type="button"
					@click="newWalletStore.toggleBalanceModal(false)"
				>
					Close
				</button>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { useNewWalletStore } from '@/stores/NewWalletStore'
import { wasNotDeclined } from '@/util/Errors'
import { BN } from 'bn.js'
import { defineComponent, PropType, reactive, computed } from 'vue'
import Bugsnag from '@bugsnag/js'
import IGatewayModule from '@/abi/IGatewayModule.json'
import { useAuthStore } from '@/stores/AuthStore'
import metamask from '@/util/metamask'
import sleep from '@/util/sleep'

const ETH_SAFE_GATEWAY_ADDRESS = import.meta.env.VITE_ETH_SAFE_GATEWAY_ADDRESS

export default defineComponent({
	setup(props) {
		const state = reactive({
			currentWithdrawalAmount: null,
			txInProgress: false,
			txComplete: false,
			txError: null,
			tx: null,
		})

		const newWalletStore = useNewWalletStore()
		const authStore = useAuthStore()

		const withdrawableBpxBalance = computed(() => {
			const bal = newWalletStore.balances.find((b) => b.slug == 'bpx')

			if (!bal) {
				return new BN('0')
			}

			return new BN(bal.available)
		})

		async function executeWithdrawal() {
			state.txError = null
			state.txInProgress = true
			state.txComplete = false

			if (metamask.state.chain !== import.meta.env.VITE_BPX_CHAIN) {
				const res = await metamask.requestChain(
					import.meta.env.VITE_BPX_CHAIN
				)
				if (!res) {
					state.txInProgress = false
					return
				}
			}

			const id = authStore.user
			const wallet = metamask.state.wallet

			const marketplace = await metamask.loadContract(
				IGatewayModule,
				ETH_SAFE_GATEWAY_ADDRESS
			)

			const asset = `1:${
				import.meta.env.VITE_BPX_ADDRESS
			}:0`.toLowerCase()
			const amount = new BN(state.currentWithdrawalAmount).mul(
				new BN(1e9)
			)

			let authedTx
			try {
				authedTx = await newWalletStore.withdraw(
					asset,
					amount.toString(),
					wallet
				)
			} catch (e) {
				console.error({ authedTx: e })
				state.txInProgress = false
				state.txError = e.toString()
				return
			}

			const execute = await marketplace.methods.execute(
				authedTx.safe,
				authedTx.destination,
				authedTx.transaction,
				false,
				authedTx.expiration,
				authedTx.identifier,
				authedTx.counter,
				authedTx.salt,
				authedTx.signature
			)

			let gasEstimate
			try {
				gasEstimate = await execute.estimateGas({
					from: metamask.state.wallet,
					gas: 500_000,
				})
			} catch (e) {
				console.error({ estimateGas: e, metamask: metamask.state })
				state.txInProgress = false
				state.txError = e.toString()
				return
			}

			gasEstimate = Math.ceil(gasEstimate * 1.3)

			const wait = sleep(1)

			const receipt = await execute
				.send({
					from: metamask.state.wallet,
					gas: gasEstimate,
					maxPriorityFeePerGas: null,
					maxFeePerGas: null,
				})
				.on('receipt', async (r) => {
					await wait

					console.log({ receipt: r })

					state.txComplete = true
					state.txInProgress = false
					state.tx = r
				})
				.catch((err) => {
					state.txInProgress = false

					if (wasNotDeclined(err)) {
						state.txError = err
						Bugsnag.notify(err)
					}
				})
		}

		return {
			state,
			withdrawableBpxBalance,
			executeWithdrawal,
			newWalletStore,
			metamask,
		}
	},
})
</script>
