<template>
	<div>
		<h2 class="font-bold text-2xl text-left">Deposit BPX</h2>

		<div
			v-if="state.txInProgress"
			class="my-6 grid grid-cols-[auto,1fr] gap-4 items-start p-4 bg-sky-200 text-slate-950 rounded-lg"
		>
			<div class="text-4xl animate-spin text-blue-600">
				<i class="far fa-spinner-third"></i>
			</div>
			<div>
				<div class="leading-10">Waiting on blockchain...</div>
			</div>
		</div>
		<div
			v-else-if="state.txComplete"
			class="my-6 grid grid-cols-[auto,1fr] gap-4 items-start p-4 bg-emerald-200 text-slate-950 rounded-lg"
		>
			<div class="text-4xl text-emerald-600">
				<i class="far fa-circle-check"></i>
			</div>
			<div class="space-y-4">
				<div class="leading-10 font-bold">Transaction complete!</div>
				<div>Deposited assets may take up to five minutes to appear in your account.</div>
				<div>
					<a
						:href="`https://etherscan.io/tx/${state.tx.transactionHash}`"
						class="underline hover:no-underline"
						target="_blank"
						><i class="far fa-external-link"></i> View your
						transaction</a
					>
				</div>
			</div>
		</div>
		<div
			v-else-if="state.txError"
			class="my-6 grid grid-cols-[auto,1fr] gap-4 items-start p-4 bg-red-200 text-slate-950 rounded-lg"
		>
			<div class="text-4xl text-red-600">
				<i class="far fa-circle-exclamation"></i>
			</div>
			<div>
				<div class="leading-10">Something went wrong!</div>

				<div>
					Try again in a few minutes. If you're still having trouble,
					reach out to Blokpax support at
					<a
						href="mailto:help@blokpax.com"
						class="underline hover:no-underline"
						>help@blokpax.com</a
					>.
				</div>
			</div>
		</div>

		<wallet-connected>
			<template #default>
				<div class="mt-12">
					<button
						class="w-full text-xl font-medium bg-slate-400 text-slate-600 enabled:bg-blue-600 enabled:text-slate-50 enabled:hover:saturate-150 p-4 text-center rounded-lg transition-all"
						@click="metamask.connect"
					>
						Connect Wallet
					</button>
				</div>
			</template>

			<template #connected>
				<div>
					<div class="grid md:grid-cols-2 gap-4 mt-6">
						<div
							class="text-center bg-slate-200 text-slate-800 p-4 rounded-lg"
						>
							<div class="text-base font-bold">
								Depositing from:
							</div>
							<div class="text-lg font-mono">
								<wallet-address
									:address="metamask.state.wallet"
								/>
							</div>
						</div>

						<div
							class="text-center bg-slate-200 text-slate-800 p-4 rounded-lg"
						>
							<div class="text-base font-bold">
								Balance in wallet:
							</div>
							<div class="text-lg">
								{{
									state.currentBalance
										? $format(
												humanReadablePrice(
													state.currentBalance
												)
										  )
										: '0'
								}}
								BPX
							</div>
						</div>
					</div>

					<div
						class="flex items-center my-12 text-center border border-slate-400 rounded-md w-full"
					>
						<input
							class="flex-grow font-medium text-left text-4xl/10 border-0 focus:border-0 focus:ring-0 bg-transparent placeholder:text-slate-400"
							type="tel"
							v-model="state.currentDepositAmt"
							placeholder="1337"
							@keyup.enter="executeDeposit"
						/>

						<span class="text-2xl/10 font-bold flex-none px-2"
							>BPX</span
						>
					</div>

					<div class="mt-6">
						<button
							class="w-full text-xl font-medium bg-slate-400 text-slate-600 enabled:bg-blue-600 enabled:text-slate-50 enabled:hover:saturate-150 p-4 text-center rounded-lg transition-all"
							@click="executeDeposit"
							:disabled="!state.currentDepositAmt"
						>
							Submit
						</button>
					</div>
				</div>
			</template>
		</wallet-connected>

		<div class="mt-6 text-center">
			<button
				class="text-base underline hover:no-underline text-slate-700 mx-auto"
				type="button"
				@click="newWalletStore.toggleBalanceModal(false)"
			>
				Close
			</button>
		</div>
	</div>
</template>
<script lang="ts">
import { useNewWalletStore } from '@/stores/NewWalletStore'
import { wasNotDeclined } from '@/util/Errors'
import { BN } from 'bn.js'
import { defineComponent, PropType, reactive, computed, onMounted } from 'vue'
import Bugsnag from '@bugsnag/js'
import sleep from '@/util/sleep'
import metamask from '@/util/metamask'
import Currency from '@/types/Currency'
import IERC20 from '@/abi/IERC20.json'
import { BPX_ADDRESS } from '@/util/escrow'
import { humanReadablePrice } from '@/util/currencyFormat'
const ETH_SAFE_ADDRESS = import.meta.env.VITE_ETH_SAFE_ADDRESS

export default defineComponent({
	setup(props) {
		const state = reactive({
			currentDepositAmt: null,
			txInProgress: false,
			txComplete: false,
			txError: null,
			tx: null,
			currentBalance: null,
		})

		const newWalletStore = useNewWalletStore()

		async function executeDeposit() {
			state.txError = null
			state.txInProgress = true
			state.txComplete = false

			if (metamask.state.chain !== import.meta.env.VITE_BPX_CHAIN) {
				const res = await metamask.requestChain(
					import.meta.env.VITE_BPX_CHAIN
				)
				if (!res) {
					state.txInProgress = false
					return
				}
			}

			const deposit = await metamask.loadContract(IERC20, BPX_ADDRESS)

			const tx = await deposit.methods.transfer(
				ETH_SAFE_ADDRESS,
				Currency.fromDecimal(state.currentDepositAmt).toString()
			)

			let gasEstimate
			try {
				gasEstimate = await tx.estimateGas({
					from: metamask.state.wallet,
					gas: 500_000,
				})
			} catch (e) {
				console.error({ estimateGas: e, metamask: metamask.state })
				state.txInProgress = false
				state.txError = e.toString()
				return
			}

			gasEstimate = Math.ceil(gasEstimate * 1.3)
			const wait = sleep(1)

			const receipt = await tx
				.send({
					from: metamask.state.wallet,
					gas: gasEstimate,
					maxPriorityFeePerGas: null,
					maxFeePerGas: null,
				})
				.on('receipt', async (r) => {
					await wait

					console.log({ receipt: r })

					state.txComplete = true
					state.txInProgress = false
					state.tx = r
				})
				.catch((err) => {
					state.txInProgress = false

					if (wasNotDeclined(err)) {
						state.txError = err
						Bugsnag.notify(err)
					}
				})
		}

		async function updateBalance() {
			if (!metamask.state.wallet) {
				state.currentBalance = null
				return
			}

			if (metamask.state.chain != import.meta.env.VITE_BPX_CHAIN){
				state.currentBalance = null
				return
			}

			const contract = await metamask.loadContract(IERC20, BPX_ADDRESS)
			const bal = await contract.methods
				.balanceOf(metamask.state.wallet)
				.call()

			state.currentBalance = bal
		}

		metamask.on('connected', updateBalance)
		metamask.on('chainChanged', updateBalance)
		updateBalance()

		return {
			state,
			executeDeposit,
			newWalletStore,
			metamask,
			humanReadablePrice,
		}
	},
})
</script>
