<style lang="css" scoped>
	h1 {
		margin-bottom: 1rem;
		margin-top: 2rem;
		font-weight: bold;
	}
	h2 {
		margin-bottom: .7rem;
	}
	h3 {
		margin-bottom: .7rem;
	}
	p {
		margin-bottom: 1rem;
	}
	.pp a {
		color: blue !important;
		text-decoration: underline;
	}
	.header {
		text-align: center;
		padding: 20px;
	}
	.list {
		padding: 20px;
	}
	.list oi[title]::before {
		content: attr(title);
		display: block;
		font-weight: bold;
		padding: 4px;
	}
	.list li.title {
		font-weight: bold;
	}
	.list li.title li {
		font-weight: normal;
		margin-top: 5px;
	}
	.list ol {
		list-style-type: none;
		counter-reset: item;
		margin: 0 0 20px 0;
		padding: 0;
	}

	.list ol > li {
		display: table;
		counter-increment: item;
		margin-bottom: 0.6em;
	}

	.list ol > li:before {
		content: counters(item, ".") ". ";
		display: table-cell;
		padding-right: 0.6em;
	}

	.list li ol > li {
		margin: 0;
	}

	.list li ol > li:before {
		content: counters(item, ".") " ";
	}
</style>
<template>
	<div>
		<div class="max-w-screen-lg py-16 mx-auto items-center">
			<div class="text-black">
				<h1 class="text-4xl lg:text-5xl font-semibold leading-tight">Blokpax Terms of Use</h1>
			</div>
		</div>
	</div>

    <div class="mx-3 md:max-w-screen-lg lg:mx-auto pp">
		<div class="">
			<h3 class="text-2xl">Last updated on March 28, 2023</h3>
			<p>Please read these Terms of Use and our <a
	   href="https://www.iubenda.com/privacy-policy/51289722">Privacy
	   Policy</a> carefully as they govern your use of the website of BPX
   Collective, LLC (“Blokpax”, “we”, or “us”) and all of its subsidiary
   websites and services (including but not limited to, blokpax.com,
   wondersotf.xyz, barefootrepublic.xyz, lostminers.xyz,
   bantambrigade.xyz).</p>
			<p>We provide this website (the “Site”), applications (the “App”),
				products, and services (collectively, the “Services”) to you subject to
				the following terms of use and any additional terms incorporated herein
				by reference (collectively, the “Terms”). Before you use the Services,
				you must agree to these Terms.</p>
			<p><strong>THE TERMS INCLUDE AN ARBITRATION PROVISION. PLEASE READ THESE
					“BINDING ARBITRATION” AND “CLASS WAIVER” PROVISIONS CAREFULLY, AS THEY
					AFFECT YOUR RIGHTS AND REQUIRE THAT ANY PAST, PENDING, OR FUTURE
					DISPUTES BETWEEN YOU AND US SHALL BE RESOLVED BY FINAL AND BINDING
					ARBITRATION ON AN INDIVIDUAL AND NOT A CLASS-WIDE OR CONSOLIDATED BASIS.
					IF YOU DO NOT WISH TO BE SUBJECT TO ARBITRATION, INCLUDING ON A
					RETROACTIVE BASIS, YOU MAY OPT OUT OF THE ARBITRATION PROVISION WITHIN
					THIRTY (30) DAYS BY FOLLOWING THE INSTRUCTIONS PROVIDED AT THE END OF
					THE SECTION TITLED “BINDING ARBITRATION AND CLASS WAIVER.” OTHERWISE, BY
					USING THE SERVICES, OR BY CLICKING “I ACCEPT” OR OTHERWISE INDICATING
					YOUR ACCEPTANCE, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE
					BOUND BY THE ARBITRATION PROVISION AND THESE TERMS.</strong></p>
			<p><strong><u>YOU MUST BE A LEGAL RESIDENT OF THE U.S. TO USE THE
						SERVICES. NO ONE UNDER THE AGE OF 18 MAY USE THE
						SERVICES.</u></strong></p>
			<p>PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SERVICE. WE ARE
				WILLING TO MAKE THE SERVICES AVAILABLE TO YOU ONLY IF YOU ACCEPT ALL OF
				THESE TERMS. <strong>BY USING THE SERVICES OR ANY PART OF THEM, OR BY
					CLICKING “I ACCEPT,” OR BY PURCHASING OR RECEIVING A BLOKPAX NFT (AS
					DEFINED BELOW), OR OTHERWISE INDICATING YOUR ACCEPTANCE, YOU ARE
					CONFIRMING THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE
					TERMS.</strong> THESE TERMS REPRESENT THE COMPLETE AGREEMENT BETWEEN YOU
				AND BLOKPAX CONCERNING THE SERVICES, UNLESS WE HAVE EXECUTED A SEPARATE
				WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. IF YOU ARE ACCEPTING THESE
				TERMS ON BEHALF OF ANOTHER PERSON OR ENTITY, YOU REPRESENT THAT YOU HAVE
				THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT ENTITY’S BEHALF. IF
				YOU DO NOT HAVE SUCH AUTHORITY, THEN WE ARE UNWILLING TO MAKE THESE
				SERVICES AVAILABLE TO YOU. IF YOU DO NOT AGREE TO ALL OF THESE TERMS,
				THEN YOU MAY NOT ACCESS OR USE THE SERVICES. </p>
			<h3 class="text-2xl">Updates and Amendments to Terms of Use and Privacy
				Policy</h3>
			<p>You understand and agree that Operator may from time to time make
				changes or otherwise amend these Terms of Use, Official Rules or the
				Privacy Policy. Operator may, but shall not be required to, provide
				notice to You of any changes or amendments, and to the extent any such
				obligation under law exists notwithstanding this provision, You agree
				that such obligation would be satisfied by sending an email to the email
				address associated with your Account. The current <a
														  href="https://blokpax.com/terms-of-service">Terms of
														  Use</a>, <a
							 href="https://www.iubenda.com/privacy-policy/51289722">Privacy
							 Policy</a> and <a
						   href="https://app.blokpax.com/drops/rules">Official
						   Rules</a> will be posted on the Site. It shall be your obligation to
					   check the Terms, Policy and Rules each time you use the Services. You
					   agree that your continued use of the Services after that date will
					   constitute your acceptance of and agreement to such changes.</p>
			<p>You may not use these Services if you do not agree to these Terms, if
				you are not of legal age to enter into these Terms in your jurisdiction
				of residence, or if you are prohibited from using the Services or any of
				our contents, products, or services by applicable law.</p>
			<p>No one at Blokpax is authorized to modify these Terms with you or
				otherwise enter into an agreement with you that conflicts with these
				Terms except by means of a written agreement signed by an authorized
				agent of Blokpax, and any purported modifications, alterations, or
				conflicting terms will be null and void.</p>
			<p>PLEASE READ THESE TERMS CAREFULLY, SINCE THEY INCLUDE IMPORTANT
				INFORMATION CONCERNING YOUR RIGHTS AND OBLIGATIONS, ALONG WITH
				CONDITIONS, LIMITATIONS, AND EXCLUSIONS THAT MAY BE RELEVANT TO YOU.</p>

			<div class="list">


				<ol type="1">
					<li>
						<p>
							<strong>USE OF THE SERVICES</strong>
						</p>
						<ol type="1">
							<li><p>To make use of the Services, you must create an account. When you
									create an account, you must provide accurate and complete current
									information, and you must promptly update the information as necessary
									when it changes.</p></li>
							<li><p>You are responsible for the security of your account. You agree
									not to permit any third party to access the Services through your
									account, and you bear all responsibility for any such access or use. If
									you become aware of any suspicious or unauthorized use of your account
									with us, you agree to notify us immediately at
									support@blokpax.com.</p></li>
							<li><p>The Services, and all information contained therein, are provided
									on an “as is” basis.</p></li>
							<li><p>The Services may become unavailable due to maintenance or
									malfunction of equipment or other reasons, and there may be delays,
									omissions, or inaccuracies in information contained in the
									Services.</p></li>
							<li><p>The form and nature of the Services may change from time to time
									without prior notice to you. You acknowledge and agree that we may add
									new features and/or change any part of the Services at any time without
									notice.</p></li>
							<li><p>The information contained in the Services does not constitute the
									rendering of any legal, accounting, tax, or other such professional
									advice.</p></li>
							<li><p>No part of the Services may be reproduced or transmitted in any
									form, by any means, except that Blokpax authorizes you to view, copy,
									download, and print documents available through the Services, provided
									that you use the documents solely for noncommercial, informational
									purposes, that you do not modify the documents, and that you do not
									remove copyright, trademark, and other proprietary notices.</p></li>
							<li><p>Nothing contained in the Services should be construed as
									granting, by implication, estoppel, or otherwise, any license or right
									to use the Services or any documents displayed on the Services, through
									the use of framing or otherwise, except as expressly permitted by these
									Terms or with the prior written permission of Blokpax.</p></li>
							<li><p>All information, including but not limited to suggestions,
									comments, ideas, graphics, or other submissions, communicated to Blokpax
									through the Services is the exclusive property of Blokpax, and Blokpax
									is entitled to use any submitted information for any purpose without
									restriction (except as stated in our Privacy Policy) and without
									compensation to the person who submitted said information. The user
									acknowledges and warrants the originality of any submission and accepts
									all responsibility for its accuracy, appropriateness, and
									legality.</p></li>
						</ol>
					</li>

					<li>
						<p>
							<strong>SWEEPSTAKES</strong>
						</p>
						<ol type="1">
							<li><p>The Services may from time to time include contests in the nature
									of sweepstakes promotions (“Sweepstakes”) or other contests. Each such
									Sweepstakes or contest will be governed by a set of official rules that
									will be published on this site and available for review. To view current
									Sweepstakes rules, click <a
									   href="https://app.blokpax.com/drops/rules">here</a> and then select the
								   “<u>Official Rules</u>” link for any Available Drop.</p></li>
						</ol>
					</li>


					<li>
						<p>
							<strong>TRANSACTIONS ON THE BLOCKCHAIN</strong>
						</p>
						<ol type="1">
							<li><p>Transactions that take place via the Services are, for some uses,
									managed and confirmed via the blockchain networks (the “Blockchain
									Network”). You understand and agree that your Blockchain Network public
									address will be made publicly available whenever you participate in a
									transaction via the Services.</p></li>
							<li><p>Payments or financial transactions that you engage in via the
									Services may be conducted through a Blockchain Network. We have no
									control over these transactions, and we cannot reverse them.
									Accordingly, <strong>we cannot and do not provide refunds for any
										purchases that you may make via the Services</strong>. You acknowledge
									and agree that we have no liability to you or any third party for any
									claims or damages that may arise in connection with any payments or
									transactions you engage in via the Services.</p></li>
							<li><p>Every transaction conducted through the Blockchain Network
									requires the payment of a transaction fee that helps to fund the
									computers that run the Blockchain Network (the “Gas Fee”). Except as
									otherwise expressly stated in these Terms, you will be solely
									responsible for paying any Gas Fee for any transaction you instigate via
									the Services, unless Blokpax chooses, in its sole discretion, to pay the
									Gas Fee on your behalf.</p></li>
							<li><p>Royalty. Transfers of a Blokpax-generated non-fungible token
									(“Blokpax NFT”), whether via the Services or on another marketplace, may
									request an automatic transfer to Blokpax of a royalty, calculated as a
									percentage of the consideration paid for the Blokpax NFT in the
									transfer, such percentage to be established and modified from time to
									time by Blokpax. The percentage, if applicable is included in the
									Blokpax NFT metadata and may be voluntarily enforced by third-party
									platforms.</p></li>
							<li><p>You are solely responsible to pay any and all taxes, other than
									taxes on our net income, now or hereafter claimed or imposed by any
									governmental authority in connection with your use of the Services
									(“Taxes”). These Taxes include, but are not limited to, all sales,
									value-added, and other taxes, duties, and assessments associated with
									your use of the Services. You will pay or reimburse us for all national,
									federal, state, local, or other taxes and assessments of any
									jurisdiction (other than income taxes levied on us for our net income),
									including value-added taxes and taxes as required by international tax
									treaties, customs or other import or export taxes, and amounts levied in
									lieu thereof based on charges set, services performed, or payments made
									hereunder; and you agree that you will not be entitled to deduct the
									amount of any such taxes, duties, or assessments from payments
									(including Gas Fees) made to us in connection with the
									Services.</p></li>
							<li><p>Blokpax reserves the right to refuse service under these terms
									when so required by any of its third-party service providers, to the
									fullest extent allowed by applicable law.</p></li>
							<li><p>You are solely responsible for all Blokpax NFTs and any other
									products that you obtain from Blokpax. Blokpax shall have no liability
									to you or anyone else for the use, handling, transfer, loss,
									record-keeping, account or wallet access information, storage (except as
									specifically provided below) or other disposition of your NFTs or other
									products that you obtain from Blokpax.</p></li>



							<li><p><strong>Assumption of Risk for NFTS</strong></p>
								<p><strong>You accept and acknowledge:</strong></p>
								<ol type="1">
									<li><p>The value of an NFTs is subjective. Prices of NFTs are subject to
											volatility and fluctuations in the price of cryptocurrency can also
											materially and adversely affect NFT prices. You acknowledge that you
											fully understand this subjectivity and volatility and that you may lose
											money.</p></li>
									<li><p>A lack of use or public interest in the creation and development
											of distributed ecosystems could negatively impact the development of
											those ecosystems and related applications, and could therefore also
											negatively impact the potential utility and value of NFTs.</p></li>
									<li><p>The regulatory regime governing blockchain technologies,
											non-fungible tokens, cryptocurrency, and other crypto-based items is
											uncertain, and new regulations or policies may materially adversely
											affect the development of the Service and the utility of NFTs.</p></li>
									<li><p>You are solely responsible for determining what, if any, taxes
											apply to your transactions. Blokpax is not responsible for determining
											the taxes that apply to your NFTs.</p></li>
									<li><p>There are risks associated with purchasing items associated with
											content created by third parties through peer-to-peer transactions,
											including but not limited to, the risk of purchasing counterfeit items,
											mislabeled items, items that are vulnerable to metadata decay, items on
											smart contracts with bugs, and items that may become untransferable. You
											represent and warrant that you have done sufficient research before
											making any decisions to sell, obtain, transfer, or otherwise interact
											with any NFTs or accounts/collections.</p></li>
									<li><p>We do not control the public blockchains that you are interacting
											with and we do not control certain smart contracts and protocols that
											may be integral to your ability to complete transactions on these public
											blockchains. Additionally, blockchain transactions are irreversible and
											Blokpax has no ability to reverse any transactions on the
											blockchain.</p></li>
									<li><p>There are risks associated with using Internet and blockchain
											based products, including, but not limited to, the risk associated with
											hardware, software, and Internet connections, the risk of malicious
											software introduction, and the risk that third parties may obtain
											unauthorized access to your third-party wallet or Account. You accept
											and acknowledge that Blokpax will not be responsible for any
											communication failures, disruptions, errors, distortions or delays you
											may experience when using the Service or any Blockchain network, however
											caused.</p></li>
									<li><p>The Service relies on third-party platforms and/or vendors. If we
											are unable to maintain a good relationship with such platform providers
											and/or vendors; if the terms and conditions or pricing of such platform
											providers and/or vendors change; if we violate or cannot comply with the
											terms and conditions of such platforms and/or vendors; or if any of such
											platforms and/or vendors loses market share or falls out of favor or is
											unavailable for a prolonged period of time, access to and use of the
											Service will suffer.</p></li>
									<li><p>Blokpax reserves the right to hide collections, contracts, and
											items affected by any of these issues or by other issues. Items you
											purchase may become inaccessible on Blokpax. Under no circumstances
											shall the inability to view items on Blokpax or an inability to use the
											Service in conjunction with the purchase, sale, or transfer of items
											available on any blockchains serve as grounds for a claim against
											Blokpax.</p></li>
									<li><p>If you have a dispute with one or more users, YOU RELEASE US FROM
											CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE, KNOWN AND
											UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. IN
											ENTERING INTO THIS RELEASE YOU EXPRESSLY WAIVE ANY PROTECTIONS (WHETHER
											STATUTORY OR OTHERWISE) THAT WOULD OTHERWISE LIMIT THE COVERAGE OF THIS
											RELEASE TO INCLUDE THOSE CLAIMS WHICH YOU MAY KNOW OR SUSPECT TO EXIST
											IN YOUR FAVOR AT THE TIME OF AGREEING TO THIS RELEASE.</p></li>
								</ol>
							</li>
						</ol>
					</li>


					<li>
						<p>
							<strong>OWNERSHIP AND USE RESTRICTIONS</strong>
						</p>
						<ol type="1">
							<li><p>We will only recognize your ownership of any Blokpax NFTs or
									other products if you have purchased or otherwise rightfully acquired
									such items from a legitimate source and not via any illegal, wrongful,
									or fraudulent means.</p></li>
							<li><p>When you purchase a Blokpax NFT through the Services, Blokpax
									grants you a worldwide, non-exclusive license to use and display the
									Blokpax NFT, solely for the following purposes: (a) for your own
									personal, non-commercial use; and (b) as part of the Services or other
									marketplace approved by Blokpax in its sole discretion that permits the
									purchase and sale of your Blokpax NFT.</p></li>
							<li><p>You acknowledge and agree that Blokpax (and, as applicable, our
									licensors) owns all right, title, and interest in and to all elements of
									the Services and all intellectual property rights therein (including,
									without limitation, all designs, systems, methods, information, computer
									code, software, services, “look and feel,” organization, compilation of
									the content, code, data, and all other elements of the Services
									(collectively, the “Blokpax Services Materials”)). Your rights only
									extend to any Blokpax NFTs or other products that you purchase or
									acquire, and to any specific physical assets (e.g., trading cards) whose
									ownership is conferred by the redemption of a Blokpax NFT. You
									acknowledge that the Blokpax Services Materials are protected by
									copyright, trade dress, patent, and trademark laws, international
									conventions, other relevant intellectual property and proprietary
									rights, and applicable laws. All Blokpax Service Materials are the
									copyrighted property of Blokpax or its licensors, and all trademarks,
									service marks, and trade names associated with the Services otherwise
									contained in the Blokpax Service Materials are proprietary to Blokpax or
									its licensors, or are third-party intellectual property being used under
									the fair use doctrine. Except as expressly set forth herein, your use of
									the Services does not grant you ownership of any other rights with
									respect to any content, code, data, or other Blokpax Services Materials
									that you may access via the Services. We reserve all rights in and to
									the Blokpax Services Materials that are not expressly granted to you in
									these Terms. You understand and agree that (a) your purchase or
									acquisition of any Blokpax NFT or other product via the Services does
									not give you any rights or licenses in or to the Blokpax Services
									Materials other than those expressly contained in these Terms; (b) you
									do not have the right, except as otherwise set forth in these Terms, to
									reproduce, distribute, or otherwise commercialize any elements of the
									Blokpax Services Materials without our prior written consent in each
									case, which consent Blokpax may withhold in its sole and absolute
									discretion; (c) you will not apply for, register, or otherwise use or
									attempt to use any Blokpax trademarks or service marks, or any
									confusingly similar marks, anywhere in the world without our prior
									written consent in each use, which consent Blokpax may withhold in its
									sole and absolute discretion. While you own the particular Blokpax
									NFTs and other products that you purchase or acquire, the copyright
									associated with each Blokpax NFT or other product remains with Blokpax
									or its licensors, and you may not copy the Blokpax NFT or other product,
									except as set forth in these Terms.</p></li>
							<li><p>You acknowledge and agree that you may not do any of the
									following without Blokpax’s express written consent, nor may you permit
									any third party to do any of the following without Blokpax’s express
									written consent: (a) modify a Blokpax NFT in any way; (b) use a Blokpax
									NFT to advertise, market, or sell any third party product or service;
									(c) use a Blokpax NFT in connection with any images, videos, or other
									forms of media that depict hatred, intolerance, violence, cruelty, or
									anything else that could reasonably be found to constitute hate speech
									or otherwise infringe upon the rights of others; (d) use a Blokpax NFT
									in movies, videos, or any other forms of media, except to the limited
									extent that such use is expressly permitted by these Terms or solely for
									your own personal, non-commercial use; (e) sell, distribute for
									commercial gain (including giving away in the hopes of future commercial
									gain), or otherwise commercialize merchandise that includes, contains,
									or consists of a Blokpax NFT, except as expressly permitted by these
									Terms or a set of Sweepstakes rules; (f) attempt to trademark,
									copyright, or otherwise acquire additional intellectual property rights
									in or to the Blokpax NFT, or (g) otherwise utilize the Blokpax NFT for
									your or any third party’s commercial benefit. The restrictions in this
									section will survive the termination of these Terms.</p></li>
							<li><p>You acknowledge and agree that, to the extent your Blokpax NFT
									contains any third-party intellectual property, you will not have the
									right to use such intellectual property in any way except as
									incorporated in the Blokpax NFT, and subject to the restrictions
									contained in these Terms. You further acknowledge and agree that we may
									need to pass through additional restrictions on your ability to use the
									Blokpax NFT, and that to the extent we inform you of additional
									restrictions in writing (including by modification of these Terms), you
									will be responsible for complying with all such additional restrictions
									from the date you are deemed to receive the notice.</p></li>
							<li><p>If at any time you sell, trade, donate, give away, transfer, or
									otherwise dispose of a Blokpax NFT for any reason, all rights you have
									in a Blokpax NFT will immediately expire without the requirement of
									notice, and you will have no further rights to sell, trade, access, or
									otherwise interact with the associated Blokpax NFT.</p></li>
							<li><p>Blokpax requires users to respect the intellectual property
									rights of others. If you are the owner of a copyright and you believe
									your work has been used in the Services in a way that constitutes
									copyright infringement, please provide our Copyright Agent with a notice
									meeting all of the requirements of the Digital Millennium Copyright Act
									(“DMCA”). Your notice should contain the following information:</p>
								<ol type="1">
									<li><p>A physical or electronic signature of the person authorized to
											act on behalf of the owner of the copyright or other intellectual
											property interest;</p></li>
									<li><p>A clear description of the copyrighted work or other intellectual
											property that you claim has been infringed;</p></li>
									<li><p>A description of where the material that you claim is infringing
											is located in the Services;</p></li>
									<li><p>Your address, telephone number, and email address;</p></li>
									<li><p>A statement by you that you have a good faith belief that the
											disputed use is not authorized by the copyright owner, its agent or the
											law; and</p></li>
									<li><p>A statement by you, made under penalty of perjury, that the above
											information in your notice is accurate and that you are the copyright or
											intellectual property owner or authorized to act in the copyright or
											intellectual property owner’s behalf.</p></li>
								</ol>
							</li>
						</ol>



						<blockquote>
							<p>Before you file your DMCA notice, please carefully consider whether
								or not the use of the copyrighted material at issue is protected by the
								Fair Use doctrine. If you file a DMCA notice when there is no infringing
								use, you could be liable for costs and attorneys’ fees.</p>
							<p>Our agent for notice of claims of copyright or other intellectual
								property infringement can be reached as follows:</p>
							<p>By mail:</p>
							<p>
								Theodore J. Chiacchio <br />
								Registered Patent Attorney <br />
								Chiacchio IP, LLC <br />
								307 North Michigan Avenue, Suite 2011 <br />
								Chicago, IL 60601
							</p>
							<p>By email:</p>
							<p><a
		   href="mailto:tchiacchio@chiacchioip.com">tchiacchio@chiacchioip.com</a></p>
						</blockquote>
					</li>

					<li>
						<p><strong>CUSTODY AND SAFEKEEPING</strong>
						</p>
						<ol type="1">
							<li><p>Blokpax agrees to provide, at no cost, custody and safekeeping
									that it, in its sole discretion, believes to be reasonable, for the
									assets redeemable by a Blokpax NFT for a period of thirty-six (36)
									months after the date on which that redeemable Blokpax NFT was first
									awarded. After the initial thirty-six-month period expires, if the
									redeemable Blokpax NFT has not been redeemed, Blokpax will charge a
									custody fee based on a percentage on the insured value of the physical
									asset. The current annual custody fee is 0.5% $USD of the insured value
									of the item. Blokpax may change the custody fee at any time, in its sole
									discretion, and will notify users of the change by updating these Terms
									and posting the announcement of the new custody fee in these Terms.
									Blokpax will set the insured value of the underlying physical asset
									based on its sole determination with no guarantee either explicit or
									implicit that the Blokpax insured value is a reasonable representation
									of the price that the item would receive in an arm’s-length market
									transaction.  Blokpax will publish on its website a list of unredeemed
									Blokpax NFTs that are redeemable for physical assets and the custody
									fees due. THIRD PARTY WALLETS ARE INHERENTLY ANONYMOUS. Blokpax HAS NO
									WAY OF CONTACTING THE OWNER OF A BLOKPAX NFT STORED IN A THIRD-PARTY
									DIGITAL WALLET OR ALTERNATIVE STORAGE MEDIUM. IT IS THE REDEEMABLE
									BLOKPAX NFT’S OWNER’S SOLE RESPONSIBILITY TO BE AWARE OF AND PAY THE
									REQUIRED CUSTODY FEES. If custody fees for a physical asset that is
									associated with a Blokpax NFT redeemable for the physical asset remain
									unpaid for ninety (90) or more continuous days after the date on which
									the custody fees began accruing, Blokpax, in its sole discretion, may
									choose to sell the underlying physical asset and transfer the proceeds
									(converted to a digital currency chosen by Blokpax), less transaction
									fee and unpaid custody fees, to the wallet address containing the
									Blokpax NFT. If the physical asset is sold in this manner, the metadata
									on the redeemable Blokpax NFT will be updated to show that the
									underlying physical asset has been redeemed. Blokpax makes no
									representation or warranty that it will obtain a price for the asset
									that reflects the market value of the item. </p></li>
							<li><p>All Blokpax NFTs include coding for a royalty to Blokpax of set
									percentage of the sales proceeds of the Blokpax NFT when they are sold
									on third party sites.  If Blokpax receives royalty payments for a
									specific Blokpax NFT because of transfers of that Blokpax NFT, Blokpax
									will waive custody fees for the physical asset associated with that
									Blokpax NFT for a period of twelve (12) months from the date of the
									transaction for which Blokpax received royalty fees. For any custody
									related question, users may contact us at <a
														href="mailto:custody@blokpax.com">custody@blokpax.com</a>.</p></li>
						</ol>
					</li>

					<li>
						<p><strong>CONDITIONS OF USE</strong>
						</p>
						<ol type="1">
							<li><p>YOU AGREE THAT YOU ARE RESPONSIBLE FOR YOUR OWN CONDUCT WHILE
									USING THE SERVICES, AND THAT YOU ARE RESPONSIBLE FOR ANY CONSEQUENCES
									THEREOF. YOU AGREE THAT YOU WILL ONLY USE THE SERVICES FOR PURPOSES THAT
									ARE LEGAL, PROPER, AND IN ACCORDANCE WITH THESE TERMS AND APPLICABLE
									LAWS OR REGULATIONS.</p></li>
							<li><p>You warrant and agree that your use of the Services will not in
									any manner involve: (a) the creation of accounts by automated means or
									under false or fraudulent pretenses; (b) the impersonation of another
									person; (c) the use of automated software that simulates human behavior,
									such as bots; (d) the use of unauthorized software, scripts, or the like
									(including but not limited to bots, spiders, mods, and scrapers), to
									modify, automate, or change the purchasing process in any way, or to
									collect data; (e) the acquisition of Blokpax NFTs or other assets via
									inappropriate or illegal means, including but not limited to
									acquisitions made via payment mechanisms that you do not have the right
									to use; (f) the purchase or sale of any user’s account to other users or
									third parties outside of the Services; (g) the wrongful receipt or
									seizure of any Blokpax NFTs or other assets; (h) the access,
									modification, or use of any nonpublic areas of the Services or our
									computer systems; (i) an attempt to probe, scan, or test the
									vulnerabilities of the Services or any related system, or an attempt to
									breach any security or authentication measures used in connection with
									the Services; (j) an attempt to modify, adapt, or reverse engineer any
									portion of the Services; (k) the creation of a product or service that
									competes with the Services; (l) interference or attempted interference
									with the access or enjoyment of any user, host, or network, including
									but not limited to attempts to overload, flood, or spam the Services;
									(m) the distribution or attempted distribution of any viruses, worms,
									Trojan horses, corrupted files, or other items of a destructive or
									deceptive nature; (n) the sending, uploading, distribution, or
									dissemination of any unlawful, defamatory, harassing, abusive,
									fraudulent, obscene, offensive, or otherwise objectionable material; (o)
									abuse, harassment, or threats against any users of the Services or any
									of our representatives; (p) the use of any abusive, defamatory,
									harassing, obscene, offensive, hateful, vulgar, or otherwise
									objectionable language when communicating with any users of the Services
									or any of our representatives; (q) the infringement of any intellectual
									property rights; (r) the violation of the legal rights of others; (s)
									illegal activity, or the promotion or encouragement of illegal activity;
									(t) an attempt to harm or threaten to harm other users in any way. You
									further warrant and agree that you will not allow any third party to use
									the Services in any such way through your account.</p></li>
							<li><p>You agree that Blokpax may immediately suspend or terminate your
									access to the Services at any time (except for your right to redeem any
									redeemable Blokpax NFT that you then hold that is then redeemable for a
									specified physical asset), in our sole and absolute discretion, for any
									conduct or activity that we believe, in our sole and absolute
									discretion, is illegal, that violates these Terms, harms the Site or the
									Services (including but not limited to hacking, spamming, dissemination
									of viruses, Trojan Horses or other “bugs” or that otherwise negatively
									affects others’ enjoyment of the Services. You acknowledge that Blokpax
									is not required to provide you with notice before suspending or
									terminating your access to the Services, though we may choose to do so,
									and that Blokpax will not be liable to you for any such suspension or
									termination.</p></li>
							<li><p>In addition, if Blokpax believes any Blokpax NFTs or other assets
									were wrongfully, fraudulently, or illegally obtained or acquired, we
									reserve the right to, in our sole and absolute discretion and without
									any notice or liability to you, void any such transactions and/or
									confiscate any such Blokpax NFTs or other assets that were wrongfully,
									fraudulently, or illegally obtained or acquired.</p></li>
							<li><p>You agree that Blokpax has the right, without providing prior
									notice, to take appropriate legal action, including, without limitation,
									referral to law enforcement or regulatory authority, or notifying the
									harmed party of any illegal or unauthorized use of the Services. Without
									limiting the foregoing, we have the right to fully cooperate with any
									law enforcement authorities or court orders requesting or directing us
									to disclose the identity or other information of anyone using the
									Services. YOU WAIVE AND HOLD US AND OUR PARENT, SUBSIDIARIES,
									AFFILIATES, SUPPLIERS, DISTRIBUTORS, ADVERTISING/PROMOTIONAL AGENCIES,
									LICENSORS, LICENSEES AND EACH OF THEIR RESPECTIVE PARENT COMPANIES AND
									EACH SUCH COMPANY’S OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND
									SUCCESSORS HARMLESS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION
									TAKEN BY US AND ANY OF THE FOREGOING PARTIES RELATING TO ANY
									INVESTIGATIONS EITHER BY US OR SUCH PARTIES OR BY LAW ENFORCEMENT
									AUTHORITIES.</p></li>
							<li><p>You represent, warrant, covenant, and agree that (a) you are not
									located in, and you are not a national or resident of, any country to
									which the United States, United Kingdom, European Union, Australia, or
									Canada has embargoed goods and/or services of the same type as the
									Services, including, without limitation, Cuba, Iran, North Korea, or
									Syria; and (b) you are not a person or entity, or owned by, under the
									control of, or affiliated with, a person or entity (i) that appears on
									the U.S. Office of Foreign Assets Control’s Specially Designated
									Nationals List, Foreign Sanctions Evaders List, or Palestinian
									Legislative Council List, (ii) that appears on the U.S. Department of
									State’s terrorist Exclusion List, (iii) that is subject to sanctions in
									any other country, or (iv) that is engaged in the design, development,
									or production of nuclear, biological, or chemical weapons, missiles, or
									unmanned aerial vehicles. You are not allowed to use the Services if we
									identify your IP address or address of residence or use of the Services
									as originating from any such country.</p></li>
							<li><p>You represent, warrant, covenant, and agree (a) that you will not
									use, trade, sell, or otherwise dispose of any asset from the Services in
									any manner that is contrary to or in violation of any applicable law;
									(b) that you have the full right and authority to use the Services and
									to be bound by these Terms; and (c) that you will at all times comply
									with applicable laws.</p></li>
							<li><p>You acknowledge and agree that, if you access the Services via
									browser, Blokpax may cease to support a given browser, and that your
									continued use of the Services may require you to download and use a
									supported browser.  You also acknowledge and agree that the performance
									of the Services is dependent on the performance of your electronics
									equipment and your Internet connection and/or telecommunications
									services.</p></li>
							<li><p>You acknowledge and agree that the Services may include
									hyperlinks to other websites or resources (the “External Sites”), which
									are provided solely as a convenience to our users. We have no control
									over the External Sites. You acknowledge and agree that Blokpax is not
									responsible for the availability of any External Sites, and that we do
									not endorse any advertising, products, or other materials on or made
									available from any External Sites. Furthermore, you acknowledge and
									agree that Blokpax is not liable for any loss or damage which may be
									incurred as a result of the availability or unavailability of the
									External Sites, or as a result of any reliance you place on the
									completeness, accuracy, or existence of any advertising, products, or
									other materials on or made available from any External Sites.</p></li>
						</ol>
					</li>

					<li>
						<p>
							<strong>TERMINATION</strong>
						</p>
						<ol type="1">
							<li><p>You may terminate these Terms at any time by canceling your
									account for the Services and discontinuing your access to the Services.
									If you cancel your account or otherwise terminate these Terms, you will
									not receive any refunds for any purchases made via the
									Services.</p></li>
							<li><p>We may, in our sole and absolute discretion and for any or no
									reason, terminate these Terms and suspend and/or terminate your account
									for the Services without prior notice. You agree that we will not be
									liable to you or any third party for any suspension or termination of
									your account or termination of these Terms. If we terminate these Terms
									or suspend or terminate your access to the Services due to your breach
									of these Terms or any suspected fraudulent, abusive, or illegal
									activity, the termination of these Terms will be in addition to any
									other remedies we may have at law or in equity.</p></li>
							<li><p>Upon termination of these Terms, whether by you or us, you may no
									longer have access to information related to your account, and you
									acknowledge that we have no obligation to preserve any such information
									and no obligation to provide any such information to you or any other
									party.</p></li>
							<li><p>Section 1 and Sections 3-13 will survive termination or
									expiration of these Terms for any reason.</p></li>
						</ol>
					</li>

					<li>
						<p>
							<strong>DISCLAIMER OF WARRANTIES</strong>
						</p>
						<ol type="1">
							<li><p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, THE SERVICES, IN
									WHOLE AND IN PART (INCLUDING, WITHOUT LIMITATION, ALL CONTENT, AND USER
									MATERIALS), ARE PROVIDED, TRANSMITTED, DISTRIBUTED, AND MADE AVAILABLE
									"AS IS" AND “AS AVAILABLE” WITHOUT EXPRESS OR IMPLIED WARRANTIES OF ANY
									KIND, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE, IMPLIED
									WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR
									NON-INFRINGEMENT. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE
									MAKE NO WARRANTY: (A) THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR
									FREE; (B) THAT DEFECTS OR ERRORS IN THE SERVICES WILL BE CORRECTED; (C)
									THAT THE SERVICES WILL BE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS;
									(D) AS TO THE QUALITY, ACCURACY, COMPLETENESS AND VALIDITY OF ANY
									INFORMATION OR MATERIALS IN CONNECTION WITH THE SERVICES; (E) THAT YOUR
									USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS; OR (F) THAT
									TRANSMISSIONS OR DATA WILL BE SECURE.<br />
									<br />
									<strong>Exceptions.</strong> SOME JURISDICTIONS DO NOT ALLOW THE
									DISCLAIMER, EXCLUSION OR LIMITATION OF CERTAIN WARRANTIES, LIABILITIES
									AND DAMAGES, SO SOME OF THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU. IN
									SUCH JURISDICTIONS, OUR WARRANTIES AND LIABILITY WILL BE LIMITED TO THE
									FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</p></li>
							<li><p>.</p></li>
							<li><p>YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SERVICES
									AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE
									REPRESENTATIONS AND WARRANTIES, DISCLAIMERS, AND LIMITATIONS OF
									LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR
									ALLOCATION OF RISK BETWEEN YOU AND US, AND WHICH FORM AN ESSENTIAL BASIS
									OF THE BARGAIN BETWEEN YOU AND US. WE WOULD NOT BE ABLE TO PROVIDE THE
									SERVICES TO YOU WITHOUT THESE LIMITATIONS.</p></li>
						</ol>
					</li>

					<li>
						<p>
							<strong>LIMITATIONS OF LIABILITY</strong>
						</p>
						<ol type="1">
							<li><p><strong>BY ACCESSING, USING OR DOWNLOADING THE SERVICES YOU
										ACKNOWLEDGE AND AGREE THAT SUCH USE IS AT YOUR OWN RISK AND THAT NONE OF
										THE PARTIES INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES
										OR ANY OF DOUBLEDOWN, ITS AFFILIATES, SUBSIDIARIES OR ANY OF THEIR
										EMPLOYEES, AGENTS OR CONTRACTORS (COLLECTIVELY "RELEASED PARTIES") ARE
										LIABLE FOR ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT, SPECIAL, OR
										PUNITIVE DAMAGES, OR ANY OTHER LOSSES, COSTS, OR EXPENSES OF ANY KIND
										(INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOSS OF DATA, LEGAL FEES,
										EXPERT FEES, COST OF PROCURING SUBSTITUTE SERVICES, LOST OPPORTUNITY, OR
										OTHER DISBURSEMENTS) WHICH MAY ARISE, DIRECTLY OR INDIRECTLY, THROUGH
										THE ACCESS TO, USE OF, RELIANCE ON ANY MATERIAL OR CONTENT ON THE
										SERVICES, OR BROWSING OF THE SERVICES OR THROUGH YOUR DOWNLOADING OF ANY
										MATERIALS, DATA, TEXT, IMAGES, VIDEO OR AUDIO FROM THE SERVICES, EVEN IF
										WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM
										EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, THE TOTAL LIABILITY OF
										DOUBLEDOWN AND/OR ITS AFFILIATES IS LIMITED TO THE TOTAL AMOUNT YOU HAVE
										PAID DOUBLEDOWN IN THE ONE HUNDRED AND EIGHTY (180) DAYS IMMEDIATELY
										PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM. WITHOUT
										LIMITING THE FOREGOING, RELEASED PARTIES ASSUME NO RESPONSIBILITY, AND
										WILL NOT BE LIABLE, FOR ANY DAMAGES RELATING TO OR CAUSED BY ANY
										VIRUSES, BUGS, HUMAN ACTION OR INACTION OF ANY COMPUTER SYSTEM, PHONE
										LINE, HARDWARE, SOFTWARE OR PROGRAM MALFUNCTIONS, OR ANY OTHER ERRORS,
										FAILURES OR DELAYS IN COMPUTER TRANSMISSIONS OR NETWORK CONNECTIONS ON
										ACCOUNT OF YOUR ACCESS TO OR USE OF THE SERVICES. RELEASED PARTIES
										CANNOT AND DO NOT GUARANTEE CONTINUOUS, UNINTERRUPTED, OR SECURE ACCESS
										TO THE SERVICES</strong></p></li>
							<li><p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOU USE THE SERVICES AT
									YOUR SOLE RISK, AND THAT THE SERVICES MAY BE TERMINATED AT ANY TIME BY
									BLOKPAX OR THIRD PARTIES PROVIDING FACILITIES AND TECHNOLOGY FOR ITS
									OPERATION.</p></li>
							<li><p>YOU ACKNOWLEDGE AND ACCEPT THE INHERENT SECURITY RISKS OF
									PROVIDING INFORMATION OVER THE INTERNET AND AGREE THAT WE HAVE NO
									LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE
									TO OUR GROSS NEGLIGENCE.</p></li>
							<li><p>YOU ACKNOWLEDGE AND AGREE THAT BLOKPAX NFTS STORED IN DIGITAL
									WALLETS ARE BEYOND THE CONTROL OF BLOKPAX. <strong><u>IF YOU LOSE YOUR
											PRIVATE KEYS TO THE WALLET THAT CONTAINS YOUR BLOKPAX NFT WE CANNOT
											RETRIEVE YOUR BLOKPAX NFT NOR CAN WE RETRIEVE ANY SALES PROCEEDS WE
											DEPOSIT INTO THE WALLET ADDRESS</u></strong>. YOU ARE SOLELY RESPONSIBLE
									FOR SECURING YOUR DIGITAL WALLET AND THE CONTENTS WITHIN IT.</p></li>
							<li><p>YOU UNDERSTAND AND AGREE THAT WE, OUR SUBSIDIARIES, AFFILIATES,
									SUPPLIERS, DISTRIBUTORS, ADVERTISING/PROMOTIONAL AGENCIES, LICENSORS,
									LICENSEES AND EACH OF THEIR RESPECTIVE PARENT COMPANIES WILL NOT BE
									LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL,
									SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES THAT YOU MAY INCUR,
									HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT
									LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR
									INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST
									OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, DIMINUTION OF VALUE OR
									ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE
									POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE
									LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
									DAMAGES, SO THE ABOVE MAY NOT APPLY TO YOU.</p></li>
							<li><p>CALIFORNIA NOTICE : By using the Services and agreeing to these
									Terms, you expressly waive all rights under Section 1542 of the Civil
									Code of California (and any similar laws), which reads as
									follows:  “1542. CERTAIN CLAIMS NOT AFFECTED BY GENERAL RELEASE. A
									GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
									KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING
									THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED
									HIS OR HER SETTLEMENT WITH THE DEBTOR.”</p></li>
						</ol>
					</li>

					<li>
						<p>
							<strong>ASSUMPTION OF RISK</strong>
						</p>
						<ol type="1">
							<li><p>Like the prices of other collectibles, the prices of collectible
									Blokpax NFTs can be extremely volatile and are subjective, and Blokpax
									NFTs have no inherent or intrinsic value. Fluctuations in the prices of
									other digital assets may adversely affect the value of your Blokpax
									NFTs.</p></li>
							<li><p>You are solely responsible for determining what taxes, if any,
									apply to your transactions via the Services.</p></li>
							<li><p>There are risks associated with the use of blockchain technology
									and digital currency, all of which may have adverse effects on the value
									of your Blokpax NFTs. These risks include but are not limited to
									software upgrades or changes; regulatory or policy changes; and errors,
									failures, disruptions, or delays in communications systems, networks,
									software, or hardware.</p></li>
						</ol>
					</li>

					<li>
						<p>
							<strong>INDEMNIFICATION</strong>
						</p>
						<ol type="1">
							<li><p>You agree to defend, indemnify and hold harmless (including
									reasonable attorney’s fees and costs of suit) Blokpax and our
									subsidiaries, affiliates, officers, agents, employees, advertisers,
									licensors, suppliers, and partners and each of their respective parent
									companies from and against any claim, liability, loss, damage (actual
									and consequential) of any kind or nature, suit, judgment, litigation
									cost, and attorneys’ fees arising out of or in any way related to (a)
									your breach of these Terms, (b) your use or misuse of the Services, or
									(c) your violation of applicable laws, rules, or regulations in
									connection with your access to or use of the Services.</p></li>
						</ol>
					</li>

					<li>
						<p>
							<strong>FORCE MAJEURE</strong>
						</p>
						<ol type="1">
							<li><p>We will not be liable or responsible to you, nor will we be
									deemed to have defaulted under or breached these Terms, for any failure
									or delay in fulfilling or performing any of these Terms, when such
									failure or delay is caused by or results from one or more of the
									following force majeure events: acts of God; flood, fire, earthquake,
									epidemics, pandemics, tsunami, hurricane, explosion; war, invasion,
									hostilities, terrorist threats or acts, riot or other civil unrest;
									government order, law, or action; embargoes or blockades; strikes, labor
									stoppages, or other industrial disturbance; telecommunications
									breakdown, shortage of adequate power or electricity, shortage of
									adequate Internet connectivity; other similar events beyond our
									control.</p></li>
							<li><p>In the event Blokpax is subject to a force majeure event, we will
									use commercially reasonable efforts to promptly notify you of the event
									and its expected duration. We will resume performance as soon as
									reasonably practicable. In the event we are unable to resume
									performance, we may terminate these Terms.</p></li>
						</ol>
					</li>

					<li>
						<p>
							<strong><u>DISPUTE RESOLUTION – BINDING ARBITRATION</u></strong>
						</p>

						<blockquote>
							<p><strong>PLEASE READ THIS SECTION CAREFULLY, AS IT AFFECTS YOUR
									RIGHTS, REQUIRES YOU TO ARBITRATE ALL DISPUTES WITH BLOKPAX, ANDLIMITS
									THE MANNER IN WHICH YOU CAN SEEK RELIEF. WHILE YOU MUST AGREE TO THESE
									PROVISIONS AS TO ANY AND ALL CLAIMS, THERE IS AN OPTION, DESCRIBED
									BELOW, TO OPT OUT OF THE ARBITRATION AND CLASS WAIVER PROVISIONS. THE
									OPTION TO OPT-OUT IS TIME-LIMITED TO THIRTY (30) DAYS AND REQUIRES YOUR
									IMMEDIATE ATTENTION.<br />
									<br />
									THESE PROVISIONS GENERALLY PRECLUDE YOU FROM BRINGING ANY CLASS,
									COLLECTIVE, OR REPRESENTATIVE ACTION AGAINST BLOKPAX. THEY ALSO PRECLUDE
									YOU FROM PARTICIPATING IN OR RECOVERING RELIEF UNDER ANY PAST, PENDING,
									OR FUTURE CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION AGAINST BLOKPAX BY
									SOMEONE ELSE.</strong></p>
							<p><strong>ARBITRATION PRECLUDES YOU FROM SUING IN COURT OR FROM HAVING
									A JURY TRIAL.<br />
									<br />
								</strong>Whether to agree to arbitration is an important decision. It is
								your decision to make and you are not required to rely solely on the
								information provided in these Terms. You should take reasonable steps to
								conduct further research and to consult with counsel (at your expense)
								regarding the consequences of your decision.</p>
						</blockquote>


						<ol type="1">
							<li><p><strong>Scope of Arbitration Provision.</strong> You and Blokpax
									agree that any past, pending, or future dispute, claim, or controversy
									arising out of relating to your access to or use of any Blokpax Services
									or these Terms of Use (including without limitation any dispute
									concerning the breach, enforcement, construction, validity,
									interpretation, enforceability, or arbitrability of these Terms of Use)
									(a "Dispute"), shall be determined by arbitration, except that you and
									Blokpax are NOT required to arbitrate any Dispute in which either party
									seeks equitable and other relief for the alleged unlawful use of
									copyrights, trademarks, trade names, logos, trade secrets, or
									patents.</p></li>
							<li><p>YOU EXPRESSLY AGREE TO GIVE UP YOUR RIGHT TO HAVE A TRIAL BY
									JURY.</p></li>
							<li><p><strong>Waiver of Class Relief.</strong> YOU EXPRESSLY AGREE THAT
									YOU AND BLOKPAX WILL NOT COMMENCE AGAINST THE OTHER ANY LAWSUIT,
									ARBITRATION, OR OTHER DISPUTE RESOLUTION MECHANISM AS A MEMBER OF A
									CLASS OF CLAIMANTS, INCLUDING, WITHOUT LIMITATION, AS A MEMBER OF A
									CLASS ACTION, COLLECTIVE ACTION, OR REPRESENTATIVE LAWSUIT. By accepting
									this agreement, you give up your right to participate in any past,
									pending, or future class action or any other consolidated or
									representative proceeding, including any existing as of the date you
									agreed to these Terms.</p></li>
							<li><p><strong>OPTION TO OPT OUT.</strong> YOU MAY OPT OUT OF THESE
									ARBITRATION AND CLASS ACTION PROVISIONS BY FOLLOWING THE INSTRUCTIONS
									BELOW. IF YOU DO NOT OPT OUT, THESE TERMS WILL APPLY RETROACTIVELY TO
									ALL CLAIMS YOU MAY POSSESS, WHETHER ASSERTED TO DATE OR
									NOT.<strong><br />
										<br />
										PROCEDURE TO OPT OUT OF ARBITRATION.</strong> IF YOU DO NOT WISH TO
									AGREE TO THIS ARBITRATION AND CLASS ACTION WAIVER AGREEMENT, YOU MUST,
									WITHIN THIRTY (30) DAYS OF ENTERING THIS AGREEMENT, SEND AN E-MAIL TO
									[support@blokpax.com] CONTAINING YOUR FULL NAME, ADDRESS, AND THE WORDS
									“OPT OUT” IN THE BODY OR SUBJECT LINE OF THE EMAIL.</p></li>
							<li><p><strong>Location of Arbitration and Applicable Rules.</strong>
									All disputes arising out of or in connection with these Terms, or in
									respect of any defined legal relationship associated therewith, shall be
									resolved exclusively by arbitration under the Federal Arbitration Act
									and using the Commercial Arbitration Rules of the American Arbitration
									Association (“AAA”) using a single AAA arbitrator selected in accordance
									with those Commercial Arbitration Rules, with arbitration to occur in
									Columbia, South Carolina; and the arbitrator may not consolidate more
									than one person’s claims, and may not otherwise preside over any form of
									a representative or class proceeding.</p></li>
							<li><p><strong>Allocation of Arbitration Fees.</strong> If you assert a
									Dispute as an individual, you will only be required to pay arbitration
									fees of $250 in connection with any arbitration under this section, and
									Blokpax will bear all other costs charged by AAA or the arbitrator up to
									$5,000. You will still be responsible for paying your own attorneys’
									fees. Each party will cover its own fees and costs associated with the
									arbitration proceedings. </p></li>
							<li><p><strong>Authority of Arbitrator.</strong> With the exception of
									the class procedures and remedies discussed above under “Waiver of Class
									Relief,” the arbitrator shall have the authority to grant any remedy
									that would otherwise be available in court. The arbitrator’s award will
									be final and binding, and any judgment on the award rendered by the
									arbitrator may be entered in any court of competent jurisdiction. The
									parties agree that they will not appeal any arbitration decision to any
									court.</p></li>
							<li><p>Notwithstanding the foregoing, we may seek and obtain injunctive
									relief in any jurisdiction in any court of competent jurisdiction, and
									you agree that these terms are specifically enforceable by us through
									injunctive relief and other equitable remedies and without proof of
									monetary damages.</p></li>
						</ol>

					</li>

					<li>
						<p>
							<strong>One Year Statute of Limitations.</strong>
						</p>
						<ol type="1">
							<li>
								You and Blokpax
								agree that any claims, regardless of form, arising out of or related to
								the Site (including Services) or these Terms of Use or Privacy Policy
								must be filed within ONE (1) YEAR of the action, omission, event or
								occurrence giving rise to the claim or suit, after which such claims
								will be time-barred and prohibited, without regard to any longer period
								of time which may be provided by any period of limitation or repose by
								law or statute
							</li>

						</ol>

					</li>

					<li>
						<p>
							<strong>PRIVACY POLICY</strong>
						</p>
						<ol type="1">
							<li><p>Blokpax’s Privacy Policy describes the ways in which we collect,
									use, and disclose your personal information. Our Privacy Policy (<a
																			  href="https://www.iubenda.com/privacy-policy/51289722">here</a>) is
																		  hereby incorporated by this reference into these Terms, and you agree to
																		  the collection, use, and disclosure of your data as set forth in the
																		  Privacy Policy.</p></li>
						</ol>
					</li>

					<li>
						<p>
							<strong>NOTICE FOR CALIFORNIA USERS</strong>
						</p>
						<ol type="1">
							<li><p>Under California Civil Code Section 1789.3, California consumers
									are entitled to the following specific consumer rights notice: The
									Complaint Assistance Unit of the Division of Consumer Services of the
									California Department of Consumer Affairs may be contacted in writing at
									1625 N. Market Blvd., Suite N – 112, Sacramento, CA 95834, or by
									telephone at 1(800) 952-5210.</p></li>
						</ol>
					</li>

					<li>
						<p>
							<strong>GENERAL</strong>
						</p>
						<ol type="1">
							<li><p>These Terms, together with our Privacy Policy, constitute the
									entire and exclusive agreement between Blokpax and you regarding the
									Services, and they supersede and replace any prior or contemporaneous
									agreements or understandings between Blokpax and you regarding the
									Services. Entry in any Sweepstakes administered by Blokpax will be
									further governed by the Official Rules of said Sweepstakes.</p></li>
							<li><p>If a particular provision of these Terms is held to be invalid,
									illegal, void, or unenforceable, the parties agree that the provision
									should be “blue-penciled” or otherwise read and enforced to the greatest
									extent consistent with the parties intent and, if not enforceable, said
									provision shall be deemed to be severed from these Terms and shall not
									affect the validity or enforceability of the remaining provisions of
									these Terms.</p></li>
							<li><p>You may not assign or otherwise transfer these Terms or any of
									your rights or obligations under these Terms to any third party without
									Blokpax’s prior written consent, which consent is within Blokpax’s sole
									and unfettered discretion. Blokpax may assign our rights and obligations
									under these Terms to any third party in our sole and unfettered
									discretion, and without your consent.</p></li>
							<li><p>Nothing in these Terms shall constitute a partnership or joint
									venture between you and Blokpax.</p></li>
							<li><p>These Terms do not and are not intended to confer any rights or
									remedies upon any person or entity other than you.</p></li>
							<li><p>Blokpax’s failure to exercise or enforce any right or provision
									under these Terms shall not operate as a waiver of such right or
									provision. Any waiver of any right or provision of these Terms will be
									effective only if in writing and signed for and on behalf of us by a
									duly authorized representative.</p></li>
							<li><p>All matters arising out of or relating to these Terms will be
									governed by and construed in accordance with the laws of the State of
									South Carolina without giving effect to any choice-of-law or
									conflict-of-law provisions or rules (whether of the State of South
									Carolina or any other jurisdiction); provided, however, that any issues
									with respect to the interpretation, applicability, and enforcement of
									Section 12 above shall be governed by the Federal Arbitration Act (9
									U.S. Code § 1, et seq.) in any instance in which that Act differs from
									the relevant law of South Carolina. Subject to the arbitration
									provisions of these Terms, any legal action or proceeding arising under
									these Terms will be brought exclusively in the courts of the State of
									South Carolina, and we and you irrevocably consent to personal
									jurisdiction and venue there.</p></li>
							<li><p>Blokpax may provide you with any notices, including any regarding
									changes to these Terms, by email or by posting them in the Services. By
									providing Blokpax with your email address, you consent to our use of
									that address to send you any notices. Notices that we send by email will
									be effective when we send the email, and notices that we post will be
									effective when they are posted. It is your responsibility to keep your
									email address up to date.</p></li>
						</ol>
					</li>

				</ol>

			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
	setup(props) {

		return {

		}
	}
})
</script>
