<template>
	<div>
		Loading Storefront
		<loader />
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
	setup(props) {

		return {

		}
	}
})
</script>
