<template>
	<div class="bpmp-storefront__filters">
		<div class="flex-grow flex-shrink overflow-y-auto"
			:class="isSticky ? 'md:sticky md:top-[calc(84px+1rem)] md:h-[calc(100vh-115px)] md:overflow-auto' : ''"
		>
			<div class="flex justify-between m-4 px-4 sm:hidden">
				<h2 class="font-semibold text-xl flex-grow text-center">Filters</h2>
				<button v-if="props.closable" @click.prevent="$emit('close')"><i class="fa-regular fa-sharp fa-times text-xl" /></button>
			</div>

			<div v-if="props.showCollectionFilters" class="mb-4">
				<p class="px-4"><strong>Status</strong></p>
				<filter-option label="Listed"
					@click.prevent="toggleListed"
					:selected="(storeStore.state.activeFilters?.Listed ?? []).indexOf('Yes') >= 0"
				/>
			</div>

			<p class="px-4"><strong>Traits</strong></p>
			<filter-accordian v-for="(options, key) in storeStore.state.metadata"
				class=""
				:key="`filt-${key}`"
				:label="key"
				:options="options"
				@filter="updateFilters"
			/>
		</div>

		<div v-if="props.closable"
			class="sm:hidden flex-shrink-0 flex-grow-0 flex justify-between space-x-2 py-4 px-4 border-t border-gray-200">
			<button class="shadow hover:shadow-none border border-gray-200 rounded-xl text-center w-full text-sky-600"
				@click.prevent="$emit('clear')">
				Clear all
			</button>
			<button class="shadow hover:shadow-none btn-primary-lg w-full"
				@click.prevent="$emit('done')">
				Done
			</button>
		</div>
	</div>
</template>
<script lang="ts">
import { useStorefrontStore } from "@/stores/StorefrontStore";
import { defineComponent, onMounted, PropType, reactive, ref } from "vue";
import FilterAccordian from "@/components/FilterAccordian.vue";
import FilterOption from "@/components/FilterOption.vue";

export default defineComponent({
	components: { FilterAccordian, FilterOption },
	emits: ['done', 'clear', 'close', 'filtersUpdated'],
	props: {
		closable: {
			type: Boolean,
			required: false,
			default: () => true,
		},
		showCollectionFilters: {
			type: Boolean,
			required: false,
			default: () => false,
		},
	},
	setup(props, { emit: $emit }) {
		const storeStore = useStorefrontStore()
		const stickySidebarRef = ref(null);
		const isSticky = ref(false);

		const ownerOptions = [
			{
				prop: "Any",
				val: "Any",
			},
			{
				prop: "Me",
				val: "Me",
			}
		]

		onMounted(() => {
			const preContent = document.querySelector('.bpmp-storefront__pre-content'); // this class is in @/Components/Storefront.vue

			const obsPreContent = new IntersectionObserver(([entry]) => {
				entry.isIntersecting
					? isSticky.value = false
					: isSticky.value = true
			}, { threshold: 0.1, rootMargin: '-54px'});

			obsPreContent.observe(preContent);
		})

		function toggleListed() {
			storeStore.toggleFilter('Listed', 'Yes');
			$emit('filtersUpdated');
		}

		function updateFilters(filter: string, selected: string[]) {
			// storeStore.filters[ filter ] = selected;
			storeStore.setFilters(filter, selected);
			$emit('filtersUpdated') //, state.filters)
		}

		return {
			props,
			storeStore,
			ownerOptions,
			toggleListed,
			updateFilters,
			stickySidebarRef,
			isSticky,
		}
	}
})
</script>
