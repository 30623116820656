<template>
	
	<div v-if="currentTab == `activity`">
		<section class="mt-6 md:px-4 md:mt-10">
				<h2
					class="text-lg font-medium text-gray-900 inline-flex px-4"
				>
					<ArrowsUpDownIcon
						class="stroke-2 mr-2 h-6 w-6 text-gray-900 -rotate-180"
					/>
					Item Activity
				</h2>

				<div
					class="mt-2 pb-10 overflow-auto aspect-auto space-y-10 divide-y divide-gray-200 border-t border-gray-200 md:border md:rounded-xl"
				>
					<table class="min-w-full divide-y table-auto divide-gray-200">
						<thead class="bg-gray-200/60">
							<tr>
								<th class="w-48"></th>
								<th
									scope="col"
									class="px-2 pl-4 py-3 text-left table-auto w-48 text-sm font-normal text-gray-900"
								>
									Event
								</th>
								<th
									scope="col"
									class="px-2 py-3 text-left text-sm table-auto w-48 font-normal text-gray-900"
								>
									Price
								</th>
							
								<th
									scope="col"
									class="px-2 pr-4 py-3 text-left text-sm font-normal w-48 text-gray-900"
								>
									Date
								</th>
							</tr>
						</thead>
						<tbody v-for="item in state.activityData" class="divide-y  divide-gray-200 bg-white">
							<tr>
								<td class=" flex justify-left ml-8 items-center -mr-12 mt-10" >
								
										<ShoppingCartIcon class="w-8 h-8"/>
										<div class="ml-3 text-base text-gray">Sale</div>
								
									
								</td>

								<td
						
									class="whitespace-nowrap px-2 pl-4  py-4 text-sm font-medium text-gray-900"
								>
								
								<StorefrontActivityAsset
									:asset="item"
									
									:slug="storeStore.state.storefront.slug"
								/>
								
								</td>

								<td
									class="whitespace-nowrap px-2 py-3 ml-24 text-sm text-gray-500"
								>
									<div class="flex flex-col p">
										<div class="font-normal ">{{ $format(humanReadablePrice(
												(item.listing.price * item.listing.quantity)
											)) }} {{$token}}</div>
										<to-usd
												:bpx="item.listing.price"
												:is-decimal="false"
												class="font-normal"
											/>
									</div>
								</td>
							
								<td
									class="whitespace-nowrap px-2 pr-4 py-3 text-sm text-gray-500"
								>
								
									{{ formatDistanceToNow(new Date(item.listing.filled_at)) }} ago

								</td>
							</tr>
						</tbody>
					</table>
				</div>
						
				</section>
				<div v-if="currentTab == `activity`">
					
					<div v-if="this.state.last_page <= 7" class="flex flex-row mt-4 ml-4">
						<button v-if="this.state.currentPage != 1" @click.prevent="this.state.currentPage = this.state.currentPage - 1, getActivity() ;">
							<div class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">Back</div>
						</button>
						<button v-for="item in this.state.last_page" :key="item" @click.prevent="this.state.currentPage = item , getActivity();" class="">
							<div v-if="item != this.state.currentPage" class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">{{ item }}</div>
							<div v-if="item == this.state.currentPage" class="mr-2 px-4 py-2 font-bold text-gray bg-gray-200 rounded-lg">{{ item }}</div>
						</button>
						<button v-if="this.state.currentPage != this.state.last_page" @click.prevent="this.state.currentPage = this.state.currentPage + 1, getActivity() ;">
							<div class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">Next</div>
						</button>
					</div>
					<div v-if="this.state.last_page > 7" class="flex flex-row mt-4 ml-4">
							<button v-if="this.state.currentPage != 1" @click.prevent="this.state.currentPage = this.state.currentPage - 1, getActivity(); ">
								<div class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">Back</div>
							</button>

							<button v-if="this.state.currentPage <= this.state.last_page - 5 && this.state.currentPage > 6" @click.prevent="this.state.currentPage = 1, getActivity();" class="">
								<div v-if="this.state.currentPage != 1" class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">1</div>
								<div v-if="this.state.currentPage == 1" class="mr-2 px-4 py-2 font-bold text-gray bg-gray-200 rounded-lg">1</div>
							</button>
							
							<div v-if="this.state.currentPage <= this.state.last_page - 5 && this.state.currentPage > 6" class="text-2xl mr-2 mt-4">. . . </div>

							<button v-if="this.state.currentPage>2 && this.state.currentPage < (this.state.last_page - 4) " v-for="item in range(this.state.currentPage-2, this.state.currentPage+4)" :key="item" @click.prevent="this.state.currentPage = item, getActivity();" class="">
								<div v-if="item != this.state.currentPage" class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">{{ item }}</div>
								<div v-if="item == this.state.currentPage" class="mr-2 px-4 py-2 font-bold text-gray bg-gray-200 rounded-lg">{{ item }}</div>
							</button>
							<button v-if="this.state.currentPage==1  && this.state.currentPage < (this.state.last_page - 4)" v-for="item in range(this.state.currentPage, this.state.currentPage+6)" :key="item" @click.prevent="this.state.currentPage = item, getActivity();" class="">
								<div v-if="item != this.state.currentPage" class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">{{ item }}</div>
								<div v-if="item == this.state.currentPage" class="mr-2 px-4 py-2 font-bold text-gray bg-gray-200 rounded-lg">{{ item }}</div>
							</button>
							<button v-if="this.state.currentPage==2  && this.state.currentPage < (this.state.last_page - 4)" v-for="item in range(this.state.currentPage-1, this.state.currentPage+5)" :key="item" @click.prevent="this.state.currentPage = item, getActivity();" class="">
								<div v-if="item != this.state.currentPage" class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">{{ item }}</div>
								<div v-if="item == this.state.currentPage" class="mr-2 px-4 py-2 font-bold text-gray bg-gray-200 rounded-lg">{{ item }}</div>
							</button>

							<button v-if="this.state.currentPage >= this.state.last_page - 4" @click.prevent="this.state.currentPage = 1, getActivity();" class="">
								<div v-if="this.state.currentPage != 1" class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">1</div>
								<div v-if="this.state.currentPage == 1" class="mr-2 px-4 py-2 font-bold text-gray bg-gray-200 rounded-lg">1</div>
							</button>

							<div class="text-2xl mr-2 mt-4">. . . </div>



							
							<button v-if="this.state.currentPage == (this.state.last_page-4) " v-for="item in range(this.state.last_page-7, this.state.last_page-1)" :key="item" @click.prevent="this.state.currentPage = item, getActivity();" class="">
								<div v-if="item != this.state.currentPage" class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">{{ item }}</div>
								<div v-if="item == this.state.currentPage" class="mr-2 px-4 py-2 font-bold text-gray bg-gray-200 rounded-lg">{{ item }}</div>
							</button>
							<button v-if="this.state.currentPage >= (this.state.last_page-3) " v-for="item in range(this.state.last_page-6, this.state.last_page)" :key="item" @click.prevent="this.state.currentPage = item, getActivity();" class="">
								<div v-if="item != this.state.currentPage" class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">{{ item }}</div>
								<div v-if="item == this.state.currentPage" class="mr-2 px-4 py-2 font-bold text-gray bg-gray-200 rounded-lg">{{ item }}</div>
							</button>
							<button v-if="this.state.currentPage <= (this.state.last_page-4)" @click.prevent="this.state.currentPage = this.state.last_page, getActivity();" class="">
								<div v-if="this.state.last_page != this.state.currentPage" class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">{{ this.state.last_page }}</div>
								<div v-if="this.state.last_page == this.state.currentPage" class="mr-2 px-4 py-2 font-bold text-gray bg-gray-200 rounded-lg">{{ this.state.last_page }}</div>
							</button>
							

							<button v-if="this.state.currentPage != this.state.last_page" @click.prevent="this.state.currentPage = this.state.currentPage + 1 , getActivity();">
								<div class="mr-2 px-4 py-2 text-gray bg-gray-200 rounded-lg">Next</div>
							</button>
					</div>
				</div>
	</div>	
	<div v-if="currentTab == `account-activity`">
		<section class="mt-6 md:px-4 md:mt-10">
				<h2
					class="text-lg font-medium text-gray-900 inline-flex px-4"
				>
					<ArrowsUpDownIcon
						class="stroke-2 mr-2 h-6 w-6 text-gray-900 -rotate-180"
					/>
					Item Activity
				</h2>

				<div
					class="mt-2 pb-10 overflow-auto aspect-auto space-y-10 divide-y divide-gray-200 border-t border-gray-200 md:border md:rounded-xl"
				>
					<table class="min-w-full divide-y table-auto divide-gray-200">
						<thead class="bg-gray-200/60">
							<tr>
								<th class="w-48"></th>
								<th
									scope="col"
									class="px-2 pl-4 py-3 text-left table-auto w-48 text-sm font-normal text-gray-900"
								>
									Event
								</th>
								<th
									scope="col"
									class="px-2 py-3 text-left text-sm table-auto w-48 font-normal text-gray-900"
								>
									Price
								</th>
							
								<th
									scope="col"
									class="px-2 pr-4 py-3 text-left text-sm font-normal w-48 text-gray-900"
								>
									Date
								</th>
							</tr>
						</thead>
						<tbody v-for="item in state.accountActivityData" class="divide-y  divide-gray-200 bg-white">
							<tr>
								<td class=" flex justify-left ml-8 items-center -mr-12 mt-10" >
										<div v-if="item.order == null" class="flex flex-row">
											<ClipboardIcon class="w-8 h-8"/>
											<div class="ml-3 mt-2 text-base text-gray">Listing</div>
										</div>
										<div v-else class="flex flex-row">
											<ShoppingCartIcon class="w-8 h-8"/>
											<div class="ml-3 mt-2 text-base text-gray">Sale</div>
										</div>
									
								</td>

								<td
									v-if="!props.asset"
									class="whitespace-nowrap px-2 pl-4  py-4 text-sm font-medium text-gray-900"
								>
								
								<StorefrontActivityAsset
									:asset="item"
									:slug="getSlug(item.asset.storefront_id)"
								/>
								
								</td>

								
								<td
									class="whitespace-nowrap px-2 py-3 ml-24 text-sm text-gray-500"
								>
									<div class="flex flex-col p">
										<div class="font-normal ">{{ $format(humanReadablePrice(
												(item.listing.price * item.listing.quantity)
											)) }} {{$token}}</div>
										<to-usd
												:bpx="item.listing.price"
												:is-decimal="false"
												class="font-normal"
											/>
									</div>
								</td>
							
								<td
									class="whitespace-nowrap px-2 pr-4 py-3 text-sm text-gray-500"
								>
									{{ formatDistanceToNow(new Date(item.listing.created_at)) }} ago

								</td>
							</tr>
						</tbody>
					</table>
				</div>
						
				</section>
				
	</div>	
	<div v-if="props.asset">
		<section class="mt-6 md:px-4 md:mt-10">
				<h2
					class="text-lg font-medium text-gray-900 inline-flex px-4"
				>
					<ArrowsUpDownIcon
						class="stroke-2 mr-2 h-6 w-6 text-gray-900 -rotate-180"
					/>
					Item Activity
				</h2>

				<div
					class="mt-2 pb-10 overflow-auto aspect-auto space-y-10 divide-y divide-gray-200 border-t border-gray-200 md:border md:rounded-xl"
				>
					<table class="min-w-full divide-y table-auto divide-gray-200">
						<thead class="bg-gray-200/60">
							<tr>
								<th class="w-48"></th>
								<th
									scope="col"
									class="px-2 pl-4 py-3 text-left table-auto w-48 text-sm font-normal text-gray-900"
								>
									Event
								</th>
								<th
									scope="col"
									class="px-2 py-3 text-left text-sm table-auto w-48 font-normal text-gray-900"
								>
									Price
								</th>
							
								<th
									scope="col"
									class="px-2 pr-4 py-3 text-left text-sm font-normal w-48 text-gray-900"
								>
									Date
								</th>
							</tr>
						</thead>
						<tbody v-for="item in state.activityDataAsset" class="divide-y  divide-gray-200 bg-white">
							<tr>
								<td class=" flex justify-left ml-8 items-center -mr-12 mt-10" >
								
										<ShoppingCartIcon class="w-8 h-8"/>
										<div class="ml-3 text-base text-gray">Sale</div>
								
									
								</td>

								

								<td
								
									class="whitespace-nowrap px-2 pl-4  py-4 text-sm font-medium text-gray-900"
								>
								
								<StorefrontActivityAsset
									:asset="props"
									:slug="route.params.slug"
								/>
								
								</td>
								<td
									class="whitespace-nowrap px-2 py-3 ml-24 text-sm text-gray-500"
								>
									<div class="flex flex-col p">
										<div class="font-normal ">{{ $format(humanReadablePrice(
												(item.listing.price * item.listing.quantity)
											)) }} {{$token}}</div>
										<to-usd
												:bpx="item.listing.price"
												:is-decimal="false"
												class="font-normal"
											/>
									</div>
								</td>
							
								<td
									class="whitespace-nowrap px-2 pr-4 py-3 text-sm text-gray-500"
								>
									{{ formatDistanceToNow(new Date(item.listing.filled_at)) }} ago

								</td>
							</tr>
						</tbody>
					</table>
				</div>
						
				</section>
				
	</div>		
	
						
						<!-- ACTIVITY PAGE -->
			
		
				
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, computed } from "vue";
import StorefrontHeader from "@/components/StorefrontHeader.vue";
import StorefrontAsset from "@/components/StorefrontAsset.vue";
import StorefrontActivityAsset from "@/components/StorefrontActivityAsset.vue";
import {formatDistanceToNow} from "date-fns"
import StorefrontFilters from "@/components/StorefrontFilters.vue";
import ToUsd from '@/components/ToUsd.vue'
import { humanReadablePrice } from '@/util/currencyFormat'
import api from '@/util/api'

import FilterSummary from "@/components/FilterSummary.vue";
import LoadingTile from "@/components/LoadingTile.vue";
import {
	ShoppingBagIcon,
	ClipboardIcon,
	ShoppingCartIcon,
	TagIcon,
	ArrowsUpDownIcon,
	ChevronDoubleDownIcon,
	ChevronDoubleUpIcon,
	UsersIcon,
	EyeIcon,
	HeartIcon,
	TableCellsIcon,
	WalletIcon,
	UserIcon,
} from '@heroicons/vue/24/outline'
import TileLoader from "@/components/TileLoader.vue";
import Grid from 'vue-virtual-scroll-grid';
import { useStorefrontStore } from '@/stores/StorefrontStore';
import { useRoute } from "vue-router";
import { AssetFilter, AssetSearchPagination } from "@/types/Storefront";
import sleep from "@/util/sleep";
import { useAccountStore } from "@/stores/AccountStore";
import {useMarketStore} from "@/stores/MarketStore";
import {useAssetStore} from "@/stores/AssetStore"
export default defineComponent({
	props: ['tab', 'asset'],
	components: { 
		Grid, 
		StorefrontHeader,
		ShoppingBagIcon,
		ArrowsUpDownIcon,
		ClipboardIcon,
		StorefrontActivityAsset,
		TagIcon,
		ChevronDoubleDownIcon,
		ChevronDoubleUpIcon,
		UsersIcon,
		ShoppingCartIcon,
		EyeIcon,
		HeartIcon,
		TableCellsIcon,
		WalletIcon,
		UserIcon, 
		StorefrontAsset, 
		 StorefrontFilters, 
		 ToUsd, 
		 FilterSummary, 
		 TileLoader,
		  LoadingTile },
	
	setup(props) {
		
		const state = reactive({
		
			activityData:{},
			accountActivityData:{},
			activityDataAsset:{},
			tmpAssetQuery: '',
			assetQuery: '',
			currentPage:1, 
			perPage:50,
			last_page:0,
			pageInfo: {} as AssetSearchPagination,
			pageProvider: null as Function,
		});

		
		

		const storeStore = useStorefrontStore();
		const accountStore = useAccountStore();
		const marketStore = useMarketStore();
		const assetStore = useAssetStore();
		const route = useRoute();

		onMounted(() => {
			// default the filters to open when the component mounts
			// if the user is on a tailwind md or bigger screen
	
		
			if(route.params.tab == `activity`){
				
				getActivity();
			}
			if(route.params.tab == `account-activity`){
				
				getAccActivity();
			}
			if(props.asset){
			
				getActivityAsset();
			}
			
			
		})

		const TAB_ACTIVITY = 'activity';
		const TAB_ACCOUNT_ACTIVITY = 'account-activity';
		const TAB_ITEMS = 'items';

		const currentTab = computed(() => {
			switch (route.params.tab) {
				case 'activity':
					return TAB_ACTIVITY;

				case 'account-activity':
					return TAB_ACCOUNT_ACTIVITY;

				default:
					return TAB_ITEMS;
				}
			})

		

		

		function range (min,max){
			var array = [],
			j = 0;
			for(var i = min; i <= max; i++){
				array[j] = i;
				j++;
			}
				return array;
		}

		

		function getSlug(id: string){
			

			for(let i = 0; i<marketStore.storeFronts.length; i++){
				if(id == marketStore.storeFronts[i].id){
					return marketStore.storeFronts[i].slug;
				}
			}

			return null;
		}

		async function getActivityAsset(){
			
			const result =  await api.loadStorefrontActivityAsset(
				route.params.slug,
				route.params.assetId,
			
			)

			state.activityDataAsset = result.data;
			// assetStore.setActivityDataAsset(result.data);
		
			return result.data;
		}

		async function getActivity(){
			

			const result = await api.loadStorefrontActivity(
				storeStore.state.storefront.slug,
				state.currentPage,
				state.perPage,
				
			)

			
			state.last_page = result.data.meta.last_page;
			state.activityData = result.data.data;
		
			
			return result.data.data;
		}

		async function getAccActivity(){

			const result = await accountStore.storeActivityAccount()
			
			state.accountActivityData = result.data;
		
			return result.data;
		}

	

		


	


		

		



		


		return {
			state,
			storeStore,
			getSlug,
		
			route,
			formatDistanceToNow,
			humanReadablePrice,
			props,
			assetStore,
		
			marketStore,
			ShoppingBagIcon,
			ShoppingCartIcon,
			TagIcon,
			ArrowsUpDownIcon,
			ClipboardIcon,
			ChevronDoubleDownIcon,
			ChevronDoubleUpIcon,
			currentTab,
			UsersIcon,
			EyeIcon,
			HeartIcon,
			TableCellsIcon,
			WalletIcon,
			UserIcon,
			range,
			getActivity,
		
		}
	}
})
</script>
