import { defineStore } from 'pinia'
import api from '@/util/api'
import { Asset } from '@/types/Asset';
import { useAuthStore } from './AuthStore';
import { Ref, computed, ref } from 'vue';
const ASSET_POLL_TIMEOUT = 60 * 1000;

export const useAccountStore = defineStore('account', () => {
	const authStore = useAuthStore();


	let assetMonInterval;

	function setup() {
		authStore.onEvent('login', () => {
			// console.log('caught login event');
			startUserAssetMonitor(ASSET_POLL_TIMEOUT)
		});
		if (userLoggedIn()) {
			// console.log('user is logged in, start monitoring assets');
			startUserAssetMonitor(ASSET_POLL_TIMEOUT);
		} else {
			// console.log('user is not logged in');
		}

		return {
			// state
			assets,

			// actions
			
			load,
			getAsset,
			storeActivityAccount,

			// getters
			assetsById,
		}
	}

	// state
	const assets = ref<Asset[]>([] as Asset[]);

	// actions
	async function load(forceReload: boolean = false): Promise<Ref<Asset[]>> {
		if (forceReload || 0 == assets.value.length) {
			assets.value = await api.loadUserAssets(
				authStore.token.data.accessToken
			)
		}

		return assets;
	}

	function getAsset(assetId: string) {
		return assetsById.value[ assetId ] ?? null;
	}

	// getters
	const assetsById = computed(() => {
		return assets.value.reduce((all, a) => {
			all[ a.asset_identifier ] = a;
			return all;
		}, {});
	});

	function userLoggedIn() {
		return authStore.authenticated;
	}

	async function pollAssets() {
		// console.log('polling for assets');
		return await load(true);
	}

	async function storeActivityAccount(){
		return await api.loadStorefrontActivityAccount(authStore.token.data.accessToken)
	}

	function startUserAssetMonitor(timeout: number) {
		if (assetMonInterval) {
			return;
		}
		// console.log('starting asset monitor');

		assetMonInterval = setInterval(pollAssets, timeout);
		pollAssets();
	}

	function stopUserAssetMonitor() {
		clearInterval(assetMonInterval);
		assetMonInterval = null;
	}

	// init
	return setup();
});
