import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { createHead } from '@vueuse/head';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import App from './App.vue';
import './style.css';
import router from './router';
import { RouterLink } from 'vue-router';
import timeago from 'vue-timeago3';
import VueGtag from 'vue-gtag';
import InstantSearch from 'vue-instantsearch/vue3/es';

import Pluralize from '@/components/Pluralize.vue';
import MainNavigation from '@/components/MainNavigation.vue';
import WalletConnected from '@/components/WalletConnected.vue';
import Modal from '@/components/Modal.vue';
import LoaderVue from '@/components/Loader.vue';
import Jazzicon from '@/components/Jazzicon.vue';
import AppFooter from '@/components/AppFooter.vue';
import WalletAddress from '@/components/WalletAddress.vue';

import Pusher from 'pusher-js';
import currencyFormat from '@/util/currencyFormat';
import { cdnURL } from '@/util/cdn';

import axios from 'axios';
//axios.defaults.withCredentials = true;

declare global {
	interface Window {
		Pusher: any | undefined;
		ethereum: any | undefined;
	}
};

if (import.meta.env.VITE_BUGSNAG_API_KEY) {
	Bugsnag.start({
		apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
		plugins: [ new BugsnagPluginVue() ],
		releaseStage: import.meta.env.VITE_ENV,
		enabledReleaseStages: ['production', 'staging'],
	});
};

const head = createHead();
const app = createApp(App);

app.use(InstantSearch);
app.use({
	install: (app, opts) => {
		app.config.globalProperties.$format = currencyFormat;
	}
});

app.use({
	install: (app, opts) => {
		app.config.globalProperties.$token = 'BPX';
	}
});

app.use({
	install: (app, opts) => {
		app.config.globalProperties.$shorten = function(address: string, len:number = 6, def:string = '') {
			if (typeof address != 'string') {
				return def;
			}

			return `${address.substring(0, len)}...${address.substring(address.length - len)}`;
		}
	}
});

app.use({
	install: (app, opts) => {
		app.config.globalProperties.$cdn = cdnURL;
	}
});

if (import.meta.env.VITE_BUGSNAG_API_KEY) {
	app.use(Bugsnag.getPlugin('vue'));
}

if (import.meta.env.VITE_GOOGLE_ANALYTICS_ID) {
	app.use(VueGtag, {
		config: { id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID }
	});
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(head);
app.use(timeago, {
	converterOptions: {
		includeSeconds: false
	}
});
app.component('pluralize', Pluralize);
app.component('main-navigation', MainNavigation);
app.component('wallet-connected', WalletConnected);
app.component('router-link', RouterLink);
app.component('modal', Modal);
app.component('loader', LoaderVue);
app.component('jazzicon', Jazzicon);
app.component('app-footer', AppFooter);
app.component('wallet-address', WalletAddress);

// if (import.meta.env.VITE_MODE == 'production') {
// 	app.use(VueGtag, {
// 		id: import.meta.env.VITE_GA_ID,
// 	})
// }

app.mount('#app');
