import { defineStore } from 'pinia'
import api from '@/util/api'
import { ExchangeRate } from '@/types/Currency'
import { BN } from 'bn.js';
import { ref, reactive } from 'vue';


export const useExchangeStore = defineStore('exchange', () => {
	let pollInterval;
	let pollPromise;
	const exchangeRate = ref<ExchangeRate | null>(null);

	async function monitorExchangeRate(force: boolean = false) {
		if ( pollInterval && false == force) {
			return await pollPromise;
		}

		if (pollInterval) {
			clearInterval(pollInterval);
		}

		pollInterval = setInterval(pollExchangeRate, 60 * 1000);
		return await pollExchangeRate();
	}

	async function pollExchangeRate() {
		pollPromise = api.getExchangeRate();
		const rate = await pollPromise;
		if (rate) {
			exchangeRate.value = rate
		}
	}

	async function usd2bpx(usd: number | string) : Promise<ExchangeRate> {
		return await api.bpxVal(usd);
	}

	async function bpx2usd(bpx: string, isDec: boolean = false) : Promise<ExchangeRate> {
		return await api.usdVal(bpx, isDec);
	}

	async function usd(amt: string, isDec: boolean = false) {
		if ( ! exchangeRate.value) {
			await monitorExchangeRate();
		}
		let bpxAmt = new BN(amt);
		if (isDec) {
			// convert 100.1234 bpx to 1001234
			let tmpMultiplier = new BN(String(Math.pow(10, 4)));
			bpxAmt = bpxAmt.mul(tmpMultiplier);
		} else {
			// convert 1001234000000000 to 1001234
			let tmpDivisor = new BN(String(Math.pow(10, 5)));
			bpxAmt = bpxAmt.div(tmpDivisor);
		}

		let rate = new BN(
			String(Math.floor(exchangeRate.value.exchangeRate * 10000))
		);

		let conv = bpxAmt.mul(rate);
		let val = Math.round(conv.div(new BN(String(Math.pow(10, 4)))).toNumber() / 100) / 100;
		return val;
	}

	return {
		usd,
	}
});
