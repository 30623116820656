// @ts-nocheck
import axios from 'axios'
import Errors from '@/util/Errors'
import { Auction, AuctionImage, Bid } from '@/types/Auction'
import { Event } from '@/types/Event'
import { Taxonomy, Tag } from '@/types/Taxonomy'
import { DateTime, Duration } from 'ts-luxon'
import {
	AssetFilter,
	Storefront,
	StorefrontAssetResponse,
} from '@/types/Storefront'
import { Asset, Listing } from '@/types/Asset'
import { ExchangeRate } from '@/types/Currency'
import { MarketCurrency } from '@/stores/NewWalletStore'

const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT
const CBS_API_ENDPOINT = import.meta.env.VITE_CBS_API_ENDPOINT

function parseAuctionApiObject(auction: any): Auction {
	let a: Auction = {
		id: auction.id,
		blockchain_auction_id: auction.blockchain_auction_id,
		seller_token_id: auction.seller_token_id,
		title: auction.title,
		description: auction.description,
		md_description: auction.md_description,
		starting_bid: auction.starting_bid,
		buyer_premium_bps: auction.buyer_premium_bps,
		bid_increment: auction.bid_increment,
		auction_group: {
			id: auction.auction_group.id,
			name: auction.auction_group.name,
			slug: auction.auction_group.slug,
			image: auction.auction_group.image,
			start_at: DateTime.fromISO(auction.auction_group.start_at),
			end_at: DateTime.fromISO(auction.auction_group.end_at),
		},
		extended_bidding_seconds: Duration.fromObject({
			seconds: auction.extended_bidding_seconds,
		}),
		current_status: auction.current_status,
		bid_count: auction.bid_count,
		high_bid: auction.high_bid,
		high_bidder: auction.high_bidder,
		last_bid_at: auction.last_bid_at
			? DateTime.fromISO(auction.last_bid_at)
			: null,
		end_at: DateTime.fromISO(auction.end_at),
		images: [] as AuctionImage[],
		bids: [] as Bid[],
		tags: [] as Tag[],

		claimed_at: auction.claimed_at
			? DateTime.fromISO(auction.claimed_at)
			: null,
		claimed_by: auction.claimed_by || null,
		claim_recipient: auction.claim_recipient || null,
		claim_tx_hash: auction.claim_tx_hash || null,

		proceeds_claimed_at: auction.proceeds_claimed_at
			? DateTime.fromISO(auction.proceeds_claimed_at)
			: null,
		proceeds_claimed_by: auction.proceeds_claimed_by || null,
		proceeds_claim_recipient: auction.proceeds_claim_recipient || null,
		proceeds_claim_tx_hash: auction.proceeds_claim_tx_hash || null,
	}

	auction.image.map((img: AuctionImage) => {
		a.images.push({
			uri: img.uri,
			display_order: img.display_order,
		})
	})

	if (auction.bid) {
		auction.bid.map((bid: Bid) => {
			a.bids.push(parseBidApiObject(bid))
		})
	}

	if (auction.tags) {
		auction.tags.map((tag: Tag) => {
			a.tags.push(parseTagApiObject(tag))
		})
	}

	return a
}

function parseBidApiObject(bid: Bid) {
	const b = {
		auction_id: bid.auction_id,
		blockchain_auction_id: bid.blockchain_auction_id,
		bid: bid.bid,
		bidder: bid.bidder,
		tx_hash: bid.tx_hash,
		bid_at: DateTime.fromISO(bid.bid_at),

		auction: bid.auction ? parseAuctionApiObject(bid.auction) : null,
	}

	return b
}

function parseTagApiObject(tag: Tag) {
	return {
		id: tag.id,
		name: tag.name,
		slug: tag.slug,
	} as Tag
}

function parseTaxonomy(data: any): Taxonomy {
	let t: Taxonomy = {
		id: data.id,
		name: data.name,
		slug: data.slug,
		tags: [] as Tag[],
	}

	data.tags.map((tag: any) => {
		t.tags.push(parseTagApiObject(tag))
	})

	return t
}

function parseEventApiObject(event: any): Event {
	let e: Event = {
		id: event.id,
		name: event.name,
		slug: event.slug,
		image: event.image,
		active: event.active,
		start_at: DateTime.fromISO(event.start_at),
		end_at: DateTime.fromISO(event.end_at),
	}
	return e
}

function apiUri(path: string) {
	return `${API_ENDPOINT}${path}`
}

function cbsApiUri(path: string) {
	return `${CBS_API_ENDPOINT}${path}`
}

async function fetchEvents(
	page: number | null = null,
	sort: string | null = null,
	limit: number | null = null
): Promise<Event[]> {
	const response = await axios.get(apiUri(`/api/events`), {
		page,
		sort,
		limit,
	})

	if (response.status !== 200) {
		throw Errors.HttpError('Failed to fetch auction index', response)
	}

	let events: Event[] = response.data.data.map((event: any) => {
		return parseEventApiObject(event)
	})

	return events
}

async function fetchAuctions(
	page: number | null,
	sort: string | null,
	limit: number | null
): Promise<Auction[]> {
	const response = await axios.get(apiUri(`/api/auctions`), {
		page,
		sort,
		limit,
	})

	if (response.status !== 200) {
		throw Errors.HttpError('Failed to fetch auction index', response)
	}

	let auctions: Auction[] = response.data.data.map((auction: any) => {
		return parseAuctionApiObject(auction)
	})

	return auctions
}

async function fetchAuction(id: number): Promise<Auction> {
	const response = await axios.get(apiUri(`/api/auction/${id}`))

	if (response.status !== 200) {
		throw Errors.HttpError('Failed to fetch auction', response)
	}

	return parseAuctionApiObject(response.data.data)
}

async function fetchTaxonomy(): Promise<Taxonomy[]> {
	const response = await axios.get(apiUri(`/api/taxonomy`))

	if (response.status != 200) {
		throw Errors.HttpError('Failed to fetch taxonomy', response)
	}

	return response.data.data.map((data: any): Taxonomy => {
		return parseTaxonomy(data)
	}) as Taxonomy[]
}

async function notifyBid(txHash: string): Promise {
	return await axios.post(apiUri(`/api/auction/bid`), {
		txHash: txHash,
	})
}

async function loadWatched(wallet: string): Promise<number[]> {
	const response = await axios.get(apiUri(`/api/account/${wallet}/watched`))

	if (response.status !== 200) {
		return [] as number[]
	}

	return response.data.auction.map((a) => {
		const auction = parseAuctionApiObject(a)
		return auction.id
	})
}

async function loadProfile(token: AuthToken): Promise {
	const response = await axios.get(apiUri(`/api/account`), {
		headers: {
			Authorization: `Bearer ${token.base64_encode()}`,
		},
	})

	if (response.status !== 200) {
		return null
	}

	return {
		profile: {},
		// watched_auctions: response.data.watched_auctions.map( a => {
		// 	const auction = parseAuctionApiObject(a)
		// 	return auction.id
		// }),
		bid_auctions: response.data.bid_auctions.map(parseAuctionApiObject),
		won_auctions: response.data.won_auctions.map(parseAuctionApiObject),
		owned_auctions: response.data.owned_auctions.map(parseAuctionApiObject),
		// bids: response.data.bids.map(parseBidApiObject),
	}
}

async function watchAuction(
	token: AuthToken,
	auctionId: number,
	watch: boolean
): Promise<number[]> {
	const method = watch ? 'post' : 'delete'
	const response = await axios({
		url: apiUri(`/api/account/auctions/watch`),
		method,
		data: {
			id: auctionId,
		},
		headers: {
			Authorization: `Bearer ${token.base64_encode()}`,
		},
	})

	if (response.status !== 200) {
		return [] as number[]
	}

	return response.data.map((a) => a.id)
}

async function getLoginUri(redirectTo: string): Promise<string> {
	const response = await axios({
		// withCredentials: true,
		url: apiUri('/api/login/redirect'),
		method: 'POST',
		data: {
			redirect_to: redirectTo,
		},
		headers: {
			Accept: 'application/json',
		},
	})

	if (response.status !== 200) {
		// bad news
		return 'nope'
	}

	return response.data.uri
}

async function authenticate(code: string, state: string): Promise<any> {
	const response = await axios({
		url: apiUri('/api/login/auth'),
		method: 'POST',
		data: {
			code,
			state,
		},
		headers: {
			Accept: 'application/json',
		},
	})

	if (response.status !== 200) {
		return 'nope'
	}

	return response.data
}

async function authenticateHandoff(
	payload: string,
	ts: string,
	salt: string
): Promise<any> {
	const response = await axios({
		url: apiUri('/api/login/auth-handoff'),
		method: 'POST',
		data: {
			payload,
			ts,
			salt,
		},
		headers: {
			Accept: 'application/json',
		},
	})

	if (response.status !== 200) {
		return 'nope'
	}

	return response.data
}

async function whoami(accessToken: string): Promise<any> {
	const response = await axios(apiUri('/api/whoami'), {
		params: {
			accessToken,
		},
	})

	if (response.status != 200) {
		// bad
		return null
	}

	return response.data.data
}

async function withdraw(
	accessToken: string,
	asset: string,
	amount: string,
	wallet: string
): Promise<any> {
	const response = await axios({
		url: apiUri(`/api/account/withdraw/${asset}`),
		method: 'POST',
		params: {
			accessToken,
			amount,
			wallet,
		},
	})

	if (response.status !== 200) {
		return 'nope'
	}

	return response.data
}

async function balance(accessToken: string): Promise<any> {
	const response = await axios({
		url: apiUri('/api/account/balance'),
		method: 'GET',
		params: {
			accessToken,
		},
	})

	if (response.status !== 200) {
		return 'nope'
	}

	return response.data
}

async function loadStorefronts(): Promise<Storefront[]> {
	const response = await axios(apiUri('/api/storefronts'))

	if (200 != response.status) {
		return [] as Storefront[]
	}

	return response.data.data
}

async function loadStorefront(slug: string): Promise<Storefront | null> {
	const response = await axios(apiUri(`/api/storefront/${slug}`))

	if (200 != response.status) {
		return null
	}

	return response.data.data
}

async function loadStorefrontMetadata(slug: string): Promise<StorefrontMetadata[]> {
	const response = await axios(apiUri(`/api/storefront/${slug}/metadata`))

	if (200 != response.status) {
		return {}
	}

	return response.data.data
}

async function loadStorefrontAssets(
	slug: string,
	pgNumber: number = 0,
	perPage: number = 100,
	filters: AssetFilter
): Promise<StorefrontAssetResponse[]> {
	const response = await axios({
		method: 'GET',
		url: apiUri(`/api/storefront/${slug}/assets`),
		params: { ...filters, pg: pgNumber, perPage },
	})


	if (200 != response.status) {
		return {
			assets: [],
			pagination: {}
		}
	}

	return {
		assets: response.data.data,
		pagination: response.data.meta,
	}
}

async function loadStorefrontActivity(slug:string, pgNumber:number=0, perPage:number=50){

		
		const response = await axios({
			method: 'GET',
			url: apiUri(`/api/storefront/${slug}/activity`),
			params: {  pg: pgNumber, perPage:perPage },
		})
	
		
		const data = response.data;

	
	return {
		data
	}
}

async function loadStorefrontActivityAsset(slug:string, asset:number){
	const response = await axios({
		method: 'GET',
		url: apiUri(`/api/storefront/${slug}/asset/${asset}/activity`),
		
	})

	const data = response.data.data;
	
	return {
		data
	}
}

async function loadStorefrontActivityAccount(accessToken: String){
	const response = await axios({
		method: 'GET',
		url: apiUri(`/api/account/activity`),
		params: {
			accessToken,
		},
	})

	const data = response.data.data;
	return {
		data
	}
}

async function loadAsset(
	slug?: string,
	assetId?: number,
	userId?: string
): Promise<any> {
	return await axios({
		url: apiUri(`/api/storefront/${slug}/asset/${assetId}`),
		method: 'GET',
		data: {
			user: userId,
		},
		headers: {
			Accept: 'application/json',
		},
	}).then((res) => {
		return res.data
	})
}

async function loadUserAssets(accessToken: string): Promise<Asset[]> {
	const assets = await axios({
		url: apiUri(`/api/account/assets`),
		method: 'GET',
		params: {
			accessToken,
		},
		headers: {
			Accept: 'application/json',
		},
	})

	return assets.data.data
}

async function getExchangeRate(): Promise<ExchangeRate> {
	const { status, data } = await axios({
		url: apiUri(`/api/exchange`),
		method: 'GET',
		headers: { Accept: 'application/json' },
	})

	return data.data
}

async function usdVal(
	bpxVal: string,
	isDec: boolean = false
): Promise<ExchangeRate> {
	const response = await axios({
		url: apiUri(`/api/exchange`),
		method: 'GET',
		params: {
			[isDec ? 'bpx_dec' : 'bpx']: bpxVal,
		},
		headers: { Accept: 'application/json' },
	})

	return response.data.data
}

async function bpxVal(usdVal: number | string): Promise<ExchangeRate> {
	const response = await axios({
		url: apiUri(`/api/exchange`),
		method: 'GET',
		params: {
			usd: usdVal,
		},
		headers: { Accept: 'application/json' },
	})

	return response.data.data
}

async function getCurrencyBalances(
	accessToken: string
): Promise<MarketCurrency[]> {
	const response = await axios({
		url: apiUri(`/api/account/balance`),
		method: 'GET',
		params: {
			accessToken,
		},
	})

	if (response.status !== 200) {
		return [] as MarketCurrency[]
	}

	return response.data
}

async function createListing(
	listing: NewListing,
	accessToken: string
): Promise<Listing | null> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/storefront/${listing.storefront_id}/listing`),
			method: 'POST',
			params: {
				accessToken,
			},
			data: {
				listing,
			},
		})

		return data.data
	} catch (e) {
		console.error('Failed to create asset listing: ', e.message)
		return null
	}
}

async function editListing(
	listingID: string,
	listing: NewListing,
	accessToken: string
): Promise<Listing | null> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/listing/${listingID}`),
			method: 'PUT',
			params: {
				accessToken,
			},
			data: {
				listing,
			},
		})

		return data.data
	} catch (e) {
		console.error('Failed to create asset listing: ', e.message)
		return null
	}
}

async function deleteListing(
	listingID: string,
	accessToken: string
): Promise<boolean> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/listing/${listingID}`),
			method: 'DELETE',
			params: {
				accessToken,
			},
		})

		return data.success
	} catch (e) {
		console.error('Failed to delete listing: ', e.message)
		return false
	}
}

async function createOrder(
	assetID: string,
	listingID: string,
	accessToken: string
): Promise<PaymentIntent | null> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/order`),
			method: 'POST',
			params: {
				accessToken,
			},
			data: {
				asset_id: assetID,
				listing_id: listingID,
			},
		})

		return data
	} catch (e) {
		console.error('Failed to create order: ', e.message)
		return null
	}
}

async function buyListing(
	listingID: string,
	accessToken: string
): Promise<{ success: boolean; message: string; code?: number } | null> {
	try {
		const { status, data } = await axios({
			url: apiUri(`/api/listing/${listingID}/buy`),
			method: 'POST',
			params: {
				accessToken,
			},
		})

		if (status) {
			return data
		}

		return {
			success: false,
			message: 'Failed to purchase listing',
		}
	} catch (e) {
		console.error('Failed to purchase listing: ', e.message)
		return {
			success: false,
			message: 'Failed to purchase listing',
		}
	}
}

async function transferAssets(assets: { asset_id: string; quantity: number }[], recipient: string, pin: string | null, accessToken: string) : Promise<{ success: boolean; message: string; code: number | string | null; }> {
	try {
		const { status, data }= await axios({
			url: apiUri(`/api/account/transfer`),
			method: 'POST',
			params: {
				accessToken,
			},
			data: {
				assets,
				recipient,
				pin
			}
		});

		if (status < 200 || status > 299) {
			return {
				success: false,
				message: `Failed to transfer ${assets.length != 1? 's' : ''}`
			};
		}

		if (data.success) {
			return {
				success: true,
				message: `Asset${ assets.length != 1? 's' : ''} transferred successfully`,
			};
		} else {
			return {
				success: false,
				message: data.message
			};
		}
	} catch (e) {
		console.error(e.message);
		return {
			success: false,
			message: `Failed to transfer ${assets.length != 1? 's' : ''}`
		};
	}
}

export default {
	fetchEvents,
	fetchAuctions,
	fetchAuction,
	fetchTaxonomy,
	notifyBid,
	loadWatched,
	loadProfile,
	watchAuction,
	getLoginUri,
	authenticate,
	authenticateHandoff,
	loadStorefrontActivityAccount,
	whoami,
	withdraw,
	balance,
	loadStorefronts,
	loadStorefront,
	loadStorefrontMetadata,
	loadStorefrontAssets,
	loadStorefrontActivity,
	loadStorefrontActivityAsset,
	loadAsset,
	loadUserAssets,
	getExchangeRate,
	usdVal,
	bpxVal,
	getCurrencyBalances,
	createListing,
	editListing,
	deleteListing,
	createOrder,
	buyListing,
	transferAssets,
}
