<template>
	<account-login v-if=" ! authStore.authenticated" />
	<div v-else class="relative">
		<div class="md:flex">
			<!-- class="md:block sticky top-0 left-0 h-screen w-1/2 bg-white shadow-xl z-20" /> -->
			<account-nav v-if="false"
			 	:forceShow="showNav"
				class="absolute inset-0 right-auto bg-white shadow-xl z-20
				md:block md:relative md:shadow-none md:left-auto md:top-auto md:bottom-auto md:bg-transparent"
				@close="showNav = false"
			/>
			<p class="md:hidden m-4" v-if="false">
				<button @click="toggleNav"><i class="fa-2x fa-solid fa-bars"></i></button>
			</p>
			<component class="flex-grow m-4 mb-0"
				:is="accountComponent"
			/>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, PropType, reactive, ref } from "vue";

// components
import AccountLogin from '@/pages/Account/AccountLogin.vue';
import AccountNav from "@/components/AccountNav.vue";
// import AuctionTile from "@/components/AuctionTile.vue";
// import AuctionListingItem from "@/components/AuctionListingItem.vue";
// import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
// import BidHistoryItem from "@/components/BidHistoryItem.vue";
// import WalletConnected from "@/components/WalletConnected.vue";
import Home from '@/pages/Account/Home.vue'
import SoldAuctions from '@/pages/Account/SoldAuctions.vue'
import WatchedAuctions from '@/pages/Account/WatchedAuctions.vue'
import WonAuctions from '@/pages/Account/WonAuctions.vue'
import BidHistory from '@/pages/Account/BidHistory.vue'
import Collection from "./Account/Collection.vue";

import { useAuctionStore } from "@/stores/AuctionStore";
import { useAuthStore, AuthToken } from "@/stores/AuthStore";
import { useProfileStore } from '@/stores/ProfileStore'
import metamask from '@/util/metamask'
import Currency from "@/types/Currency";
import { useAppStore } from "@/stores/AppStore";
import { Auction } from "@/types/Auction";
import { useRoute } from "vue-router";

export default defineComponent({
    components: { AccountNav, AccountLogin },
	setup(props) {
		const authStore = useAuthStore();
		const route = useRoute();

		const appStore = useAppStore()
		const auctionStore = useAuctionStore();
		const profileStore = useProfileStore();
		const navContainer = ref(null)
		const showNav = ref(false)

		onMounted(async() => {
			if ( ! authStore.authenticated && ! await authStore.login()) {
				window.top.location = await authStore.getLoginUri(route.path)
				return
			}
		})

		const accountComponent = computed(() => {
			switch (route.params.subpage) {
				// case 'sold':
				// 	return SoldAuctions;

				// case 'watched':
				// 	return WatchedAuctions;

				// case 'won':
				// 	return WonAuctions;

				// case 'bids':
				// 	return BidHistory;

				default:
					// return Home;
					return Collection;
			}
		});

		function toggleNav() {
			// console.log(navContainer, navContainer.value)
			// navContainer.value.classList.toggle('hidden')
			const before = showNav.value
			showNav.value = ! showNav.value
			// console.log('toggling nav', 'before: ', before, 'after: ', showNav.value)
		}

		return {
			// state,
			Currency,
			appStore,
			auctionStore,
			authStore,
			metamask,
			navContainer,
			showNav,
			toggleNav,
			accountComponent,
			// authenticate,
		}
	}
})
</script>
