<template>
	<div>
		<div class="relative">
			<div class="h-64 bg-cover" :style="[`background-image: url('${props.storefront.bg_image}')`]"></div>
			<div class="absolute left-4 -bottom-[2rem] aspect-square w-32 h-32 rounded-2xl p-2 bg-white shadow-md">
				<img :src="props.storefront.pfp_image" class="w-full rounded-xl">
			</div>
		</div>

		<div class="mx-4 mt-12">
			<h1 class="text-3xl font-semibold">{{ props.storefront.name }}</h1>

			<div class="flex space-x-4" v-if="false">
				<p>
					<label class="font-medium">Unique cards</label>: 1,500
				</p>
				<p>
					<label class="font-medium">Total cards</label>: 247,500
				</p>
			</div>
			<div class="flex space-x-4 my-2">
				<template v-if="false && props.storefront.details.links">
					<p v-for="link in props.storefront.details.links" :key="`link-${link.text}`">
						<a target="_blank" :href="link.href"
							class="text-blue-900 hover:text-blue-600">
							<i v-if="link.icon" class="fa-solid" :class="link.icon"></i>
							{{ link.text }}
						</a>
					</p>
				</template>
				<!-- <p class="flex-grow"></p> -->
				<p v-if="props.storefront.details.web">
					<a target="_blank" :href="props.storefront.details.web"
						class="text-blue-900 hover:text-blue-600"
						:title="`Website ${props.storefront.name} - ${props.storefront.details.web}`">
						<i class="fa-sharp fa-light fa-globe"></i>
						<span class="hidden">Website</span>
					</a>
				</p>
				<p v-if="props.storefront.details.discord">
					<a target="_blank" :href="`https://discord.gg/${props.storefront.details.discord}`"
						class="text-blue-900 hover:text-blue-600"
						:title="`Discord @${props.storefront.details.discord}`">
						<i class="fa-brands fa-discord"></i>
						<span class="hidden">Discord</span>
					</a>
				</p>
				<p v-if="props.storefront.details.twitter">
					<a target="_blank" :href="`https://twitter.com/${props.storefront.details.twitter}`"
						class="text-blue-900 hover:text-blue-600"
						:title="`Twitter @${props.storefront.details.twitter}`">
						<i class="fa-brands fa-twitter"></i>
						<span class="hidden">Twitter</span>
					</a>
				</p>
			</div>

			<div v-if="! props.loading"
				class="space-y-2"
				v-html="props.storefront.details.description_html"></div>
		</div>
	</div>
</template>
<script lang="ts">
import { Storefront } from "@/types/Storefront";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		storefront: {
			required: true,
			type: Object as PropType<Storefront>,
		},
		loading: {
			required: false,
			type: Boolean,
			default: () => false,
		},
	},
	setup(props) {

		return {
			props
		}
	}
})
</script>
