<template>
    
        <div class=" rounded-xl cursor-pointer">
            <div class="group relative">
                <p v-if="props.balance && (props.balance?.balance ?? 0) > 1"
                    class="flex justify-center items-center
                    absolute top-4 right-4
                    px-2 py-2
                    leading-none text-sm
                    bg-black/80 rounded-lg text-white
                    shadow-md shadow-gray-400
                    z-10">
                    <i class="fa-regular fa-layer-group mr-1 p-0 m-0 leading-none"></i>
                    {{ props.balance?.balance ?? 0 }}
                </p>
                <div class=" overflow-hidden ">
                    <router-link
                        :to="{ name: 'asset', params: { slug: props.slug, assetId: props.asset.asset.id }}">
                    <div class="flex flex-row">
                    <img
                        :src="props.asset.asset.image"
                        @load="state.loaded = true"
                        loading="lazy"
                        class="ease-in-out w-auto rounded-xl h-20 duration-300 hover:scale-10" />
                        <div class="flex flex-col mt-4">
                            <h1 class="flex justify-center items-center ml-4">{{props.asset.asset.name}}</h1>
                            <h1 v-if="route.params.assetId == undefined" class="flex justify-left items-center ml-4 font-normal text-gray-500">Quantity: {{ props.asset.listing.quantity }} </h1>
                        </div>
                    </div>
                   
                    </router-link>
                    <div class="flex items-end p-4 opacity-0 group-hover:opacity-100 hidden" aria-hidden="true">
                        <button
                            type="button"
                            class="pt-2 flex w-full inline-flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-8 py-3 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50  ease-in-out duration-300 hover:scale-110">
                            Buy Now
                        </button>
                    </div>
                </div>
            </div>

        </div>
        
  
</template>
<script lang="ts">
import { Asset } from "@/types/Asset";
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import LoadingPlaceholder from "./LoadingPlaceholder.vue";
import { useMarketStore } from "@/stores/MarketStore";
import { ShoppingBagIcon } from '@heroicons/vue/24/outline';
import { humanReadablePrice } from "@/util/currencyFormat";
import { useRoute } from "vue-router";

export default defineComponent({
    components: { LoadingPlaceholder, ShoppingBagIcon },
	props: {
		asset: {
			required: true,
			type: Object as PropType<Asset>,
		},
		slug: {
			required: true,
			type: String,
		},
		showStorefrontName: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		balance: {
			required: false,
			type: [Object, Boolean] as PropType<any | boolean>,
			default: () => false
		},
	},
	setup(props) {
		const state = reactive({
			loaded: false,
		});
        const route = useRoute();
		const marketStore = useMarketStore();
		const storefront = computed(() => {
			return marketStore.byID[props.asset.asset.storefront.id]
		});


		return {
			props,
            route,
			state,
			storefront,
			humanReadablePrice,
		}
	}
})
</script>
