<template>
	<div>
		<p class="text-lg text-center">
			Authenticating account
		</p>
		<loader />
	</div>
</template>
<script lang="ts">
import Loader from "@/components/Loader.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    components: { Loader },
	setup(props) {

		return {

		}
	}
})
</script>
