import { defineStore } from 'pinia'
import metamask from '@/util/metamask'
import api from '@/util/api';
import { computed, ref } from 'vue';
import EventBus from '@/util/eventBus';

const SESSION_TIMEOUT = 120 * 60;
const lsKey = 'bpxid-at';
let AUTH: AuthToken;
let eventEmitter: EventBus;

export class AuthToken {
	public data : AuthTokenData;

	constructor(token: AuthTokenData) {
		this.data = token
	}

	persist(): void {
		window.localStorage.setItem(lsKey, this.toString())
	}

	// expired() : boolean {
	// 	const now = Math.ceil(Date.now() / 1000);

	// 	if (now - this.data.timestamp > SESSION_TIMEOUT) {
	// 		return true
	// 	}

	// 	return false
	// }

	valid() : boolean {
		const user = api.whoami(this.data.accessToken);

		if (user) {
			return true;
		}

		return false;
	}

	toString() : string {
		return JSON.stringify(this.data)
	}

	base64_encode() : string {
		return window.btoa(this.toString())
	}

	static clear(wallet: string) : void {
		window.localStorage.removeItem(wallet)
	}

	static fromString(token: string) : AuthToken {
		return new AuthToken(JSON.parse(token))
	}

	static load() : AuthToken | null {
		const t = window.localStorage.getItem(lsKey)

		if (t) {
			return AuthToken.fromString(t)
		}

		return null;
	}
}

interface AuthTokenData {
	accessToken: string
}

interface UserType {
	id: string
	name: string
	email: string
	email_verified_at: Date | null
	metadata: {
		accounts: string[] | null
	} | null
}

class User {
	user: UserType;

	constructor(usr: UserType) {
		this.user = usr as UserType;
	}

	get id() : string {
		return this.user.id
	}

	get name() : string {
		return this.user.name;
	}

	get email() : string {
		return this.user.email
	}

	get verified() : boolean {
		return ! (this.user.email_verified_at == null);
	}
}

export const useAuthStore = defineStore('auth', () => {
	eventEmitter = new EventBus();
	const user = ref<UserType | null>(null);
	const token = ref<AuthToken | null>(null);

	function doLogin(newUser: UserType, newToken: AuthToken) {
		user.value = newUser;
		token.value = newToken;
		token.value.persist();
		// console.log('emitting login event');
		eventEmitter.emit('login', user)
	}

	async function getLoginUri(redirectTo: string) : Promise<string> {
		return await api.getLoginUri(redirectTo);
	}

	async function authenticate(code: string, state: string) : Promise<string> {
		const authResponse = await api.authenticate(code, state);

		if (authResponse.status != 'ok') {
			// couldnt authenticate the user, wat happened??
			return;
		}

		doLogin(authResponse.user, new AuthToken({
			accessToken: authResponse.accessToken
		}));

		return authResponse.redirect_to;
	}

	async function authenticateHandoff(payload: string, timestamp: string, salt: string): Promise<string> {
		const authResponse = await api.authenticateHandoff(payload, timestamp, salt);
		if (authResponse.status != 'ok') {
			// couldnt negotiate the handoff :(
			return '/';
		}

		doLogin(authResponse.user, new AuthToken({
			accessToken: authResponse.accessToken
		}));
		return authResponse.redirect_to;
	}

	async function login(): Promise<boolean> {
		const token = AuthToken.load();

		if ( ! token) {
			return false;
		}

		const response = await api.whoami(token.data.accessToken);

		if ( ! response) {
			return false;
		}

		doLogin(response, token);

		return true;
	}

	async function redirectToLogin(nextPath: string) {
		window.top.location = await getLoginUri(nextPath);
	}

	const authenticated = computed(() => {
		return !! user.value;
	})

	const accountID = computed(() => {
		if ( ! authenticated.value) {
			return null;
		}

		return user.value.metadata.accounts[0];
	})

	return {
		// state
		user,
		token,

		// actions
		getLoginUri,
		redirectToLogin,
		authenticate,
		authenticateHandoff,
		login,

		/* event methods */
		onEvent: (name, fn) => {
			return eventEmitter.on(name, fn);
		},

		offEvent: (name, fn) => {
			return eventEmitter.off(name, fn);
		},

		onOneEvent: (name, fn) => {
			return eventEmitter.once(name, fn);
		},

		// getters
		authenticated,
		accountID,
	}
})
