import { defineStore } from 'pinia'
import api from '@/util/api'
import { AssetFilter, Storefront, StorefrontAssetResponse, StorefrontMetadata } from '@/types/Storefront'
import { Asset } from '@/types/Asset';
import { Ref, ref } from 'vue';

interface StoreFrontStore {
	storefront: Storefront | null;
	metadata: any;
	assets: Asset[];
	activeFilters: any;
}

export const useStorefrontStore = defineStore('storefront', () => {

	const state: Ref<StoreFrontStore> = ref({
		storefront: null,
		metadata: {},
		accountActivityData:{},
		assets: [],
		activeFilters: {},
		activityData: {},
		currentActivityData: {},
		currentAsset: {},
		currentTab: "",
	});

	function loadStore(storeSlug: string) {
		if (storeSlug === state.value.storefront?.slug) {
			return loadStoreExisting(storeSlug);
		} else {
			return loadStoreNew(storeSlug);
		}
	}

	async function storeAssets(slug: string, pgNumber: number = 0, perPage: number = 100, filters: AssetFilter)
		: Promise<StorefrontAssetResponse> {
			
		/* @ts-ignore */
		return await api.loadStorefrontAssets(
			slug,
			pgNumber,
			perPage,
			filters
		)
	}
	function prepareFilterParams(query: string, filters: any = {}) : AssetFilter {
		// @ts-ignore
		return {
			query,
			filters,
		}
	}

	function loadStoreNew(storeSlug: string) {
		resetStore();

		const promises = [
			api.loadStorefront(storeSlug).then((s: Storefront) => state.value.storefront = s),
			api.loadStorefrontMetadata(storeSlug).then((m: StorefrontMetadata[]) => state.value.metadata = m),
		];

		return Promise.all(promises).then(() => {
			return state.value.storefront;
		});
	}

	function loadStoreExisting(storeSlug: string) {
		api.loadStorefrontMetadata(storeSlug)
			.then((m) => state.value.metadata = m);
	}

	function resetStore() {
		state.value.metadata = {};
		state.value.storefront = null;
		state.value.assets = [];
		state.value.activeFilters = {};
	}

	function clearFilters() {
		state.value.activeFilters = {};
	}

	function removeFilter(filter: string, value: string) {
		if (undefined === state.value.activeFilters[ filter ]) {
			return
		}

		state.value.activeFilters[filter]
			.splice(state.value.activeFilters[filter].indexOf(value), 1);
	}

	function setFilters(filter: string, selected: string[]) {
		state.value.activeFilters[ filter ] = selected
	}

	function isFilterActive(filter: string) {
		return filter in state.value.activeFilters && state.value.activeFilters[filter].length;
	}

	function toggleFilter(filter: string, value: string, enabled: boolean | null = null) {
		const currentIndex = state.value.activeFilters[ filter ] !== undefined
			? state.value.activeFilters[ filter ].indexOf(value)
			: -1

		const filterState = enabled === null
			? ! (currentIndex >= 0)
			: enabled;


		if (filterState && currentIndex === -1) {
			if (undefined == state.value.activeFilters[ filter ]) {
				state.value.activeFilters[ filter ] = []
			}

			state.value.activeFilters[ filter ].push(value)
		} else {
			state.value.activeFilters[ filter ].splice(
				state.value.activeFilters[ filter ].indexOf(value),
				1
			)
		}
	}

	async function loadStoreAssets(
		slug: string,
		pgNumber: number = 0,
		perPage: number = 100,
		filters: AssetFilter,
	): Promise<StorefrontAssetResponse> {
		return await api.loadStorefrontAssets(
			slug,
			pgNumber,
			perPage,
			filters
		)
	}

	return {
		state,
		loadStore,
		clearFilters,
		removeFilter,
		toggleFilter,
		setFilters,
		loadStoreAssets,
		isFilterActive,
	}
});
