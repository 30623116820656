<template>
	<LoadingStorefront v-if="state.loading" />
	<div v-else>
		<storefront-purchase-modal
			v-if="state.selectedListing"
			:asset="state.asset"
			:listing="state.selectedListing"
			@close="state.selectedListing = null"
		/>
		<feedback-modal @close="clearFeedback" v-if="null !== state.feedback">
			<template #title>
				<span class="text-slate-900 font-poppins">
					<span v-if="state.feedback.success">
						<span class="inline-flex justify-center items-center text-green-600 bg-green-100 aspect-square px-3 rounded-full"><i class="fa-solid fa-check"></i></span>
						<span class="ml-3">
							Congratulations!
						</span>
					</span>
					<span v-else>
						<span class="inline-flex justify-center items-center text-red-600 bg-red-100 aspect-square px-3 rounded-full"><i class="fa-regular fa-circle-exclamation"></i></span>
						<span class="ml-3">
							Something went wrong!
						</span>
					</span>
				</span>
			</template>
			<template #body>
				<div class="text-xl my-4">
					<p>{{ state.feedback.message }}</p>
				</div>
			</template>
			<template #buttons="{ close }">
				<button class="btn-primary-lg mt-2"
					@click.stop="close">
					Dismiss
				</button>
			</template>
		</feedback-modal>
		<transfer-asset-modal
			v-if="state.transferAsset"
			:asset="state.asset"
			:max-transferrable="userOwned"
			@close="state.transferAsset = false"
			@complete="loadAsset(), state.transferAsset = false"
		/>
		<div>
			<!--
				determine whether or not we show our list for sale button based on
				asset ownership, as well as the total number of assets this user
				has listed (where applicable)
				-->
			<div
				v-if="userOwned > 0"
				class="sticky top-[68px] bg-gray-50 z-20 shadow-sm px-4 pt-4 pb-4 border-b border-b-slate-200"
			>
				<div class="flex items-center justify-end max-w-7xl mx-auto">
					<button
						@click="state.transferAsset = true"
						v-show="true"
						v-cloak
						title="Send this NFT to another account"
						class="
						aspect-square w-12 h-12 flex justify-center items-center
						px-2 py-2
						text-gray-600 leading-none text-sm
						cursor-pointer
						rounded-lg
						border border-transparent
						hover:shadow-md hover:bg-gray-100 hover:text-gray-900 hover:border-gray-100
						active:bg-gray-100"
					>
						<i class="fa-solid fa-paper-plane" />
					</button>
					<button
						v-show="false"
						v-cloak
						title="Share this NFT"
						class="aspect-square w-8 flex justify-center items-center text-gray-600 leading-none text-sm border border-transparent rounded-lg hover:shadow-sm active:bg-gray-100"
					>
						<i class="fa fa-share-nodes" />
					</button>

					<router-link
						custom
						v-if="userOwned"
						v-slot="{ navigate }"
						:to="{
							name: 'list-asset',
							params: {
								slug: state.storefront.slug,
								assetId: state.asset.id,
							},
						}"
					>
						<div class="text-center ml-4">
							<button
								@click="navigate"
								:disabled="userListed >= userOwned"
								class="relative px-8 py-3 text-center text-base font-medium text-white rounded-md border border-transparent bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400 disabled:text-gray-100"
							>
								List for Sale
							</button>
						</div>
					</router-link>
				</div>
			</div>

			<ListingSuccess
				v-if="this.$route.query.notice === 'asset_list_success'"
			/>
			<ListingUpdateSuccess
				v-if="this.$route.query.notice === 'asset_list_update_success'"
			/>
			<ListingRemovalSuccess
				v-if="this.$route.query.notice === 'asset_list_removal_success'"
			/>
			<AssetPurchaseSuccess
				v-if="this.$route.query.notice === 'asset_purchase_success'"
			/>
			<InvalidUserError
				v-if="this.$route.query.error === 'invalid_user_error'"
			/>

			<div class="mx-auto pb-16 mt-6 sm:pb-24 max-w-7xl">
				<div class="sm:grid grid-cols-[2fr,3fr] space-y-4 sm:space-y-0">
					<div class="px-4">
						<h1 class="text-md font-medium text-gray-900">
							<router-link
								:to="{
									name: 'storefront',
									params: { slug: state.storefront.slug },
								}"
								class="text-md font-medium text-sky-600 hover:text-sky-500"
							>
								{{ state.storefront.name }}
							</router-link>
						</h1>
						<h1 class="text-xl font-medium text-gray-900 mt-2">
							#{{ state.asset.id }} - {{ state.asset.name }}
						</h1>
					</div>

					<div class="px-4 -order-1 row-span-2">
						<div class="">
							<img
								:src="state.asset.image"
								:alt="state.asset.name"
								class="rounded-xl"
							/>
						</div>
					</div>

					<div class="row-span-2 space-y-4 px-4">
						<div
							class="space-x-3 flex justify-center sm:justify-start my-4"
						>
							<button
								v-if="(state.asset.owners?.length ?? 0) > 0"
								type="button"
								class="inline-flex justify-center gap-x-1.5 rounded-md py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50"
							>
								<UsersIcon
									v-if="tokenCount != 1"
									class="-ml-0.5 h-5 w-5 text-gray-400"
								/>
								<UserIcon
									v-else
									class="-ml-0.5 h-5 w-5 text-gray-400"
								/>
								<span v-if="tokenCount != 1">
									{{ state.asset.owners.length }}
									<pluralize
										:amount="state.asset.owners.length"
										plural="Owners"
										singular="Owner"
									/>
								</span>
								<span
									v-if="singleOwner !== null"
									class="text-left text-sm text-gray-700"
								>
									<!-- this should be shown for non ERC155 tokens only -->
									Owned by {{ singleOwner['username'] }}
								</span>
							</button>
							<button
								v-if="tokenCount > 1"
								type="button"
								class="inline-flex justify-center gap-x-1.5 rounded-md py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50"
							>
								<TableCellsIcon
									class="-ml-0.5 h-5 w-5 text-gray-400"
								/>
								{{ tokenCount }} Tokens
							</button>
							<!-- <button
								type="button"
								class="inline-flex justify-center gap-x-1.5 rounded-md py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
								<EyeIcon
									class="-ml-0.5 h-5 w-5 text-gray-400"
								/>
								{{ $format(state.asset.history.total_views) }}
								Views
							</button>
							<button
								type="button"
								class="inline-flex justify-center gap-x-1.5 rounded-md py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
								<HeartIcon
									class="-ml-0.5 h-5 w-5 text-gray-400"
								/>
								{{ state.asset.history.favorites }} favorites
							</button> -->
						</div>

						<!-- MultiToken Buy/Sell Option -->
						<section v-if="tokenCount > 1" class="">
							<div class="grid grid-cols-1 gap-6">
								<div
									class="rounded-lg border border-gray-200 bg-gray-50 px-6"
								>
									<TabGroup as="div" :default-index=" userOwned > 0 && userListed < userOwned? 0 : 1 ">
										<div class="border-b border-gray-200 flex items-center justify-between">
											<TabList v-if="userOwned > 0 || state.asset.listings.length > 0"
												class="-mb-px flex space-x-8">
												<Tab
													as="template"
													v-if="userOwned > 0"
													v-slot="{ selected }">
													<button
														:class="[
															selected
																? 'border-sky-600 text-sky-600'
																: `border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800`,
															'whitespace-nowrap border-b-2 pb-2 pt-3 text-sm font-medium',
														]">
														Sell
													</button>
												</Tab>
												<Tab
													as="template"
													v-slot="{ selected }">
													<button
														:class="[
															selected
																? 'border-sky-600 text-sky-600'
																: 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
															'whitespace-nowrap border-b-2 pb-2 pt-3 text-sm font-medium',
															'text-gray-300',
														]"
													>
														Buy
													</button>
												</Tab>
											</TabList>
											<span v-if="userOwned > 0"
												class="pb-2 pt-3 text-sm font-medium">
												<UserIcon
													class="mr-1 h-5 w-5 inline-flex"
												/>
												You own {{ userOwned }}
											</span>
										</div>
										<TabPanels as="template">
											<TabPanel
												v-if="userOwned"
												class="text-sm text-gray-500"
											>
												<dl>
													<dd
														class="mt-2 mb-8 max-w-none gap-x-[8px] gap-y-16px flex items-center justify-between"
													>
														<router-link
															custom
															v-slot="{navigate,}"
															:to="{ name: 'list-asset', params: { slug: state.storefront.slug, assetId: state.asset.id, }}"
														>
															<button
																@click="navigate"
																:disabled="userListed >= userOwned"
																class="relative mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-sky-600 px-8 py-3 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400 disabled:text-gray-100"
															>
																List for sale
															</button>
														</router-link>
														<!-- TODO: ensure the quantity entered isn't greater than what they own -->
														<!-- <input
															class="mt-8 px-8 py-4 flex w-full justify-center sm:text-sm text-center items-center text-base font-medium rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500"
															v-model="
																state.list_amount
															"
															type="number"
															placeholder="Quantity"
														/> -->
														<!--
														<div class="float-left w-[20px] leading-[100px] ml-[60px] text-center cursor-pointer" v-on:click="minusQty(buy_data)">-</div>
														<div class="float-left w-[20px] leading-[100px] ml-[20px] text-center">{{buy_data.qty}}</div>
														<div class="float-left w-[20px] leading-[100px] ml-[20px] text-center cursor-pointer" v-on:click="plusQty(buy_data)">+</div>
														-->
													</dd>
												</dl>
											</TabPanel>
											<TabPanel v-if="bestListing"
												class="text-sm text-gray-500 pb-4">
												<dl>
													<dd class="mt-2 max-w-none gap-x-[8px]">
														<div class="mt-4">
															<h2 class="text-sm font-medium text-gray-500">
																Current Price:
															</h2>
															<div class="mt-2">
																<p class="text-2xl font-bold tracking-tight text-gray-900">
																	{{ $format(
																		humanReadablePrice(
																			bestListing.price * bestListing.quantity
																		)
																	) }}
																	{{ $token }}
																	<span v-if="bestListing.quantity > 1"
																		class="text-base font-normal text-gray-500 font-poppins"
																	>Quantity: {{ bestListing.quantity }}</span>
																</p>
																<p class="leading-normal">
																	or
																	<to-usd :bpx="bestListing.price * bestListing.quantity" />
																	with Credit Card
																</p>
															</div>
														</div>
													</dd>
													<dd class="mt-4">
														<button
															:disabled="false && (userID == null || bestListing.seller_blokpax_user_id == userID)"
															type="button"
															class="flex w-1/2 items-center justify-center
															text-base font-medium text-white
															px-8 py-3
															rounded-md border border-transparent bg-sky-600
															hover:bg-sky-700
															disabled:bg-gray-400 disabled:text-gray-100
															focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50"
															@click="handleBuyNow(bestListing)">
															Buy Now
														</button>

													</dd>
													<dd v-if="userID == null">
														<p class="mt-2">
															<a href="//app.blokpax.com/sign-up?next=" class="href-primary">Create an account</a>
															or <button @click="handleLoginClicked" class="href-primary">Log in</button> to purchase
														</p>
													</dd>
												</dl>
											</TabPanel>
										</TabPanels>
									</TabGroup>
								</div>
							</div>
						</section>

						<!-- Single Token Buy Option -->
						<div
							v-else-if="
								tokenCount === 1 && state.asset.listings > 0
							"
						>
							<div class="mt-4">
								<h2 class="text-sm font-medium text-gray-500">
									Current Price:
								</h2>
								<div class="inline-flex mt-2">
									<p
										class="text-2xl font-bold tracking-tight text-gray-900"
									>
										{{
											$format(
												new Currency(state.asset.price)
											)
										}}
										BPX
									</p>
									<p
										class="ml-4 text-xl italic font-medium leading-normal text-gray-900/40"
									>
										(or ${{
											$format(
												new Currency(
													state.asset.price
												).addPercentage(30)
											)
										}}
										USD credit card)
									</p>
								</div>
							</div>

							<!-- Buy Now Button -->
							<button
								type="button"
								class="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-sky-600 px-8 py-3 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50"
								@click="open = true"
							>
								<ShoppingBagIcon class="mr-2 h-5 w-5" />
								Buy Now
							</button>
						</div>

						<!-- Price History -->
						<storefront-asset-price-history class="hidden mt-10" />

						<!-- Listings -->
						<storefront-asset-listings
							class="mt-10"
							:storefront="state.storefront"
							:asset="state.asset"
							:listings="state.asset.listings"
							@buy="handleBuyNow"
						/>

						<!-- Active Offers -->
						<storefront-asset-offers class="mt-10 hidden" />
					</div>

					<div class="pt-4">
						<!-- Asset Details & Metadata -->
						<div class="px-4">
							<!-- Traits -->
							<detail-accordian
								name="Traits"
								:open="true"
								class="rounded-t-xl"
							>
								<ul
									v-for="attribute in state.asset.metadata"
									:key="attribute"
									role="list"
									class="hidden"
								>
									<li>
										{{ attribute.trait_type }}:
										{{ attribute.value }}
									</li>
								</ul>
								<div
									class="overflow-hidden space-y-2 md:space-y-0 md:grid md:grid-cols-2 md:gap-2 md:mx-0 md:rounded-2xl"
								>
									<div
										v-for="attribute in state.asset
											.metadata"
										:key="attribute"
										class="bg-gray-200/60 py-4 rounded-xl prose prose-sm text-center"
									>
										<p class="">{{ attribute.value }}</p>
										<p
											class="text-sm text-gray-500 font-medium"
										>
											{{ attribute.trait_type }}
										</p>
									</div>
								</div>
							</detail-accordian>

							<!-- Description -->
							<detail-accordian
								name="Description"
								:open="false"
								class="border-t-0 rounded-b-xl"
							>
								{{ state.storefront.details.description }}
							</detail-accordian>

							<!-- Details -->
							<detail-accordian
								name="Details"
								:open="true"
								class="hidden"
							>
								<div
									class="px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
								>
									<dt
										class="text-sm font-medium text-gray-900"
									>
										Contract Address:
									</dt>
									<dd
										class="flex text-sm text-gray-700 sm:col-span-2 sm:mt-0"
									>
										<span class="flex-grow">{{
											state.storefront.contract_address
										}}</span>
									</dd>
									<dt
										class="text-sm font-medium text-gray-900"
									>
										Token ID:
									</dt>
									<dd
										class="flex text-sm text-gray-700 sm:col-span-2 sm:mt-0"
									>
										<span class="flex-grow">{{
											state.asset.id
										}}</span>
									</dd>
									<dt
										class="text-sm font-medium text-gray-900"
									>
										Token Standard:
									</dt>
									<dd
										class="flex text-sm text-gray-700 sm:col-span-2 sm:mt-0"
									>
										<span class="flex-grow">{{
											state.storefront.storefront_type
										}}</span>
									</dd>
									<dt
										class="text-sm font-medium text-gray-900"
									>
										Chain:
									</dt>
									<dd
										class="flex text-sm text-gray-700 sm:col-span-2 sm:mt-0"
									>
										<span class="flex-grow">{{
											state.storefront.network_id
										}}</span>
									</dd>
									<dt
										class="text-sm font-medium text-gray-900"
									>
										Last Updated:
									</dt>
									<dd
										class="flex text-sm text-gray-700 sm:col-span-2 sm:mt-0"
									>
										<span class="flex-grow">{{
											mDate(
												state.asset
													.metadata_refreshed_at
											)
										}}</span>
									</dd>
								</div>
							</detail-accordian>
						</div>
					</div>
				</div>

				<!-- Item Activity -->
				<ActivityTable :asset="state.asset"/>

			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {
	defineComponent,
	reactive,
	ref,
	toRaw,
	isProxy,
	onMounted,
	watch,
	computed,
} from 'vue'
import LoadingStorefront from '@/components/LoadingStorefront.vue'
import StorefrontPurchaseModal from '@/components/modals/StorefrontPurchaseModal.vue'
import TransferAssetModal from '@/components/modals/TransferAssetModal.vue'
import BPXPurchaseModal from '@/components/modals/BPXPurchaseModal.vue'
import CCPurchaseModal from '@/components/modals/CCPurchaseModal.vue'
import StorefrontAssetOffers from '@/components/StorefrontAssetOffers.vue'
import StorefrontAssetListings from '@/components/StorefrontAssetListings.vue'
import StorefrontAssetPriceHistory from '@/components/StorefrontAssetPriceHistory.vue'
import DetailAccordian from '@/components/DetailAccordian.vue'
import ToUsd from '@/components/ToUsd.vue'
import FeedbackModal from "@/components/modals/FeedbackModal.vue";
import {
	TransitionRoot,
	TransitionChild,
	TabGroup,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
} from '@headlessui/vue'
import {
	ShoppingBagIcon,
	TagIcon,
	ShoppingCartIcon,

	ArrowsUpDownIcon,
	ChevronDoubleDownIcon,
	ChevronDoubleUpIcon,
	UsersIcon,
	EyeIcon,
	HeartIcon,
	TableCellsIcon,
	WalletIcon,
	UserIcon,
} from '@heroicons/vue/24/outline'
import sleep from '@/util/sleep'
import { useAsset } from '@/components/assets/asset'
import moment from 'moment'
import Currency from '@/types/Currency'
import ActivityTable from "@/components/ActivityTable.vue";

import { useAuthStore } from '@/stores/AuthStore'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { useAssetStore } from '@/stores/AssetStore'
import {
	ListingSuccess,
	ListingUpdateSuccess,
	ListingRemovalSuccess,
	AssetPurchaseSuccess,
} from '@/components/notices/storefront/notices'
import {
	InvalidUserError,
	ListingError,
	ListingRemovalError,
	ListingUpdateError,
} from '@/components/Errors/storefront/errors'
import { humanReadablePrice } from '@/util/currencyFormat'
import { useRoute, useRouter } from 'vue-router'
import StorefrontActivityAsset from "@/components/StorefrontActivityAsset.vue";

export default defineComponent({
	name: 'AssetDetail',
	props: {
		slug: {
			required: true,
			type: String,
		},
		assetId: {
			required: true,
			type: Number,
		},
		assetStorefrontId: {
			required: true,
			type: String,
		},
	},
	components: {
		TransferAssetModal,
		FeedbackModal,
		LoadingStorefront,
		ShoppingCartIcon,
		ActivityTable,
		StorefrontPurchaseModal,
		StorefrontActivityAsset,
		StorefrontAssetPriceHistory,
		StorefrontAssetOffers,
		StorefrontAssetListings,
		DetailAccordian,
		ShoppingBagIcon,
		ArrowsUpDownIcon,
		TagIcon,
		ChevronDoubleDownIcon,
		ChevronDoubleUpIcon,
		UsersIcon,
		EyeIcon,
		HeartIcon,
		TableCellsIcon,
		WalletIcon,
		UserIcon,
		TransitionRoot,
		TransitionChild,
		TabGroup,
		TabList,
		Tab,
		TabPanels,
		TabPanel,
		InvalidUserError,
		ListingError,
		ListingRemovalError,
		ListingUpdateError,
		ListingRemovalSuccess,
		ListingSuccess,
		ListingUpdateSuccess,
		AssetPurchaseSuccess,
		ToUsd,
		CCPurchaseModal,
		BPXPurchaseModal,
	},
	methods: {
		mDate(tDate) {
			return moment(tDate).fromNow()
		},
	},
	setup(props, context) {
		const open = ref(false)
		const authStore = useAuthStore()
		const storeStore = useStorefrontStore();
		const assetStore = useAssetStore();
		const route = useRoute();
		const router = useRouter();

		const state = reactive({
			loading: true,
			showPurchaseModal: false,
			transferAsset: false,
			asset: null,
			storefront: null,
			showBpxPurchaseModal: false,
			bestListing: null,
			selectedListing: null,
			feedback: null as null | { success: boolean, message: string },
			// list_amount: 1,
		})

		const userID = computed(() => {
			return authStore.user?.id ?? null;
		});

		watch(
			() => state.asset,
			(cur, old) => {
				if (!cur) {
					console.error(
						`Asset with ID: ${props.assetId} not found. Return to storefront page.`
					)
					return
				}
			}
		)


		

		async function loadAsset() {
			
			state.loading = true
			const wait = sleep(.75);
			await useAsset(props.slug || route.params.slug, props.assetId || route.params.assetId, authStore.user).then(
				(response) => {
					response = isProxy(response) ? toRaw(response) : response
					/* @ts-ignore */
					state.asset = response.asset.data.asset
					/* @ts-ignore */
					state.storefront = response.asset.data.storefront
				}
			)
			
			wait.then(() => (state.loading = false))
		}

		

		onMounted(async () => {
			loadAsset();

			if (route.query.success !== undefined) {
				state.feedback = {
					success: true,
					message: String(route.query.success),
				};
			} else if (route.query.error !== undefined) {
				state.feedback = {
					success: false,
					message: String(route.query.error),
				};
			}
			
			 
		})

		function clearFeedback() {
			router.replace({
				path: route.fullPath
			});

			state.feedback = null;
		}

		const tabs = [
			{ name: 'Buy', href: '#', current: false, disabled: false },
			{ name: 'Sell', href: '#', current: false, disabled: false },
		]

		const tokenCount = computed(() => {
			if (!state.asset) {
				return 0
			}

			return state.asset.owners.reduce(
				(total, owner) => (total += owner.balance),
				0
			)
		})

		const userOwned = computed(() => {
			if (!authStore.authenticated) {
				return 0
			}

			if (tokenCount.value == 0) {
				return 0
			}

			return (
				state.asset.owners.find((owner) => {
					return owner.account_id == authStore.accountID
				})?.balance ?? 0
			)
		})

		const userListed = computed(() => {
			if ( ! authStore.authenticated) {
				return 0;
			}

			if (tokenCount.value == 0) {
				return 0;
			}

			return state.asset.listings.reduce((total, listing) => {
					if (listing.seller_blokpax_user_id == authStore.user.id) {
						return total + listing.quantity;
					}

					return total;
				}, 0)
		});

		const singleOwner = computed(() => {
			if (tokenCount.value !== 1) {
				return null
			}

			return state.asset.owners[0]
		})

		const bestListing = (state.bestListing = computed(() => {
			if (state.asset.listings.length == 0) {
				return false
			}

			return state.asset.listings.sort((l, r) => l.price - r.price)[0]
		}))

		const handleBuyNow = (listing) => {
			state.selectedListing = listing
		}

		function handleLoginClicked() {
			authStore.redirectToLogin(route.path);
		}

		return {
			props,
			loadAsset,
			tabs,
			state,
			userID,
			open,
			storeStore,
			Currency,
			tokenCount,
			route,
			userOwned,
			userListed,
			ShoppingCartIcon,
			assetStore,
			singleOwner,
			bestListing,
			humanReadablePrice,
			handleBuyNow,
			handleLoginClicked,
			clearFeedback,
		}
	},
})
</script>