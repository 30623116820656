<template>
	<div class="max-w-7xl mx-auto px-6">
		<div class="mt-6">
			<h1 class="text-center md:text-left text-4xl font-bold">Live Drops</h1>

			<div class="mt-2 space-y-4">
				<div class="
				relative
				bg-[url(/image/baseball-buy-hero.gif)]
				bg-no-repeat bg-[right_-3rem_top] md:bg-center bg-cover
				h-96 mt-2 md:mt-4
				rounded-xl overflow-hidden
				shadow-md shadow-gray-600">
					<div class="absolute inset-0 bg-gradient-to-b from-transparent to-black/70"></div>
					<div class="absolute bottom-0 m-4 p-4 bg-black/50 rounded-xl md:bg-transparent">
						<h1 class="text-2xl text-white font-semibold">Home Team Heroes Baseball 2023</h1>
						<p class="mt-3">
							<a class="
							bg-white text-black font-semibold
							rounded-full
							px-8 py-3 shadow
							" href="https://collecthth.com">
								Buy and Rip Instantly!
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-16">
			<h1 class="text-center md:text-left text-4xl font-bold">Upcoming Drops</h1>
			<div class="mt-2 space-y space-y-4 md:space-y-0 md:mt-4 md:grid grid-cols-2 gap-6">
				<div class="
					relative
					bg-[url(/image/football-presale.gif)]
					bg-no-repeat md:bg-center bg-cover
					h-64
					rounded-xl overflow-hidden
					shadow-md shadow-gray-600">
						<div class="absolute inset-0 bg-gradient-to-b from-transparent to-black/70"></div>
						<div class="absolute bottom-0 m-4 p-4 bg-black/50 rounded-xl md:bg-transparent">
							<h1 class="text-2xl text-white font-semibold">Home Team Heroes Football 2023</h1>
							<p class="mt-3">
								<button class="
								bg-white text-black font-semibold
								rounded-full
								px-8 py-3 shadow
								disabled:bg-gray-200 disabled:text-gray-500
								" disabled>
									Order Presale Coming Soon!
								</button>
							</p>
						</div>
				</div>

				<div class="
					relative
					bg-[url(/image/basketball-presale.gif)]
					bg-no-repeat md:bg-center bg-cover
					h-64
					rounded-xl overflow-hidden
					shadow-md shadow-gray-600">
						<div class="absolute inset-0 bg-gradient-to-b from-transparent to-black/70"></div>
						<div class="absolute bottom-0 m-4 p-4 bg-black/50 rounded-xl md:bg-transparent">
							<h1 class="text-2xl text-white font-semibold">Home Team Heroes Basketball 2023</h1>
							<p class="mt-3">
								<button class="
								bg-white text-black font-semibold
								rounded-full
								px-8 py-3 shadow
								disabled:bg-gray-200 disabled:text-gray-500
								" disabled>
									Order Presale Coming Soon!
								</button>
							</p>
						</div>
				</div>
			</div>
		</div>

		<div class="mt-16">
			<h1 class="text-center md:text-left text-4xl font-bold">Marketplace: Trending Collections</h1>
			<div class="mt-2 space-y-4
				grid grid-cols-1 gap-6 items-stretch
				md:grid-cols-3 md:space-y-0 md:mx-0 md:mt-4">
				<router-link
					custom
					v-slot="{ navigate }"
					v-for="storefront in marketStore.storeFronts" :key="`storefront-${storefront.id}`"
					:to="{ name: 'storefront', params: { slug: storefront.slug }}">

					<div class="
						cursor-pointer
						flex flex-col
						rounded-xl overflow-hidden
						shadow-md shadow-gray-600"
						@click="navigate">
						<div class="flex-shrink-0 flex-grow-0 shadow shadow-gray-400 z-10">
							<img :src="storefront.featured_image" />
						</div>
						<div class="flex flex-grow p-4 flex-col bg-white">
							<h2 class="font-medium">{{ storefront.name }}</h2>
							<p class="flex-grow flex-shrink"></p>
							<p class="mt-2">Browse now</p>
							<p class="text-gray-500 hidden">Sales Re-Open on 8/16</p>
						</div>
					</div>
				</router-link>

				<div class="
				flex items-center justify-center
				border-2 border-gray-300 rounded-xl
				aspect-[475/350] md:aspect-auto">
					<div class="text-center text-2xl font-medium text-gray-400">
						<h2>Home Team Heroes<br>Football 2023</h2>
						<p class="text-xl mt-2">Coming 9/7</p>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-16 md:grid grid-cols-2 gap-16
			space-y-16 md:space-y-0">
			<div>
				<h1 class="text-center md:text-left text-4xl font-bold">Notable Sales</h1>
				<p class="mt-1 text-sm text-gray-500"><sup>*</sup>Sales during alpha release. <span class="hidden">Market re-opens on 8/16</span>.</p>
				<div class="grid grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12
					mt-8">

					<home-notable-sale
						img="/image/tokens/showtime-99-10x.jpg"
						label="Showtime #99 - 10x Color Pop"
						price="7315.39"
						url="/store/hth-baseball-2023-color-pops/12468"
					/>

					<home-notable-sale
						img="/image/tokens/chosen-99-10x.jpg"
						label="Chosen #99 - 10x Color Pop"
						price="5574.72"
						url="/store/hth-baseball-2023-color-pops/12454"
					/>

					<home-notable-sale
						img="/image/tokens/bomber-99-10x.jpg"
						label="Bomber #99 - 10x Color Pop"
						price="5400.51"
						url="/store/hth-baseball-2023-color-pops/12452"
					/>

					<home-notable-sale
						img="/image/tokens/goldminer-99-10x.jpg"
						label="Goldminer #99 - 10x Color Pop"
						price="3832.62"
						url="/store/hth-baseball-2023-color-pops/12460"
					/>

					<home-notable-sale
						img="/image/tokens/showtime-16-4x.png"
						label="Showtime #16 - 4x Color Pop"
						price="3484.20"
						url="/store/hth-baseball-2023-color-pops/11779"
					/>

					<home-notable-sale
						img="/image/tokens/showtime-26-3x.png"
						label="Showtime #26 - 3x Color Pop"
						price="2612.64"
						url="/store/hth-baseball-2023-color-pops/11789"
					/>

					<home-notable-sale
						img="/image/tokens/botto-99-10x.jpg"
						label="Botto #99 - 10x Color Pop"
						price="2264.73"
						url="/store/hth-baseball-2023-color-pops/12453"
					/>

					<home-notable-sale
						img="/image/tokens/matata-17-3x.png"
						label="Matata #17 - 3x Color Pop"
						price="2264.73"
						url="/store/hth-baseball-2023-color-pops/11388"
					/>

					<home-notable-sale
						img="/image/tokens/botto-22-4x.png"
						label="Botto #22 - 4x Color Pop"
						price="1288.91"
						url="/store/hth-baseball-2023-color-pops/10315"
					/>
				</div>
			</div>
			<div>
				<h1 class="text-center md:text-left text-4xl font-bold">Resources</h1>
				<div class="
					mt-6
					grid grid-cols-[1fr,4fr] gap-x-4 gap-y-12 items-center
					font-bold text-lg">
					<div>
						<a href="https://blokpax.gitbook.io/hth-quickstart/"
							target="_blank"
							class="cursor-pointer">
							<img src="/image/collectors_guide.png"
								class="rounded-full shadow-md shadow-gray-400">
						</a>
					</div>
					<div>
						<a href="https://blokpax.gitbook.io/hth-quickstart/"
							target="_blank"
							class="cursor-pointer">
							<p class="text-gray-400 text-sm font-normal">Home Team Heroes</p>
							<p>HTH Collector's Guide</p>
						</a>
					</div>

					<div>
						<a href="https://hero-data.collecthth.com/"
							target="_blank"
							class="cursor-pointer">
							<img src="/image/cp_point_updates.png"
								class="rounded-full shadow-md shadow-gray-400">
						</a>
					</div>
					<div>
						<a href="https://hero-data.collecthth.com/"
							target="_blank"
							class="cursor-pointer">
							<p class="text-gray-400 text-sm font-normal">Home Team Heroes</p>
							<p>Color Pop Points Updates</p>
						</a>
					</div>

					<div>
						<a href="https://blokpax.gitbook.io/hth-quickstart/points-system"
							target="_blank"
							class="cursor-pointer">
							<img src="/image/scoring_explained.png"
								class="rounded-full shadow-md shadow-gray-400">
						</a>
					</div>
					<div>
						<a href="https://blokpax.gitbook.io/hth-quickstart/points-system"
							target="_blank"
							class="cursor-pointer">
							<p class="text-gray-400 text-sm font-normal">Home Team Heroes</p>
							<p>HTH Scoring Explained</p>
						</a>
					</div>

					<div>
						<a href="https://set-builder.collecthth.com/0x000000000000000000000000000000000000000/leaderboard"
							target="_blank"
							class="cursor-pointer">
							<img src="/image/collector_leaderboard.png"
								class="rounded-full shadow-md shadow-gray-400">
						</a>
					</div>
					<div>
						<a href="https://set-builder.collecthth.com/0x000000000000000000000000000000000000000/leaderboard"
							target="_blank"
							class="cursor-pointer">
							<p class="text-gray-400 text-sm font-normal">Home Team Heroes</p>
							<p>HTH Collector Leaderboards</p>
						</a>
					</div>

					<div>
						<a href="https://blokpax.gitbook.io/help/experiences/auctions"
							target="_blank"
							class="cursor-pointer">
							<img src="/image/bpx_auctions.png"
								class="rounded-full shadow-md shadow-gray-400">
						</a>
					</div>
					<div>
						<a href="https://blokpax.gitbook.io/help/experiences/auctions"
							target="_blank"
							class="cursor-pointer">
							<p class="text-gray-400 text-sm font-normal">BPX Auctions</p>
							<p>Blokpax Reward Auctions: Getting Started</p>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue';
import { useAuctionStore } from '@/stores/AuctionStore';
import { useMarketStore } from '@/stores/MarketStore';
import HomeNotableSale from '@/components/HomeNotableSale.vue';

export default defineComponent({
	components: { HomeNotableSale },
	setup(props) {
		const marketStore = useMarketStore();

		onMounted(() => {
			marketStore.load()
		})

		return {
			marketStore
		}
	}
})
</script>
