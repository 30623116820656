import { defineStore } from 'pinia'
import api from '@/util/api'
import { Storefront } from '@/types/Storefront'
import { ExchangeRate } from '@/types/Currency'
import { useAuthStore } from './AuthStore'

export const useMarketStore = defineStore('market', {
	state: () => ({
		storeFronts: [] as Storefront[],
	}),

	actions: {
		async load(forceReload: boolean = false) : Promise<Storefront[]> {
			if (forceReload || 0 == this.storeFronts.length) {
				this.storeFronts = await api.loadStorefronts()
			}

			return this.storeFronts;
		},

		async buyWithBpx(listingID: string) : Promise<{ success: boolean, message: string, code?: number }> {
			const authStore = useAuthStore();

			return await api.buyListing(listingID, authStore.token.data.accessToken);
		},

		async otcTransfer(assets: { asset_id: string; quantity: number }[], recipient: string, pin: string | null) : Promise<{ success: boolean; message: string; code?: number | string | null }> {
			const authStore = useAuthStore();
			return await api.transferAssets(assets, recipient, pin, authStore.token.data.accessToken);
		}

	},

	getters: {
		byID() {
			if (this.storeFronts.length == 0) {
				return {};
			}

			return this.storeFronts.reduce((all, storefront) => {
				all[ storefront.id ] = storefront;
				return all;
			}, {});
		},
	}
})
