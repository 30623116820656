<template>
	<modal class="z-50">
		<template #default="handlers">
			<div class="bg-white rounded-xl pt-4 overflow-hidden drop-shadow-md w-full">
				<h1 class="text-2xl font-semibold text-center">Order Summary</h1>
				<div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
					<button
						@click.stop="$emit('close')"
						type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
						<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
							<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>
				</div>
				<div>

					<div class="mt-4 border-t border-gray-200 bg-white shadow-sm">
						<dl class="space-y-6 px-4 pt-6 sm:px-6">
							<div class="flex items-center justify-between">
								<dt class="text-base font-medium">1 Item</dt>
							</div>
						</dl>
						<ul role="list" class="divide-y divide-gray-200">
							<li class="flex px-4 py-6 sm:px-6">
								<div class="flex-shrink-0">
									<img :src="state.asset.image" :alt="state.asset.name" class="w-32 rounded-md" />
								</div>
								<div class="ml-6 flex flex-1 flex-col">
									<div class="flex">
										<div class="min-w-0 flex-1">
											<h4 class="text-base">
												<a :href="state.asset.token_id" class="font-medium text-gray-700 hover:text-gray-800">#{{state.asset.id }} - {{ state.asset.name }}</a>
											</h4>
											<p class="mt-1 text-base text-gray-500">{{ state.storefront.name }}</p>
										</div>
									</div>
								</div>
							</li>
						</ul>
						<div class="space-y-6 px-4 pt-6 sm:px-6">
								<div class="grid grid-cols-12 gap-x-4 gap-y-6">
									<div class="col-span-full">
										<label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
										<div class="mt-1">
											<input
												v-model="ccState.email"
												type="email" id="email-address" name="email-address" autocomplete="email" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
										</div>
									</div>

									<div class="col-span-full">
										<label for="name-on-card" class="block text-sm font-medium text-gray-700">Name on card</label>
										<div class="mt-1">
											<input
												v-model="ccState.name_on_card"
												type="text" id="name-on-card" name="name-on-card" autocomplete="cc-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
										</div>
									</div>

									<div class="col-span-full">
										<label for="card-number" class="block text-sm font-medium text-gray-700">Card number</label>
										<div class="mt-1">
											<input
												v-model="ccState.card_number"
												type="text" id="card-number" name="card-number" autocomplete="cc-number" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
										</div>
									</div>

									<div class="col-span-8 sm:col-span-9">
										<label for="expiration-date" class="block text-sm font-medium text-gray-700">Expiration date (MM/YY)</label>
										<div class="mt-1">
											<input
												v-model="ccState.expiration_date"
												type="text" name="expiration-date" id="expiration-date" autocomplete="cc-exp" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
										</div>
									</div>

									<div class="col-span-4 sm:col-span-3">
										<label for="cvc" class="block text-sm font-medium text-gray-700">CVC</label>
										<div class="mt-1">
											<input
												v-model="ccState.cvc"
												type="text" name="cvc" id="cvc" autocomplete="csc" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
										</div>
									</div>

									<div class="col-span-full">
										<label for="address" class="block text-sm font-medium text-gray-700">Address</label>
										<div class="mt-1">
											<input
												v-model="ccState.address"
												type="text" id="address" name="address" autocomplete="street-address" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
										</div>
									</div>

									<div class="col-span-full sm:col-span-4">
										<label for="city" class="block text-sm font-medium text-gray-700">City</label>
										<div class="mt-1">
											<input
												v-model="ccState.city"
												type="text" id="city" name="city" autocomplete="address-level2" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
										</div>
									</div>

									<div class="col-span-full sm:col-span-4">
										<label for="region" class="block text-sm font-medium text-gray-700">State / Province</label>
										<div class="mt-1">
											<input
												v-model="ccState.region"
												type="text" id="region" name="region" autocomplete="address-level1" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
										</div>
									</div>

									<div class="col-span-full sm:col-span-4">
										<label for="postal-code" class="block text-sm font-medium text-gray-700">Postal code</label>
										<div class="mt-1">
											<input
												v-model="ccState.postal_code"
												type="text" id="postal-code" name="postal-code" autocomplete="postal-code" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
										</div>
									</div>
								</div>
						</div>

						<div class="border-t border-gray-200 px-4 py-6 sm:px-6 mt-6">
							<button
								@click="processCCPurchase(state, ccState)"
								type="button"
								class="w-full rounded-md border border-transparent bg-sky-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50">
								Confirm Payment of <to-usd :bpx='state.bestListing.price' />
							</button>
						</div>
					</div>
				</div>
			</div>
		</template>
	</modal>

</template>
<script lang="ts">
import { defineComponent, onMounted, PropType, reactive } from 'vue';
import Currency from '@/types/Currency';
import { useAssetStore } from '@/stores/AssetStore';
import { useAuthStore } from '@/stores/AuthStore';
import ToUsd from '@/components/ToUsd.vue';
// import * as forge from 'node-forge';

export default defineComponent({
	components: {
		ToUsd,
	},
	props: {
		state: {
			required: true,
			type: Object,
		},
	},
	setup(props) {
		const ccState = reactive({
			email: 'calvin@bpx.com',
			name_on_card: 'calvin hill',
			card_number: '4000000000000000',
			expiration_date: '10/2026',
			cvc: '111',
			address: '12 Main Street',
			city: 'Greenville',
			region: 'SC',
			postal_code: '29607'
		});

		return {
			props,
			Currency,
			ccState,
		}
	},
	methods: {
		processCCPurchase: async (state, ccState) => {
			const pub = forge.pki.publicKeyFromPem(import.meta.env.VITE_CC_KEY);
			const ccEncrypted = forge.util.encode64(pub.encrypt(ccState));

			/* @ts-ignore */
			await useAssetStore().purchaseListing(
				2,
				state.bestListing.id,
				useAuthStore()?.user?.id,
				1, //always 1 until we implement shopping cart
				ccEncrypted,
			).then((res) => {
				if (200 != res.statusCode) {
					state.purchaseError = true;
					state.purchaseErrorMessage = res.message;
					return false;
				} else {
					state.purchaseError = false;
					state.purchaseError = null;

					//show asset purchase successfully message on our asset page
					window.location.href=`/store/${state.storefront.slug}/${state.asset.id}?notice=asset_purchase_success`;
				}
			}).catch((err) => {
				state.listingError = true;
				state.listingErrorMessage = err.message;
				window.scrollTo({ top: 0, behavior: 'smooth' });
				return false;
			});
		},
	},
})
</script>
