<template>
	<router-link custom
		v-slot="{ navigate }"
		:to="props.url">
		<div @click="navigate" class="cursor-pointer">
			<div class="relative">
				<img :src="props.img" class="rounded-xl overflow-hidden shadow-md shadow-gray-600">
				<div class="absolute -top-4 -left-4 w-1/2">
					<img src="/image/sold.png" class="-rotate-12">
				</div>
			</div>
			<div class="mt-3">
				<p class="whitespace-nowrap text-ellipsis overflow-hidden
					font-bold text-lg"
					:title="props.label">
					{{ props.label }}
				</p>
				<p class="text-gray-500">
					Sold for ${{ $format(props.price) }}
				</p>
			</div>
		</div>
	</router-link>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		img: {
			required: true,
			type: String,
		},
		label: {
			required: true,
			type: String,
		},
		price: {
			required: true,
			type: [ String, Number ],
		},
		url: {
			required: true,
			type: String
		},
	},
	setup(props) {

		return {
			props,
		}
	}
})
</script>
