<style>
.bg-animate {
	-webkit-animation: bgFade 3s ease infinite;
	-moz-animation: bgFade 3s ease infinite;
	animation: bgFade 3s ease infinite;
	background-size: 300%;
}

@keyframes bgFade {
	0%, 100% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
}
</style>
<template>
	<div class="bg-white shadow rounded-xl overflow-hidden cursor-pointer w-full">
		<div class="rounded-xl overflow-hidden">
			<loading-placeholder class="w-full h-52" />
		</div>
		<div class="py-2 px-3">
			<loading-placeholder class="w-3/4 rounded-lg">
				&nbsp;
			</loading-placeholder>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import LoadingPlaceholder from "./LoadingPlaceholder.vue";

export default defineComponent({
	components: { LoadingPlaceholder },
	setup(props) {

		return {

		}
	}
})
</script>
