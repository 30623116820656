<template>

</template>
<script lang="ts">
import { useAuthStore } from "@/stores/AuthStore";
import axios from "axios";
import { defineComponent, onMounted, PropType } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
	setup(props) {
		const route = useRoute();
		const router = useRouter();
		const auth = useAuthStore();

		onMounted(async() => {
			const redirect = await auth.authenticate(
				route.query.code,
				route.query.state
			)

			router.push({
				path: redirect,
				replace: true,
			});
		});

		return {

		}
	}
})
</script>
