<style src="@vueform/toggle/themes/default.css"></style>
<template>
	<LoadingStorefront v-if="state.loading" />
	<div v-else class="bg-gray-50">
		<feedback-modal @close="backToAssetDetail" v-if="state.created || state.deleted">
			<template #title>
				<span class="text-slate-900 font-poppins">
					<span class="inline-flex justify-center items-center text-green-600 bg-green-100 aspect-square px-3 rounded-full"><i class="fa-solid fa-check"></i></span>
					<span class="ml-3">
						Success!
					</span>
				</span>
			</template>
			<template #body>
				<div class="text-xl my-4">
					<p v-if="state.deleted">Your listing has been deleted.</p>
					<p v-else-if="state.editingListing">Your listing has been updated.</p>
					<p v-else>Your listing has been created</p>
					<p class="text-xl my-4">
						Click below to go back to the detail page.
					</p>
				</div>
			</template>
		</feedback-modal>
		<feedback-modal v-if="state.confirmDeleteListing"
			@close="state.confirmDeleteListing = false">
			<template #title>
				<span class="text-slate-900 font-poppins">
					<span class="inline-flex justify-center items-center text-orange-400 bg-orange-100 aspect-square py-2 px-6 rounded-full"><i class="fa-solid fa-exclamation"></i></span>
					<span class="ml-3">Are you sure?</span>
				</span>
			</template>
			<template #body>
				<p class="text-lg mt-4">Are you sure you want to delete this listing?</p>
			</template>
			<template #buttons="{ close }">
				<div class="flex justify-end items-center space-x-4">
					<button class="btn-lg bg-gray-300 text-slate-900 mt-2"
						@click.stop="close">
						Cancel
					</button>
					<button class="btn-lg mt-2
						disabled:bg-red-600/20 disabled:text-red-900
						bg-red-700 text-white
						hover:bg-red-600"
						:disabled="state.deleting"
						@click.stop="deleteListing">
						<span v-if="false == state.deleting">Confirm</span>
						<span v-else>Deleting <i class="fa fa-spinner fa-spin"></i></span>
					</button>
				</div>
			</template>
		</feedback-modal>
		<div class="mx-auto max-w-2xl px-4 pb-24 mt-4 sm:px-6 lg:max-w-5xl lg:px-8">
			<div class="flex items-center">
				<router-link
					custom
					v-slot="{ navigate }"
					:to="{ name: 'asset', params: { slug: state.storefront.slug, assetId: state.asset.id }}"
					class="decoration-0 text-sky-700">
					<button class="flex mr-[20px] items-center decoration-0 text-sky-700"
						@click="navigate">
						<ArrowLeftIcon class="h-[28px] w-[28px] cursor-pointer text-gray-400 font-normal inline-block whitespace-nowrap flex-wrap" />
					</button>
				</router-link>
				<h1 class="text-3xl font-medium text-gray-900">
					<span v-if=" ! state.editingListing">
						List for sale
					</span>
					<span v-else>
						Edit Listing
					</span>
				</h1>
			</div>

			<div class="md:grid grid-cols-[2fr,1fr] gap-12">
				<div class="md:order-1">
					<div class="inline-block">
						<div class="
							mt-4
							sm:mt-0 sm:max-w-[375px]
							shadow-lg shadow-gray-500 rounded-2xl overflow-hidden">
							<div class="flex-shrink-0">
								<img :src="state.asset.image" :alt="state.asset.name" />
							</div>

							<div class="bg-white px-4 py-6">
								<div>
									<div class="font-medium">#{{ state.asset.id }} - {{ state.asset.name }}</div>
									<div class="text-sm text-slate-500">
										<router-link
											:to="{ name: 'storefront', params: {slug : state.storefront.slug }}">
											{{ state.storefront.name }}
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div>
					<ListingError v-if="state.listingError" v-model:visible="state.listingError" />
						<!-- Type of Sale -->
					<div class="mt-6 hidden">
						<RadioGroup v-model="state.saleType">
							<RadioGroupLabel class="text-lg font-medium text-gray-900">Type of sale</RadioGroupLabel>
							<div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
								<RadioGroupOption
									as="template"
									v-for="saleType in saleTypes"
									:key="saleType.id"
									:value="saleType"
									:disabled="saleType.disabled"
									v-slot="{ checked, active }">
									<div
										:class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'ring-2 ring-sky-500' : '', 'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none']">
										<span class="flex flex-1">
											<span class="flex flex-col">
												<RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">{{ saleType.title }}</RadioGroupLabel>
												<RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">{{ saleType.description }}</RadioGroupDescription>
											</span>
										</span>
										<CheckCircleIcon v-if="checked" class="h-5 w-5 text-sky-600" aria-hidden="true" />
										<span :class="[active ? 'border' : 'border-2', checked ? 'border-sky-500' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
									</div>
								</RadioGroupOption>
							</div>
						</RadioGroup>
						<span class="flex mt-4 text-red-500 text-sm italic font-medium"
							v-if="!!state.errors.saleType">{{ state.errors.saleType }}
						</span>
					</div>

					<!-- Quantity -->
					<div class="mt-2 pt-4 border-t border-gray-200">
						<h2 class="text-lg font-medium text-gray-900
							flex justify-between items-baseline">
							<label for="quantity"># of items</label>
							<span class="text-sm font-normal text-gray-400">
								{{ $format(ownerAvailable) }} available
							</span>
						</h2>
						<div class="grid grid-cols-4 gap-x-4 gap-y-6">
							<div class="col-span-4">
								<label for="quantity" class="block text-sm font-medium text-gray-700">
								</label>
								<div class="mt-1">
									<input
										v-model="state.quantity"
										@change="checkListingValid"
										@keyup="checkListingValid"
										type="number"
										:min="1"
										:max="ownerAvailable"
										id="quantity"
										name="quantity"
										class="px-4 py-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
								</div>
								<span class="flex mt-4 text-red-500 text-sm italic font-medium" v-if="!!state.errors.quantity">{{ state.errors.quantity }}</span>
							</div>
						</div>
					</div>

					<!-- Price -->
					<div class="mt-4 pt-10 border-t border-gray-200">
						<h2 class="text-lg font-medium text-gray-900">
							<label for="price">Set a price (BPX)</label>
						</h2>
						<div class="mt-1 grid grid-cols-4 gap-x-4 gap-y-6">
							<div class="col-span-4">
								<div class="mt-1
								flex justify-between space-x-4 items-center
								bg-white
								border text-sm
								px-4 py-3 leading-none
								focus-within:border-sky-500 focus-within:ring-sky-500 focus-within:ring-1
								rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm">
									<input
										v-model="state.price"
										@keyup="checkListingValid"
										@change="checkListingValid"
										type="tel"
										max="5000000000"
										maxlength="10"
										pattern="^([0-9]{0,10})$"
										id="price"
										name="price"
										class="flex-grow p-0 m-0 w-full block border-0 outline-0 focus:outline-0 ring-0 focus:ring-0"
									/>
									<to-usd class="flex-shrink-0 text-slate-500 leading-none"
										:bpx="state.price"
										:is-decimal="true"
									/>
								</div>
								<span class="flex mt-4 text-red-500 text-sm italic font-medium" v-if="!!state.errors.price">{{ state.errors.price }}</span>
							</div>
						</div>
					</div>

					<!-- Duration -->
					<div class="mt-4 pt-10 border-t border-gray-200">
						<h2 class="text-lg font-medium text-gray-900">
							<label for="duration">Duration</label>
						</h2>
						<div v-if="state.editingListing"
							class="flex justify-between items-center mb-2">
							<p>
								<button @click="state.modifyExpiration = ! state.modifyExpiration">
									Modify Listing Duration
								</button>
							</p>
							<p>
								<toggle v-model="state.modifyExpiration" off-label="No" on-label="Yes"
								:classes="{
									container: 'inline-block rounded-full outline-none focus:ring focus:ring-sky-700 focus:ring-opacity-30',
									toggle: 'flex w-16 h-8 px-2 rounded-full relative cursor-pointer transition items-center box-content border-2 text-base leading-none',
									toggleOn: 'bg-sky-600 border-sky-600 justify-start text-white',
									toggleOff: 'bg-gray-200 border-gray-200 justify-end text-gray-700',
									toggleOnDisabled: 'bg-gray-300 border-gray-300 justify-start text-gray-400 cursor-not-allowed',
									toggleOffDisabled: 'bg-gray-200 border-gray-200 justify-end text-gray-400 cursor-not-allowed',
									handle: 'inline-block bg-white w-8 h-8 top-0 rounded-full absolute transition-all',
									handleOn: 'left-full transform -translate-x-full',
									handleOff: 'left-0',
									handleOnDisabled: 'bg-gray-100 left-full transform -translate-x-full',
									handleOffDisabled: 'bg-gray-100 left-0',
									label: 'text-center w-8 border-box whitespace-nowrap select-none',
									}" />
							</p>
						</div>
						<div  v-if=" ! state.editingListing || state.modifyExpiration"
							class="mt-1">
								<div class="mt-1 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
									<Listbox as="div" v-model="state.duration">
										<div class="relative">
											<ListboxButton class="relative w-full cursor-default
												px-4 py-3 pl-3 pr-10 text-left text-gray-900
												rounded-md bg-white shadow-sm
												ring-1 ring-inset ring-gray-300
												focus:outline-none focus:ring-2 focus:ring-sky-600
												sm:text-sm sm:leading-6">
												<span class="block min-w-[150px]">
													{{ state.duration.value }}
												</span>
												<span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
													<ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
												</span>
											</ListboxButton>
											<transition leave-active-class="transition ease-in duration-100"
														leave-from-class="opacity-100"
														leave-to-class="opacity-0">
												<ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white px-4 py-3 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
													<ListboxOption as="template" v-for="duration in durations" :key="duration.value"
														:value="duration" v-slot="{ active, selected }">
														<li :class="[
																active
																? 'bg-sky-600 text-white'
																: 'text-gray-900',
																'relative cursor-default select-none py-2 pl-3 pr-9'
															]">
															<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
																{{ duration.value }}
															</span>
															<span v-if="selected" :class="[active ? 'text-white' : 'text-sky-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
																<CheckIcon class="h-5 w-5" aria-hidden="true" />
															</span>
														</li>
													</ListboxOption>
												</ListboxOptions>
											</transition>
										</div>
									</Listbox>
									<div class="relative">
										<input
											class="px-4 py-3
											sm:text-sm
											block
											min-w-full
											rounded-md border-gray-300
											shadow-sm
											focus:border-sky-500 focus:ring-sky-500
											disabled:bg-gray-200 disabled:text-gray-700"
											type="text"
											id="expiration"
											disabled
											name="expiration"
											:value="state.duration.date"
										/>
									</div>
								</div>
						</div>
						<span class="flex mt-4 text-red-500 text-sm italic font-medium" v-if="!!state.errors.duration">{{ state.errors.duration }}</span>
					</div>

					<div class="mt-10 border-t border-gray-200 pt-10 hidden">
						<h2 class="text-lg font-medium text-gray-900">Reserve for a specific buyer</h2>
						<div class="mt-6 grid grid-cols-4 gap-x-4 gap-y-6">
							<div class="col-span-4">
								<label for="price" class="block text-sm font-medium text-gray-700">Address</label>
								<div class="mt-1">
									<input
										v-model="state.reservedFor"
										type="text"
										id="address"
										name="address"
										class="px-4 py-3 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm" />
								</div>
							</div>
						</div>
					</div>

					<div class="mt-4 pt-10 border-t border-gray-200">
						<h2 class="text-lg font-medium text-gray-900">Summary</h2>
						<div class="mt-2 grid grid-cols-4 gap-x-4 gap-y-6">
							<div class="col-span-4">
								<dl class="space-y-3">
									<div class="flex items-center justify-between">
										<dt class="">Quantity</dt>
										<dd class="font-medium text-gray-900">{{ $format(state.quantity) }} <pluralize :amount="state.quantity" singular="token" plural="tokens" /> </dd>
									</div>
									<div class="flex items-center justify-between">
										<dt class="">
											Listing Price
											<span v-if="state.quantity > 1" class="text-gray-500 text-sm">
												(each)
											</span>
										</dt>
										<dd class="font-medium text-gray-900">
											<span v-if="state.price && String(state.price).trim().length > 0">
												{{ $format(String(state.price).trim()) }} BPX
											</span>
											<span v-else class="italic font-normal text-gray-500">
												--
											</span>
										</dd>
									</div>
									<div class="flex items-center justify-between">
										<dt class="">
											Total listing price
										</dt>
										<dd class="font-medium text-gray-900">
											<span v-if="state.price && String(state.price).trim().length > 0">
												{{ $format(String(state.price * state.quantity).trim()) }} BPX
											</span>
											<span v-else class="italic font-normal text-gray-500">
												--
											</span>
										</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>

					<div class="text-right mt-6">
						<button
							v-if="state.editingListing"
							@click="state.confirmDeleteListing = true"
							class="px-4 py-3 text-red-700 rounded-md mx-4
							hover:bg-red-600 hover:text-white"
						><i class="fa fa-trash"></i> &nbsp;Delete</button>

						<button
							@click="processListing"
							:disabled="! state.listingValid || state.processing"
							type="submit"
							class="flex-grow-0 btn-primary-lg border border-transparent
							px-4 py-3 text-base focus:outline-none
							disabled:bg-gray-300 disabled:text-gray-500">
							<span v-if="state.processing">Saving Listing <i class="fa fa-spinner fa-spin"></i></span>
							<span v-else-if="! state.editingListing">Complete Listing</span>
							<span v-else>Update Listing</span>
						</button>
					</div>
					<div class="text-right mt-4">
						<router-link
							custom
							v-slot="{ navigate }"
							:to="{ name: 'asset', params: { slug: state.storefront.slug, assetId: state.asset.id }}">
							<button
								@click="navigate"
								class="col-start-2 flex-grow-0 h-full px-4 py-3
								text-base font-medium text-gray-500">
								Cancel
							</button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, isProxy, onMounted, reactive, ref, toRaw, watch } from 'vue'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { ChevronUpDownIcon, CheckCircleIcon, ArrowLeftIcon, CheckIcon } from '@heroicons/vue/20/solid';
import LoadingStorefront from '@/components/LoadingStorefront.vue';
import { useStorefrontStore } from '@/stores/StorefrontStore';
import { useAuthStore } from '@/stores/AuthStore';
import sleep from '@/util/sleep'
import { useAsset } from '@/components/assets/asset'
import { DateTime, Duration } from 'ts-luxon';
import { useAssetStore } from '@/stores/AssetStore'
import { useExchangeStore } from '@/stores/ExchangeStore';
import ListingError from '@/components/Errors/storefront/ListingError.vue';
import FeedbackModal from "@/components/modals/FeedbackModal.vue";
import ToUsd from '@/components/ToUsd.vue';
import Toggle from '@vueform/toggle'
import { useAccountStore } from '@/stores/AccountStore';
import { ListingType, Listing, NewListing } from '@/types/Asset';
import Currency from '@/types/Currency';
import { useRoute, useRouter } from 'vue-router';

const saleTypes : { id: ListingType, title: string, description: string, disabled: boolean }[] = [
	{ id: 'fixed', title: 'Fixed price', description: 'The item is listed at the price you set.', disabled: false },
	//{ id: 2, title: 'Timed auction', description: 'The item is listed for auction.', disabled: true },
];

const durations = [
	{
		name: '1h', value: '1 hour',
		date: DateTime.now().plus(Duration.fromObject({ hours: 1 })).toLocaleString(DateTime.DATETIME_MED),
		formattedDate: DateTime.now().setZone('UTC').plus(Duration.fromObject({ hours: 1 })).toSQL({ includeOffset: true })
	},
	{
		name: '6h', value: '6 hours',
		date: DateTime.now().plus(Duration.fromObject({ hours: 6 })).toLocaleString(DateTime.DATETIME_MED),
		formattedDate: DateTime.now().setZone('UTC').plus(Duration.fromObject({ hours: 6 })).toSQL({ includeOffset: true })
	},
	{
		name: '1d', value: '1 day',
		date: DateTime.now().plus(Duration.fromObject({days: 1 })).toLocaleString(DateTime.DATETIME_MED),
		formattedDate: DateTime.now().setZone('UTC').plus(Duration.fromObject({ days: 1 })).toSQL({ includeOffset: true })
	},
	{
		name: '3d', value: '3 days',
		date: DateTime.now().plus(Duration.fromObject({ days: 3 })).toLocaleString(DateTime.DATETIME_MED),
		formattedDate: DateTime.now().setZone('UTC').plus(Duration.fromObject({ days: 3 })).toSQL({ includeOffset: true })
	},
	{
		name: '7d', value: '7 days',
		date: DateTime.now().plus(Duration.fromObject({ days: 7 })).toLocaleString(DateTime.DATETIME_MED),
		formattedDate: DateTime.now().setZone('UTC').plus(Duration.fromObject({ days: 7 })).toSQL({ includeOffset: true })
	},
	{
		name: '1m', value: '1 month',
		date: DateTime.now().plus(Duration.fromObject({ months: 1 })).toLocaleString(DateTime.DATETIME_MED),
		formattedDate: DateTime.now().setZone('UTC').plus(Duration.fromObject({ months: 1 })).toSQL({ includeOffset: true })
	},
	{
		name: '3m', value: '3 months',
		date: DateTime.now().plus(Duration.fromObject({ months: 3 })).toLocaleString(DateTime.DATETIME_MED),
		formattedDate: DateTime.now().setZone('UTC').plus(Duration.fromObject({ months: 3 })).toSQL({ includeOffset: true })
	},
	{
		name: '6m', value: '6 months',
		date: DateTime.now().plus(Duration.fromObject({ months: 6 })).toLocaleString(DateTime.DATETIME_MED),
		formattedDate: DateTime.now().setZone('UTC').plus(Duration.fromObject({ months: 6 })).toSQL({ includeOffset: false })
	},
];

export default defineComponent({
	props: {
		slug: {
			required: true,
			type: String,
		},
		assetId: {
			required: true,
			type: Number,
		},
		listingId: {
			required: false,
			type: Number,
		}
	},
	components: {
		ToUsd,
		Toggle,
		ListingError,
		RadioGroup,
		RadioGroupDescription,
		RadioGroupLabel,
		RadioGroupOption,
		CheckCircleIcon,
		ArrowLeftIcon,
		LoadingStorefront,
		Listbox,
		ListboxButton,
		ListboxLabel,
		ListboxOption,
		ListboxOptions,
		ChevronUpDownIcon,
		CheckIcon,
		FeedbackModal
	},
	setup(props, context) {
		const open = ref(false);
		const authStore = useAuthStore();
		const assetStore = useAssetStore();
		const exchangeStore = useExchangeStore();
		const accountStore = useAccountStore();
		const router = useRouter();
		const route = useRoute();


		const ownerAvailable = computed(() => {
			if ( ! state.asset) {
				return 0;
			}

			const asset = accountStore.getAsset( state.asset.asset_identifier );
			if ( ! asset) {
				return 0;
			}

			let available = asset.balance?.available ?? 0;
			available = state.asset.listings.filter(
				listing => listing.seller_blokpax_user_id == authStore.user.id
			).reduce((avail, listing) => {
				if (false == state.editingListing || state.listing.id != listing.id) {
					avail -= listing.quantity;
				}

				return avail;
			}, available);

			return available;
		})

		const state = reactive({
			loading: true,
			processing: false,
			listingValid: false,
			asset: null,
			storefront: null,
			saleType: saleTypes[0],
			duration: durations[5],
			expiration: null,
			price: null as number | null,
			quantity: 1,
			errors: {} as any,
			listingError: false,
			listingErrorMessage: null,
			created: false,
			editingListing: false,
			modifyExpiration: false,
			listing: null as Listing | null,
			confirmDeleteListing: false,
			deleting: false,
			deleted: false,
		});

		watch(() => state.asset, (cur) => {
			//does the current user own this asset?
			if (! cur) {
				router.push('/');
			}
			//add history
		});

		async function loadAsset() {
			state.loading = true;
			const wait = sleep(.75);

			await useAsset(props.slug, props.assetId, authStore.user).then((response) => {
				/* @ts-ignore */
				state.asset = response.asset.data.asset;

				/* @ts-ignore */
				state.storefront = response.asset.data.storefront;
			});

			if (undefined !== route.params.listingId) {
				const listing = state.asset.listings.find(
					l => l.id == route.params.listingId
				) ?? null;

				if ( null == listing) {
					alert("Can't find listing");
					return
				}

				state.editingListing = true;
				populateListing(listing);
				checkListingValid();
			}

			wait.then(() => state.loading = false);
		}

		function populateListing(listing: Listing) {
			state.listing = listing;
			state.quantity = listing.quantity;
			state.price = (new Currency(listing.price)).toDecimal();
		}

		onMounted(async() => {
			loadAsset();
		});

		function checkListingValid() {
			state.listingValid = isListingValid();
		}

		function isListingValid() {
			let errors = {} as any;
			if ( ! state.quantity || state.quantity < 1) {
				errors.quantity = "Enter a quantity of one, or more.";
			}

			if (state.quantity > ownerAvailable.value) {
				errors.quantity = "Can not list more than you have available.";
			}

			if ( ! String(state.price).match(/^[0-9,]+$/)) {
				errors.price = "Enter a valid price";
			}

			if (state.price < 1) {
				errors.price = "Price must be more than zero.";
			}

			if ( !! state.expiration && (false == state.editingListing || (state.editingListing && true == state.modifyExpiration))) {
				errors.duration = 'Select a valid duration.';
			}

			state.errors = errors;
			return Object.keys(errors).length == 0;
		}

		async function processListing() {
			state.processing = true;
			state.listingError = false;
			state.listingErrorMessage = null;
			const minTimeout = sleep(1.5);

			if ( ! isListingValid()) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				state.processing = false;
				return false;
			}

			let listingParams = {
				token_id: state.asset.id,
				storefront_id: state.asset.storefront_id,
				listing_type: state.saleType.id,
				price: Currency.fromDecimal(String(state.price).replace(/\D/g, '')).toString(),
				quantity: state.quantity,
				expires_at: (false == state.editingListing || state.modifyExpiration)
					? state.duration.formattedDate
					: null,
			} as NewListing;

			let listing;
			try {
				if (false == state.editingListing) {
					listing = await assetStore.createListing(listingParams);
				} else {
					listingParams.id = state.listing.id;
					listing = await assetStore.editListing(state.listing.id, listingParams);
				}
			} catch (e) {
				console.error(e);
				state.processing = false;
				// show error
				return false;
			}

			await minTimeout;
			state.processing = false;

			if ( ! listing) {
				state.listingError = true;
				state.listingErrorMessage = 'Listing could not be created';
				window.scrollTo({ top: 0, behavior: 'smooth' });
				return false;
			}

			state.listing = listing;
			state.created = true;
		}

		async function deleteListing() {
			state.deleting = true
			await sleep(1.5)
			state.confirmDeleteListing = false;
			state.deleted = await assetStore.deleteListing(state.listing.id);
			// state.deleted = true;
			state.deleting = false;
		}

		function backToAssetDetail() {
			router.push({
				name: 'asset',
				params: { slug: state.storefront.slug, assetId: state.asset.id }
			});
		}

		return {
			checkListingValid,
			processListing,
			deleteListing,
			ownerAvailable,
			open,
			props,
			DateTime,
			assetStore,
			durations,
			saleTypes,
			state,
			exchangeStore,
			backToAssetDetail,
		};
	},
});
</script>
