<template>
	<modal class="z-50">
		<template #default>
			<div class="bg-white rounded-xl overflow-hidden drop-shadow-md w-full">
				<div class="p-6 grid md:grid-cols-[2fr,3fr] gap-6 items-start">
					<div>
						<img
							:src="props.asset.image"
							class="rounded-md h-48 md:h-auto mx-auto"
						/>
						<div class="font-poppins text-lg text-center mt-2">
							<p>{{ props.asset.name }}</p>
						</div>
					</div>
					<div class="flex flex-col h-full">
						<h1 class="text-2xl font-medium font-poppins">Transfer</h1>

						<div class="mt-4 font-sm">
							<p>Transfer "{{ props.asset.name }}" to:</p>

							<div v-if="! state.confirm">
								<user-search
								 	:focus-on-load="true"
									@selected="selectRecipient"
									@focus="handleSearchFocus"
								/>
								<p class="text-sm text-gray-400 mx-2 mt-1 text-right">Search for a recipient by their username.</p>

								<div class="mt-4">
									<div class="flex items-center justify-end space-x-2">
										<div class="flex-grow text-right">
											<p>Quantity:</p>
											<p class="text-sm text-gray-400">Available: {{ props.maxTransferrable }}</p>
										</div>
										<div class="w-1/2 sm:w-1/4">
											<input class="w-full rounded-xl border-gray-200"
												type="number"
												v-model="state.quantity"
											/>
										</div>
									</div>
								</div>
							</div>
							<div v-else class="text-lg space-y-2 mt-4">
								<div class="">
									<span class="font-medium">Recipient:</span>
									<span> @{{ state.recipient.username }}</span>
								</div>

								<div class="">
									<span class="font-medium">Quantity:</span>
									<span> &nbsp;{{ state.quantity }}</span>
								</div>

								<div v-if="! state.success">
									<div class="bg-red-100 rounded-xl px-4 py-3 text-red-600 space-y-2 text-sm">
										<p>
											<span>
												<i class="fa fa-exclamation-triangle" />
											</span>
											This action <em>cannot</em> be un-done. If this is not
											your intended recipient, Blokpax will not be able to retreive
											your card for you.
										</p>
									</div>

									<p class="text-center">
										<button class="underline text-sm text-gray-500 mt-2"
											@click="state.confirm = false">Edit transfer details</button>
									</p>
								</div>
								<div v-else>
									<div class="my-6 bg-green-200 rounded-xl py-4 px-6">
										<p class="text-green-900 space-x-2">
											<span class="inline-flex justify-center items-center px-2 bg-green-600 text-white text-xl aspect-square rounded-full">
												<i class="fa fa-check"></i>
											</span>
											<span class="text-xl">Success!</span>
										</p>
										<p class="text-green-900 text-base mt-2">
											Your transfer has bee completed successfully.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-4 bg-gray-100 text-right space-x-6 pt-6 pb-4 px-6">
					<button
						class="text-gray-500"
						@click.stop="cancel"
					>
						Cancel
					</button>

					<button v-if=" ! state.confirm"
						class="btn-primary-lg text-white space-x-1"
						:disabled=" ! formValid"
						@click="send">
						<i class="fa-solid fa-paper-plane" />
						Send
					</button>

					<button v-if="state.confirm && ! state.success"
						class="btn-primary-lg text-white space-x-1"
						@click="confirmSend"
						:disabled="state.transferring">
						<span v-if="! state.transferring">
							<i class="fa-solid fa-paper-plane" />
							Confirm
						</span>
						<span v-else>
							<i class="fa-sharp fa-solid fa-spinner-third fa-spin"></i>
							Sending
						</span>
					</button>

					<button v-if="state.confirm && state.success"
						class="btn-primary-lg text-white space-x-6"
						@click="transferComplete">
						Done
					</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { computed, defineComponent, onUnmounted, PropType, reactive } from "vue";
import { Asset } from '@/types/Asset';
import { useUserProfileStore } from "@/stores/UserProfileStore";
import UserSearch from '@/components/widgets/UserSearch.vue';
import { useMarketStore } from "@/stores/MarketStore";
import sleep from "@/util/sleep";

const userProfileStore = useUserProfileStore();
const props = withDefaults(defineProps<{
	asset: Asset;
	maxTransferrable: Number;
}>(), {
});

const emit = defineEmits<{
	close: [],
	complete: [Asset],
}>();

interface SearchResult {
	username: string; objectID: string;
}

const state = reactive({
	quantity: 1,
	recipient: null as SearchResult | null,
	confirm: false,
	pin: null,
	skipConfirm: false,
	transferring: false,
	success: null as boolean | null,
	message: null as string | null,
});

function selectRecipient(user: SearchResult | null) {
	state.recipient = user;
	if (state.recipient) {
		setTimeout(monitorEnterKey, 50);
	}
}

let listening = false;
function monitorEnterKey() {
	if ( ! listening) {
		console.log('monitoring enter key');
		listening = true;
		window.addEventListener('keyup', handleEnterPress);
	}
}

function handleSearchFocus() {
	console.log('unmonitoring enter key');
	stopEnterListener();
}

function stopEnterListener() {
	listening = false;
	window.removeEventListener('keyup', handleEnterPress);
}

function handleEnterPress($evt) {
	console.log($evt.code);
	if ($evt.code == 'Enter') {
		console.log('confirm, success', state.confirm, state.success);
		if ( ! state.confirm) {
			console.log('advancing to confirm step')
			send();
		} else if ( ! state.success) {
			console.log('executing send');
			confirmSend();
			stopEnterListener();
		}
	}
}

onUnmounted(() => {
	handleSearchFocus();
})

const formValid = computed(() => {
	if (state.quantity < 1) return false;
	if (state.quantity > props.maxTransferrable) return false;
	if (state.recipient == null) return false;

	return true;
});

function send() {
	if ( ! formValid.value) {
		alert("Can not transfer.");
		return false;
	}

	state.confirm = true;
}

async function confirmSend() {
	state.transferring = true;
	const wait = sleep(.75);
	if ( ! formValid.value) {
		await wait;
		alert("Transfer settings are invalid");
		state.transferring = false;
		return false;
	}

	const marketStore = useMarketStore();
	const response = await marketStore.otcTransfer(
		[{
			asset_id: props.asset.asset_identifier,
			quantity: state.quantity,
		}],
		state.recipient.username,
		state.pin,
	)

	await wait;

	if (response.success) {
		state.success = true;
	} else {
		state.success = false;
		state.message = response.message;
		console.error(state.message);
	}

	state.transferring = false;
}

function cancel() {
	state.quantity = 1;
	state.recipient = null;
	emit('close');
}

function transferComplete() {
	emit('complete', props.asset);
}
</script>
