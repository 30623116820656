<template>
	<div class="bpmp-storefront">

		<storefront-header :storefront="storeStore.state.storefront" />

		<div class="bpmp-storefront__pre-content mt-4 mx-4">
			<div class="border-b-2 border-gray-300 flex space-x-4">
				<router-link
					:to="{ name: 'storefront', params: { slug: storeStore.state.storefront.slug, tab:`listings`}}">
					<h2 v-if="currentTab != `activity`" class="px-4 text-lg font-medium pb-1 border-b-2 border-gray-500">Items</h2>
					<h2 v-if="currentTab == `activity`" class="px-4 text-lg font-medium text-gray-500">Items</h2>
                </router-link>
				<router-link
                    :to="{ name: 'storefront-activity', params: { slug: storeStore.state.storefront.slug, tab: `activity` }}">
					<span v-if="currentTab != `activity`" class="px-4 text-lg font-medium text-gray-500">Activity</span>
					<span v-if="currentTab == `activity`" class="px-4 text-lg font-medium pb-1 border-b-2 border-gray-500">Activity</span>
				</router-link>
			</div>

			<div v-if="currentTab != `activity`" class="bpmp-storefront__content my-6 flex space-x-2 md:space-x-4 items-stretch">
				<button class="bg-white shadow px-4 md:py-2 aspect-square md:aspect-auto border border-gray-300 rounded-xl space-x-2 font-medium"
					@click.prevent="toggleFilters">
					<i class="fa-solid fa-bars-filter"></i>
					<span class="hidden md:inline-block">Filter</span>
				</button>

		
				<div class="bg-white shadow border border-gray-300 rounded-xl px-4 py-1 text-gray-600 flex-shrink flex-grow flex items-center
					focus-within:ring-1 focus-within:ring-gray-600">
					<i class="fa-solid fa-magnifying-glass flex-grow-0 flex-shrink-0"></i>
					<input type="text" placeholder="Search by name or trait"
						v-model="state.tmpAssetQuery"
						@keyup.enter.prevent="executeSearch"
						class="w-full border-none outline-none hover:outline-none focus:ouline-none focus:ring-0 flex-grow flex-shrink"
					>
					<p>
						<button v-show="state.assetQuery.length"
							@click="clearSearch(true)">
							<i class="fa fa-times" />
						</button>
					</p>
				</div>

				<div class="relative">
					<button class="shadow h-full bg-white border border-gray-300 rounded-xl px-4 py-2 font-medium leading-none"
						@click.stop="state.showSortOptions = ! state.showSortOptions">
						<span class="hidden md:flex items-center justify-center space-x-2">
							<p>{{ currentSort.label }}</p>
							<p>
								<i class="fa-solid fa-chevron-down"></i>
							</p>
						</span>
						<span class="md:hidden">
							<i class="fa-solid fa-arrow-up-arrow-down"></i>
						</span>
					</button>
					<div class="
						flex-shrink-0
					 	shadow-t-md
						border-t-2 border-gray-300
						fixed inset-0 top-auto h-auto z-50 bg-gray-50 px-4 py-4
						md:absolute md:z-20 md:shadow-lg md:top-full md:bottom-auto md:right-0 md:left-auto md:bg-white md:min-w-full md:border md:border-gray-300 md:mt-2 md:rounded-xl md:px-2 md:py-2
						"
						v-cloak
						v-show="state.showSortOptions"
						>
						<div class="flex justify-between m-4 sm:hidden">
							<h2 class="font-semibold text-xl flex-grow text-left">Sort</h2>
							<button @click.prevent="toggleFilters"><i class="fa-regular fa-sharp fa-times text-xl" /></button>
						</div>
						<div v-for="sortOption in sortOptions" :key="`sort-${sortOption.val}`"
							class="whitespace-nowrap px-4 py-3 hover:bg-gray-100/80 rounded-lg cursor-pointer flex justify-between items-center space-x-2"
							@click="updateSort(sortOption.val)">
							<p>{{ sortOption.label }}</p>
							<p v-cloak v-show="state.sortBy == sortOption.val"><i class="fa-regular fa-check text-xl" /></p>
						</div>
					</div>
				</div>
			</div>
			<!-- ^^^ Filter search -->
		</div>

		<div class="mx-4">
			<div class="bpmp-storefront__content flex sm:space-x-4">
				<storefront-filters
					v-if="currentTab != `activity`"
					class="flex-grow-0 flex-shrink-0 space-y-2
					z-30 fixed inset-0 bg-gray-50 w-full flex flex-col
					sm:relative sm:block sm:w-1/2 sm:max-w-[20rem]
					lg:w-1/5"
					v-show="state.showFilters"
					v-cloak
					:show-collection-filters="true"
					@close="toggleFilters"
					@done="toggleFilters"
					@clear="() => storeStore.clearFilters() || updateFilterParams()"
					@filtersUpdated="updateFilterParams"
				/>
				<div class="flex-grow">
					<filter-summary :query="state.assetQuery" :filters="storeStore.state.activeFilters"
						@clearQuery="clearSearch"
						@removeFilter="removeAndUpdateFilters" />

						

					<grid
						v-if="currentTab != `activity`"
						class="mt-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 flex-grow"
						data-key="id"
						:pageProvider="(state.pageProvider as PageProvider)"
						:pageProviderDebounceTime="5"
						:pageSize="Math.min(24, (state.pageInfo?.total ?? 24))"
						:length="state.pageInfo?.total ?? 100"
						:get-key="(item: InternalItem|Asset|any) => `${item.storefront_id}:${item.id}`"
					>
						<template #probe>
							<loading-tile />
						</template>
						<template #placeholder="{ style }">
							<loading-tile :style="style" />
						</template>
						<template #default="{ item, index, style }">
							<storefront-asset
								:asset="item"
								:style="style"
								:slug="storeStore.state.storefront.slug"
							/>
						</template>
					</grid>
					<div class="flex flex-col">
						<ActivityTable />	
						<!-- ACTIVITY PAGE -->
					</div>
				</div>
			</div>
				
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType, reactive, computed, onBeforeMount, ref } from "vue";
import StorefrontHeader from "@/components/StorefrontHeader.vue";
import StorefrontAsset from "@/components/StorefrontAsset.vue";
import StorefrontActivityAsset from "@/components/StorefrontActivityAsset.vue";
import ActivityTable from "@/components/ActivityTable.vue";

import StorefrontFilters from "@/components/StorefrontFilters.vue";
import ToUsd from '@/components/ToUsd.vue'
import { humanReadablePrice } from '@/util/currencyFormat'

import FilterSummary from "@/components/FilterSummary.vue";
import LoadingTile from "@/components/LoadingTile.vue";
import {
	ShoppingBagIcon,

	ShoppingCartIcon,
	TagIcon,
	ArrowsUpDownIcon,
	ChevronDoubleDownIcon,
	ChevronDoubleUpIcon,
	UsersIcon,
	EyeIcon,
	HeartIcon,
	TableCellsIcon,
	WalletIcon,
	UserIcon,
} from '@heroicons/vue/24/outline'
import TileLoader from "@/components/TileLoader.vue";
import Grid from 'vue-virtual-scroll-grid';
import { useStorefrontStore } from '@/stores/StorefrontStore';
import { AssetFilter, AssetSearchPagination } from "@/types/Storefront";
import sleep from "@/util/sleep";
import { InternalItem, PageProvider } from "./../../node_modules/vue-virtual-scroll-grid/dist/pipeline";
import { Asset } from '@/types/Asset';
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
	props: ['tab'],
	components: { 
		Grid, 
		StorefrontHeader,
		ShoppingBagIcon,
		ActivityTable,
		ArrowsUpDownIcon,
		TagIcon,
		ChevronDoubleDownIcon,
		ChevronDoubleUpIcon,
		UsersIcon,
		ShoppingCartIcon,
		EyeIcon,
		HeartIcon,
		TableCellsIcon,
		WalletIcon,
		UserIcon, 
		StorefrontAsset, 
		StorefrontActivityAsset,
		 StorefrontFilters, 
		 ToUsd, 
		 FilterSummary, 
		 TileLoader,
		  LoadingTile },
	
	setup(props) {
		const router = useRouter();
		const route = useRoute();
		const state = ref({
			showFilters: false,
			showSortOptions: false,
			activityLoaded:false,
			activityData:{},
			tmpAssetQuery: '',
			assetQuery: '',
			
	
			activeFilters: {} as any,
			sortBy: 'price_asc',
			pageInfo: {} as AssetSearchPagination,
			filterParams: {} as AssetFilter,
			pageProvider: null as Function,
		});

		const storeStore = useStorefrontStore();

		const sortOptions = [
			{ label: "Name A to Z", val: "name_asc"},
			{ label: "Price low to high", val: "price_asc" },
			{ label: "Price high to low", val: "price_desc" },
			{ label: "Recently listed", val: "listed_on_desc" },
			{ label: "Oldest listings", val: "listed_on_asc" },
		];

		

		const currentSort = computed(() => {
			return sortOptions.find(o => o.val == state.value.sortBy);
		});

		onBeforeMount(() => {
			// data massaging
			// (route.params) object will make values strings if there is only one value.
			// we need the value to always be an array for other components to continue working
			if (Object.entries(storeStore.state.activeFilters).length === 0 && Object.entries(route.query).length > 0) {
				const activeFiltersFromUrlQuery = Object.entries(route.query).reduce((objDataMassaged, keyValPair) => {
					objDataMassaged[keyValPair[0]] = Array.isArray(keyValPair[1]) ? [...keyValPair[1]] : [keyValPair[1]];
					return objDataMassaged
				}, {});
				storeStore.state.activeFilters = activeFiltersFromUrlQuery;
			}
		});

		onMounted(() => {
			// default the filters to open when the component mounts
			// if the user is on a tailwind md or bigger screen
			state.value.showFilters = window.innerWidth > 768;

			updateFilterParams();
		})

		

		function updateFilterParams() {
			state.value.filterParams = {
				query: state.value.assetQuery,
				filters: storeStore.state.activeFilters,
				sort: state.value.sortBy,
			}

			// if active filters is not an empty object,
			// replace url state add it to url as query strings
			if (Object.entries(storeStore.state.activeFilters).length > 0) {
				const urlWithQueries = router.resolve({ query: storeStore.state.activeFilters });
				history.pushState(history.state, null, urlWithQueries.fullPath);
			}

			state.value.pageProvider = getPageProvider()
		}

		

		function updateSort(sort: string) {
			state.value.sortBy = sort;
			state.value.showSortOptions = false;
			updateFilterParams();
		}

		

		function getPageProvider(): PageProvider {
			return async (pgNumber, pgLimit) => {
				const result = await storeStore.loadStoreAssets(
					storeStore.state.storefront.slug,
					pgNumber,
					pgLimit,
					state.value.filterParams
				);

				state.value.pageInfo = result.pagination;
				return result.assets
			}
		}

	

	

		const TAB_ACTIVITY = 'activity';
		const TAB_ACCOUNT_ACTIVITY = 'account-activity';
		const TAB_ITEMS = 'items';

		const currentTab = computed(() => {
			switch (route.params.tab) {
				case 'activity':
					return TAB_ACTIVITY;

				case 'account-activity':
					return TAB_ACCOUNT_ACTIVITY;

				default:
					return TAB_ITEMS;
				}
			})



		function clearSearch(withInput: boolean = false) {
			state.value.assetQuery = ''

			if (withInput) {
				state.value.tmpAssetQuery = ''
			}

			updateFilterParams()
		}

		function executeSearch() {
			state.value.assetQuery = state.value.tmpAssetQuery;
			updateFilterParams();
		}

		function toggleFilters() {
			state.value.showFilters = ! state.value.showFilters
		}

		function removeAndUpdateFilters(filter: string, value: string) {
			storeStore.removeFilter(filter, value);
			updateFilterParams();
		}

		updateFilterParams()

		return {
			state,
			storeStore,
			toggleFilters,
			currentTab,
			route,
			clearSearch,
			executeSearch,
			humanReadablePrice,
			props,
			updateFilterParams,
			sortOptions,
			ShoppingBagIcon,
			ShoppingCartIcon,
			TagIcon,
			ArrowsUpDownIcon,
			ChevronDoubleDownIcon,
			ChevronDoubleUpIcon,
			UsersIcon,
			EyeIcon,
			HeartIcon,
			TableCellsIcon,
			WalletIcon,
			UserIcon,
			currentSort,
			updateSort,
			removeAndUpdateFilters
		}
	}
})
</script>
