import Currency from '@/types/Currency'

export default function currencyFormat(val: number | String): String {
	return new Intl.NumberFormat('en-US', {
		minimumIntegerDigits: 1,
		minimumFractionDigits: 2,
		style: "decimal",
		trailingZeroDisplay: 'stripIfInteger',
	}).format(String(val))
}

export function humanReadablePrice(amt: string | number) {
	return new Currency(String(amt)).toDecimal()
}
