<template>
	<div class="flex flex-col h-full">
		<h1 class="text-2xl font-semibold">Choose Payment Method</h1>

		<div
			class="mt-4 grid grid-cols-[3fr,4fr] border-b border-t border-gray-200 px-2 py-4"
		>
			<div class="order-1 text-right">
				<p class="mr-8 text-right"><strong>Price</strong></p>
				{{ $format(humanReadablePrice(String(props.unitPrice))) }}
				{{ $token }}
			</div>

			<div class="text-center">
				<strong>Quantity</strong><br />
				{{ props.quantity }}
			</div>
		</div>

		<p class="mt-2 text-right">
			<strong>Total</strong>
			{{ $format(humanReadablePrice(String(props.bpxPrice))) }}
			{{ $token }}
		</p>

		<div class="flex-grow"></div>

		<div class="mt-6">
			<p class="text-lg text-gray-700 text-center font-poppins">
				Select a payment method
			</p>
			<div class="mt-3 grid grid-cols-[4fr,1fr,4fr] items-start gap-2">
				<div>
					<div class="">
						<button
							@click="emit('payment-method', 'bpx')"
							type="button"
							class="block w-full h-full items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-3 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50 group disabled:bg-gray-300 disabled:text-gray-400"
							:disabled="
								newWalletStore.visibleBpx == 0 ||
								props.enabled == false
							"
						>
							Pay with BPX
						</button>
					</div>

					<p
						:class="[
							'mt-2 text-center text-sm order-1',
							newWalletStore.visibleBpx == 0
								? 'text-gray-400'
								: 'text-gray-600',
						]"
					>
						{{ $token }}
						Available
						<br />
						{{
							$format(
								humanReadablePrice(newWalletStore.visibleBpx)
							)
						}}
					</p>
				</div>

				<div class="text-center pt-3">
					<span class="bg-white text-sm text-gray-500 uppercase"
						>or</span
					>
				</div>

				<div>
					<div class="">
						<button
							type="button"
							:disabled="!props.enabled || usdPrice < 25"
							class="block w-full h-full items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-3 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-300 disabled:text-gray-400"
							@click="emit('payment-method', 'cc')"
						>
							Credit Card
						</button>
					</div>

					<p class="text-sm text-gray-600 text-center mt-2">
						USD Price
						<br />
						<to-usd :bpx="props.bpxPrice" :show-usd="false" />
					</p>

					<p
						class="text-xs text-gray-600 text-center mt-2"
						v-if="usdPrice < 25"
					>
						$25 minimum order required. Store credit option coming
						soon.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import {
	defineComponent,
	PropType,
	withDefaults,
	defineProps,
	defineEmits,
	watch,
	ref,
	onMounted,
} from 'vue'
import ToUsd from '@/components/ToUsd.vue'
import { humanReadablePrice } from '@/util/currencyFormat'
import { useNewWalletStore } from '@/stores/NewWalletStore'
import { useExchangeStore } from '@/stores/ExchangeStore'

const emit = defineEmits<{
	(event: 'payment-method', method: string | null): void
}>()

const props = withDefaults(
	defineProps<{
		bpxPrice: String | Number
		unitPrice: String | Number
		quantity?: Number
		enabled?: Boolean
	}>(),
	{
		quantity: 1,
		enabled: true,
	}
)

const exchStore = useExchangeStore()
const newWalletStore = useNewWalletStore()

const usdPrice = ref(0)

onMounted(async () => {
	usdPrice.value = await exchStore.usd(props.bpxPrice.toString(), false)
})
</script>
