import { createRouter, createWebHistory } from 'vue-router'

// import IndexPage from '@/pages/Index.vue';
import Storefront from '@/pages/Storefront.vue'
import Activity from '@/pages/Activity.vue';
import AuctionList from '@/pages/AuctionList.vue'
import AuctionIndex from '@/pages/AuctionIndex.vue'
import AuctionDetail from '@/pages/AuctionDetail.vue'
import AccountPage from '@/pages/AccountPage.vue'
import TosExit from '@/pages/TosExit.vue'
import OAuthCallback from '@/pages/OAuthCallback.vue'
import AuthHandoff from '@/pages/AuthHandoff.vue'
import Index from './pages/Index.vue'
import AssetDetail from '@/pages/AssetDetail.vue';
import ListAsset from '@/pages/ListAsset.vue';
import TermsOfService from '@/pages/TermsOfService.vue';

const routes = [
//   {
//     path: '/',
//     component: IndexPage,
// 	name: 'home',
//     meta: {
//       title: 'Featured Stuff',
//     },
//   },
	{
		path: '/',
		component: Index,
		name: 'home',
		meta: {
			title: 'Blokpax Market'
		}
	},

	{
		path: '/store/:slug/:assetId(\\d+)',
		component: AssetDetail,
		name: 'asset',
		meta: {
			title: ''
		}
	},
	{
		path: '/store/:slug/:tab(\\w+)',
		component: Storefront,
		name: 'storefront-activity',
		meta: {
			title: 'BPX Market - %s'
		}
	},
	{
		path: '/store/:slug',
		component: Storefront,
		name: 'storefront',
		meta: {
			title: 'BPX Market - %s'
		}
	},
	
	{
		path: '/store/:slug/:assetId/list/',
		component: ListAsset,
		name: 'list-asset',
		meta: {
			title: '',
		},
	},
	{
		path: '/store/:slug/:assetId/listing/:listingId/edit',
		component: ListAsset,
		name: 'edit-asset-listing',
		meta: {
			title: '',
		},
	},
	{
		path: '/auctions',
		component: AuctionList,
		name: 'auction-index',
		meta: {
			title: 'Active Auctions'
		}
	},
	{
		path: '/:group',
		component: AuctionIndex,
		name: 'auction-listings',
		meta: {
			title: 'Active Auctions'
		}
	},
	{
		path: '/:group/:auction(\\d+)',
		component: AuctionDetail,
		name: 'auction'
	},
	{
		path: '/account',
		component: AccountPage,
		name: 'account'
	},
	{
		path: '/account/:tab(\\w+)',
		component: AccountPage,
		name: 'account-activity',
		
	},
	{
		path: '/account/:tab',
		component: AccountPage,
		name: 'account-activity',
		
	},
	
	{
		path: '/account/:subpage(\\w+)',
		component: AccountPage
	},
	{
		path: '/tos-exit',
		component: TosExit
	},
	{
		path: '/auth/callback',
		component: OAuthCallback
	},
	{
		path: '/auth/handoff',
		component: AuthHandoff,
	},
	{
		path: '/terms-of-service',
		component: TermsOfService,
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (to.hash) {
					resolve({ el: to.hash })
				}

				resolve(savedPosition || { top: 0 })
			}, 500)
		})
		// if (to.hash) {
		// 	return { el: to.hash }
		// }

		// return savedPosition || { top: 0 }

		// return { top: 0 }
	}
})

export default router
