<template>
	<div class="group relative bg-white shadow rounded-xl cursor-pointer">
		<div class="group relative">
			<p v-if="props.balance && (props.balance?.balance ?? 0) > 1"
				class="flex justify-center items-center
				absolute top-4 right-4
				px-2 py-2
				leading-none text-sm
				bg-black/80 rounded-lg text-white
				shadow-md shadow-gray-400
				z-10">
				<i class="fa-regular fa-layer-group mr-1 p-0 m-0 leading-none"></i>
				{{ props.balance?.balance ?? 0 }}
			</p>
			<div class="rounded-t-xl overflow-hidden shadow">
				<router-link
					:to="{ name: 'asset', params: { slug: props.slug, assetId: props.asset.id }}">
				<img
					:src="props.asset.image"
					@load="state.loaded = true"
					loading="lazy"
					class="w-full ease-in-out duration-300 hover:scale-110" />
				<!-- <loading-placeholder v-if=" ! state.loaded"
					class="w-full h-52 min-h-[13rem]">
				</loading-placeholder> -->
				</router-link>
				<div class="flex items-end p-4 opacity-0 group-hover:opacity-100 hidden" aria-hidden="true">
					<button
						type="button"
						class="pt-2 flex w-full inline-flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-8 py-3 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50  ease-in-out duration-300 hover:scale-110">
						Buy Now
					</button>
				</div>
			</div>
		</div>
		<div class="py-4 px-3">
			<p class="font-medium md:text-sm overflow-hidden whitespace-nowrap text-ellipsis"
				:title="props.asset.name">
				{{ props.asset.name }}
			</p>
			<router-link v-if="props.showStorefrontName"
				:to="{ name: 'storefront', params: { slug: storefront.slug }}"
				:title="storefront.name"
				class="block text-gray-500 text-sm whitespace-nowrap overflow-hidden text-ellipsis">
				{{ storefront.name }}
			</router-link>
			<p class="text-sm text-slate-700 mt-4 text-left" v-if="props.asset.floor_listing">
				{{ $format(humanReadablePrice(
					(props.asset.floor_listing?.quantity ?? 0) * (props.asset.floor_listing?.price ?? 0)
				)) }}
				{{ $token }}
				<span class="text-xs text-slate-400" v-if="props.asset.floor_listing.quantity > 1">({{ props.asset.floor_listing.quantity}}x)</span>
				<br>
				<span class="text-xs text-slate-500"><to-usd :bpx="props.asset.floor_listing.price * props.asset.floor_listing.quantity" /></span>
			</p>
		</div>
		<div v-if="props.balance && props.balance.available > 0"
			class="hidden group-hover:flex justify-left items-stretch
			absolute bottom-0 left-0 right-0 rounded-b-xl overflow-hidden
			text-white shadow-t-md">
			<button div class="block bg-sky-800 h-full flex-grow text-center px-4 py-2
				hover:bg-sky-700"
				@click="state.showTransferAssetModal = true">
				Transfer
			</button>
			<button class="hidden border-l border-sky-100 flex-shrink h-full bg-sky-800 hover:bg-sky-700 px-4 py-2">
				...
			</button>
		</div>
		<transfer-asset-modal v-if="state.showTransferAssetModal"
			:asset="props.asset"
			:max-transferrable="props.balance.available"
			@close="state.showTransferAssetModal = false"
			@complete="handleAssetTransferred"
		/>
	</div>
</template>
<script lang="ts">
import { Asset } from "@/types/Asset";
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import LoadingPlaceholder from "./LoadingPlaceholder.vue";
import { useMarketStore } from "@/stores/MarketStore";
import { ShoppingBagIcon } from '@heroicons/vue/24/outline';
import TransferAssetModal from '@/components/modals/TransferAssetModal.vue'
import { humanReadablePrice } from "@/util/currencyFormat";
import ToUsd from "./ToUsd.vue";

export default defineComponent({
    components: { LoadingPlaceholder, ShoppingBagIcon, ToUsd, TransferAssetModal },
	emits: ['asset-transferred'],
	props: {
		asset: {
			required: true,
			type: Object as PropType<Asset>,
		},
		slug: {
			required: true,
			type: String,
		},
		showStorefrontName: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		balance: {
			required: false,
			type: [Object, Boolean] as PropType<any | boolean>,
			default: () => false
		},
	},
	setup(props, { emit: $emit }) {
		const state = reactive({
			loaded: false,
			showTransferAssetModal: false,
		});
		const marketStore = useMarketStore();
		const storefront = computed(() => {
			return marketStore.byID[props.asset.storefront.id]
		});

		function handleAssetTransferred(asset) {
			state.showTransferAssetModal = false;
			$emit('asset-transferred', asset);
		}

		return {
			props,
			state,
			storefront,
			humanReadablePrice,
			handleAssetTransferred,
		}
	}
})
</script>
