<style lang="css" scoped>
/* .logo { background: white; } */
</style>
<template>
	<div class="bg-black flex justify-between items-stretch px-2 drop-shadow-md font-poppins">
		<div class="flex items-center">
			<router-link
				:to="{ name: 'home' }"
				title="Blokpax Market"
				class="border-transparent border-2 py-4 px-2 overflow-hidden flex items-center justify-center flex-shrink-0"
				>
				<LogoSvg width="50" height="50" fill="#ffffff" class="xs:hidden" />
				<WordmarkSvg fill="#ffffff" class="h-64 max-h-6 sm:max-h-12 max-w-[18rem] hidden xs:block" />
				<img src="/image/blokpax-logo-white.webp" class="hidden h-8">
			</router-link>
			<!-- <router-link :to="{ name: 'home' }" class="text-xl block py-6 px-4 border-b-2 border-transparent hover:border-slate-400">
				Home
			</router-link>
			<router-link :to="{ name: 'auction-index' }" class="text-xl block py-6 px-4 border-b-2 border-transparent hover:border-slate-400">
				Auctions
			</router-link> -->
			<!-- <router-link to="/faq" class="block py-6 px-4 border-b-2 border-transparent hover:border-slate-400">
				FAQ
			</router-link> -->
		</div>
		<auth-block />
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import AuthBlock from "@/components/AuthBlock.vue";
import metamask from '@/util/metamask'
import { useWalletStore } from "@/stores/WalletStore";
import { useAppStore } from "@/stores/AppStore";
import LogoSvg from "@/../public/image/logo-noword.min.svg";
import WordmarkSvg from "@/../public/image/logo-wordmark.v2.min.svg";

export default defineComponent({
    components: { AuthBlock, LogoSvg, WordmarkSvg },
	setup(props) {
		const walletStore = useWalletStore()
		const appStore = useAppStore()

		const currentBalance = computed(() => {
			return walletStore.currentBalance;
		})

		return {
			LogoSvg, WordmarkSvg,
			metamask,
			appStore,
			currentBalance
		}
	}
})
</script>
