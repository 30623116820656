import { defineStore } from 'pinia'
import { useAuthStore } from './AuthStore'
import { computed, ref, watch } from 'vue';
import api from '@/util/api';
import { BN } from 'bn.js';

const pollingTimeout = 30 * 1000;

export interface MarketCurrency {
	id: string
	name: string | null
	slug: string | null
	balance: string | number
	available: string | number
}

export const useNewWalletStore = defineStore('newWallet', () => {
	const authStore = useAuthStore();
	let walletMonInterval;

	const balances = ref<MarketCurrency[]>([] as MarketCurrency[]);
	const balanceModalVisible = ref(false);

	watch(
		() => authStore.user,
		(newUser, oldUser) => {
			// console.log('authstore user changed');
			if (newUser.id == (oldUser?.id?? null)) {
				return;
			}

			monitorBalances();
		}
	);

	function monitorBalances() {
		clearInterval(walletMonInterval);
		clearBalances();

		if (authStore.user.id) {
			walletMonInterval = setInterval(pollBalance, pollingTimeout);
		}
	}

	function clearBalances() {
		balances.value = [] as MarketCurrency[];
	}

	async function pollBalance() {
		if ( ! authStore.authenticated) {
			clearBalances();
		}

		const newBalances = await api.getCurrencyBalances(authStore.token.data.accessToken);
		balances.value = newBalances.balance;
	}

	function toggleBalanceModal(force: boolean) {
		if (force === undefined) {
			force = ! balanceModalVisible.value
		}

		// console.log('modal', force);

		balanceModalVisible.value = force;
	}

	async function withdraw(asset: string, amount: string, wallet: string) {
		const token = authStore.token
		const response = await api.withdraw(
			token.data.accessToken,
			asset,
			amount,
			wallet
		)

		return response
	}

	const visibleBpx = computed(() => {
		return balances.value.filter(c => c.slug == 'bpx' || c.slug == 'vbpx')
		.reduce((t, c) => {
			return t.add(new BN(String(c.available)));
		}, new BN(0));
	});

	const withdrawableBpx = computed(() => {
		const bpx = balances.value.find(c => c.slug == 'bpx')

		if (bpx) {
			return String(bpx.available);
		}

		return "0";
	});

	if (authStore.authenticated && ! walletMonInterval) {
		monitorBalances();
		pollBalance();
	}

	return {
		// state
		balances,
		balanceModalVisible,

		// actions
		pollBalance,
		toggleBalanceModal,
		withdraw,

		// getters
		visibleBpx,
		withdrawableBpx,
	}
})
