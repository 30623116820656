<template>
	<modal class="z-50">
		<template #default="handlers">
			<div
				class="bg-white p-6 rounded-xl overflow-hidden drop-shadow-md w-full grid md:grid-cols-[2fr,3fr] gap-4 items-start"
			>
				<div class="p-0">
					<img
						:src="props.asset.image"
						class="rounded-md h-48 md:h-auto mx-auto"
					/>
					<div class="font-poppins text-lg text-center mt-2">
						<p>{{ props.asset.name }}</p>
					</div>
				</div>
				<div class="flex flex-col h-full">
					<StorefrontChoosePaymentMethod v-if="null == state.paymentMethod"
						class="flex-grow"
						:bpx-price="totalBpx"
						:quantity="props.listing.quantity"
						:unit-price="props.listing.price"
						@payment-method="handlePaymentMethodSelection"
						:enabled=" ! state.processingPurchase"
					/>
					<StorefrontConfirmBpxPayment v-if="'bpx' == state.paymentMethod"
						class="flex-grow"
						:bpx-price="totalBpx"
						:quantity="props.listing.quantity"
						:unit-price="props.listing.price"
						@confirm="handlePayWithBPX"
						@payment-method="handlePaymentMethodSelection"
						:enabled=" ! state.processingPurchase"
					/>


					<div class="mt-4">
						<button
							class="text-gray-500 w-full"
							@click.stop="$emit('close')"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import ToUsd from '../ToUsd.vue'

import { Asset, Listing } from '@/types/Asset'
import { Storefront } from '@/types/Storefront'
import Currency from '@/types/Currency'
import { humanReadablePrice } from '@/util/currencyFormat'
import api from '@/util/api'
import { useAuthStore } from '@/stores/AuthStore'
import { useNewWalletStore } from '@/stores/NewWalletStore'
import { computed, reactive } from 'vue'
import BN from 'bn.js'
import StorefrontChoosePaymentMethod from '@/components/StorefrontChoosePaymentMethod.vue';
import StorefrontConfirmBpxPayment from '@/components/StorefrontConfirmBpxPayment.vue';
import { useMarketStore } from '@/stores/MarketStore'
import sleep from '@/util/sleep'
import { useRoute, useRouter } from 'vue-router'

const authStore = useAuthStore()
const newWalletStore = useNewWalletStore();
const router = useRouter();
const route = useRoute();


const props = defineProps<{
	asset: Asset
	listing: Listing
}>()

const state = reactive({
	paymentMethod: null as null | String,
	processingPurchase: false,
});

const totalBpx = computed(() => {
	return String(props.listing.price * props.listing.quantity);
});

function handlePaymentMethodSelection(method: string) {
	state.paymentMethod = method;
	if (state.paymentMethod == 'cc') {
		handlePayWithCreditCard();
	}
}

async function handlePayWithBPX() {
	state.processingPurchase = true;
	const marketStore = useMarketStore();
	const s = sleep(1.5);
	const result = await marketStore.buyWithBpx(props.listing.id);
	await s;

	if (result.success) {
		// console.log('redirect user?');
		router.replace({
			path: route.fullPath,
			query: {
				success: 'Purchase completed successfully!',
			}
		});
		// token has been purchased. sweet.
		// now we have to notify the user, and update the user interface
		// (close modal, remove listing, show how many the user holds, etc)
		// probably best to just reload the whole page.
	} else {
		alert(result.message);
	}
}

async function handlePayWithCreditCard() {
	state.processingPurchase = true;
	const resp = await api.createOrder(
		props.asset.asset_identifier,
		props.listing.id,
		authStore.token.data.accessToken
	)

	if (resp.success !== undefined && resp.success) {
		top.location.href = resp.redirect
		return;
	}

	state.processingPurchase = false;
	alert('Failed to initiate checkout.');
}

</script>
