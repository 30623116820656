<template>
    <div class="flex flex-col h-full">
        <h1 class="text-2xl font-semibold flex justify-between items-center">
            Pay with BPX
			<button class="text-gray-400 text-sm font-normal text-right flex justify-end items-baseline space-x-1
				hover:text-gray-600"
				@click="emit('payment-method', null)">
				<i class="fa fa-turn-down-left fa-flip-vertical" />
				<span>Back</span>
			</button>
        </h1>

        <div class="mt-4">
            <div class="text-right">
                <span class="inline-block space-x-2 rounded-lg px-2 py-2">
                    <span class="font-bold">Current Balance</span>
                    <span class="text-right">
						{{ $format(humanReadablePrice(
							newWalletStore.visibleBpx
						)) }}
						{{ $token }}
					</span>
                </span>
            </div>

            <div class="grid grid-cols-[3fr,4fr] border-b border-t border-gray-200 px-2 py-4">
                <div class="order-1 text-right">
                    <p class="mr-8 text-right"><strong>Price</strong></p>
					{{ $format(humanReadablePrice(props.unitPrice)) }}
					{{ $token }}
                </div>

                <div class="text-center">
                    <strong>Quantity</strong><br />
                    {{ props.quantity }}
                </div>
            </div>

            <p class="mt-2 text-right">
                <strong>Total</strong>
				{{ $format(humanReadablePrice(bpxPrice)) }}
				{{ $token }}
            </p>

            <p class="mt-2 text-right">
                <strong>Remaining Balance</strong>
				{{ $format(humanReadablePrice(bpxRemaining)) }}
				{{ $token }}
            </p>
        </div>

        <div class="mt-6 space-y-2">
            <button class="w-full rounded-md border border-transparent bg-sky-600 px-8 py-3 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50
				group
				disabled:bg-gray-300 disabled:text-gray-400"
				:disabled="bpxRemaining < 0 || props.enabled == false"
				@click="emit('confirm', true)">
				Complete Purchase
			</button>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineComponent, PropType, defineProps, withDefaults, computed } from "vue";
import { humanReadablePrice } from '@/util/currencyFormat';
import { useNewWalletStore } from "@/stores/NewWalletStore";
import BN from "bn.js";

const props = withDefaults(defineProps<{
    unitPrice: String | Number,
    quantity?: Number,
    bpxPrice: String| Number,
	enabled?: Boolean
}>(), {
    quantity: 1,
	enabled: true,
});

const emit = defineEmits<{
	(event: 'payment-method', method: string | null): void,
	(event: 'confirm', confirmed: boolean): void,
}>();

const newWalletStore = useNewWalletStore();

const bpxRemaining = computed(() => {
    const current = new BN(newWalletStore.visibleBpx)
    const price = new BN(String(props.bpxPrice));
    return current.sub(price).toString();
})
</script>
