<template>
    <div class="relative">
        <h1 class="my-4 pb-1 text-xl font-bold text-left flex justify-between items-center">
            Your Collection
        </h1>

        <div class="border-b-2 border-gray-300 flex space-x-4">
                <router-link
                    :to="{ name: 'account', params: {  tab:`items`}}">
                    <h2 v-if="route.params.tab != `account-activity`" class="px-4 text-lg font-medium pb-1 border-b-2 border-gray-500">Items</h2>
                    <h2 v-if="route.params.tab == `account-activity`" class="px-4 text-lg font-medium text-gray-500">Items</h2>
                </router-link>
                <router-link
                    :to="{ name: 'account-activity', params: { tab: `account-activity`} }">
                    <span v-if="route.params.tab != `account-activity`" class="px-4 text-lg font-medium text-gray-500">Activity</span>
                    <span v-if="route.params.tab == `account-activity`" class="px-4 text-lg font-medium pb-1 border-b-2 border-gray-500">Activity</span>
                </router-link>
            </div>

        <div class="relative md:flex mt-6">
            <div v-if="route.params.tab != `account-activity`" v-show="state.showCollectionFilters" v-cloak
                class="relative flex-grow-0 flex-shrink-0 flex flex-col md:w-1/4">
                <account-collection-filters
                    class="
                    fixed inset-0 h-screen z-50
                    flex flex-col
                    bg-gray-50
                    md:sticky md:top-[85px] md:h-auto md:z-auto md:overflow-y-auto
                    md:py-2
                    md:flex-shrink-0"

                    :loading="state.loading"
                    :assetStorefronts="assetStorefronts"
                    :filteredStorefronts="filteredStorefronts"
                    @toggle-storefront="toggleStorefront"
                    @done="toggleCollectionFilters()"
                    @close="toggleCollectionFilters()"
                    @clear="clearFilters"
                />
                <div class="flex-grow flex-shrink"></div>
            </div>

            <div class="flex-grow ml-4">
                <div v-if="route.params.tab != `account-activity`" class="flex items-center space-x-2">
                    <button class="bg-white shadow px-4 py-2 md:aspect-auto border border-gray-300 rounded-xl space-x-2 font-medium"
                        @click.prevent="toggleCollectionFilters()">
                        <i class="fa-solid fa-bars-filter"></i>
                        <span class="hidden md:inline-block">Filters</span>
                        <span class="md:hidden" v-if="activeFilterCount > 0">
                            {{ activeFilterCount }}
                        </span>
                    </button>

                    <p v-if="state.loading"><loading-placeholder class="w-36 rounded-lg">&nbsp;</loading-placeholder></p>
                    <p v-else class="font-medium">{{ $format(totalItems) }} <pluralize :amount="totalItems" singular="item" plural="items" /></p>
                </div>

                <div class="flex flex-wrap gap-2 -m-2
                    my-4">
                    <div v-if="activeFilterCount > 0"
                        class="hidden md:block">
                        <filter-summary
                            :filters="storeStore.state.activeFilters" @removeFilter="storeStore.removeFilter"
                            class="flex-wrap my-1 mx-2 first:ml-0 last:mr-0 px-2">
                            <template #default
                                v-if="state.filteredStorefrontIDs.length">
                                <div
                                    v-for="storefront in filteredStorefronts" :key="`f-sf-${storefront.id}`"
                                    class="bg-gray-200/80 text-gray-900 rounded-lg px-4 flex justify-center items-center space-x-2">
                                    <storefront-filter-item
                                        class="max-w-[18rem]"
                                        :storefront="storefront"
                                    />
                                    <button class="min-w-[2rem] flex-shrink-0"
                                        @click.prevent="toggleStorefront(storefront)"
                                    >
                                        <span><i class="fa fa-times" /></span>
                                    </button>
                                </div>
                            </template>
                        </filter-summary>
                    </div>
                    <!-- <div v-if="state.filteredStorefrontIDs.length"
                        class="flex flex-wrap first:ml-0">
                        <div v-for="storefront in filteredStorefronts" :key="`f-sf-${storefront.id}`"
                            class="flex bg-gray-200/80 mx-2 my-1 px-2 rounded-lg">
                                <storefront-filter-item
                                    class="max-w-[18rem]"
                                    :storefront="storefront"
                                />
                                <button class="min-w-[2rem] flex-shrink-0"
                                    @click.prevent="toggleStorefront(storefront)"
                                >
                                    <span><i class="fa fa-times" /></span>
                                </button>
                        </div>
                    </div> -->
                </div>
                <div v-if="route.params.tab != `account-activity`">
                    <tile-loader v-if="state.loading" :count="36"
                        class="mt-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                        <loading-tile />
                    </tile-loader>
                    <div v-else class="mt-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                        <storefront-asset v-for="asset in assets" :key="`asset-${asset.id}`"
                            :asset="asset"
                            :slug="asset.storefront.slug"
                            :show-storefront-name="true"
                            :balance="asset.balance"
							@asset-transferred="loadUserCollection(true)"
                        />
                    </div>
                </div>
                <div v-if="route.params.tab == `activity` || route.params.tab == `account-activity`">
                    <ActivityTable  />
                </div>

            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Asset, Attribute } from "@/types/Asset";
import { defineComponent, onMounted, PropType, reactive, computed } from "vue";
import StorefrontAsset from "@/components/StorefrontAsset.vue";
import StorefrontFilterItem from "@/components/StorefrontFilterItem.vue";
import StorefrontFilters from "@/components/StorefrontFilters.vue";
import FilterSummary from "@/components/FilterSummary.vue";
import AccountCollectionFilters from "@/components/AccountCollectionFilters.vue"
import { useMarketStore } from "@/stores/MarketStore";
import { useAccountStore } from "@/stores/AccountStore";
import TileLoader from "@/components/TileLoader.vue";
import { useRoute } from "vue-router";
import ActivityTable from "@/components/ActivityTable.vue";
import LoadingPlaceholder from "@/components/LoadingPlaceholder.vue";
import LoadingTile from "@/components/LoadingTile.vue";
import { Storefront } from "@/types/Storefront";
import { useStorefrontStore } from "@/stores/StorefrontStore";
import { useAppStore } from "@/stores/AppStore";
import sleep from "@/util/sleep";

export default defineComponent({
    props: ['tab'],
    components: { AccountCollectionFilters, ActivityTable, StorefrontAsset, StorefrontFilterItem, StorefrontFilters, FilterSummary, TileLoader, LoadingPlaceholder, LoadingTile },
    setup(props) {
        const marketStore = useMarketStore();
        const accountStore = useAccountStore();
        const storeStore = useStorefrontStore();
        const appStore = useAppStore();
        const route = useRoute();
        const state = reactive({
            loading: true,
            showCollectionFilters: true,
            filteredStorefrontIDs: [] as string[],
        });

        const activeFilterCount = computed(() => {
            return (Object.keys(storeStore.state.activeFilters).reduce((c, key) => storeStore.state.activeFilters[ key ].length, 0))
                    + (state.filteredStorefrontIDs.length ?? 0)
        });

		onMounted(async() => {
			const wait = sleep(.75);
			toggleCollectionFilters(window.innerWidth > 768);
			state.loading = true;
			await loadUserCollection();
			await wait;
			state.loading = false
		});

		async function loadUserCollection(force: boolean = false) {
			return Promise.all([
				marketStore.load(),
				accountStore.load(force)
			])
		}

        const assetStorefronts = computed(() => {
            if (state.loading) return []

            return marketStore.storeFronts.filter(function(sf) {
                for (const asset of accountStore.assets) {
                    if (asset.storefront.id == sf.id) {
                        return true;
                    }
                }

                return false;
            });
        });

        const filteredStorefronts = computed(() => {
            if (state.loading) return []

            return assetStorefronts.value.filter((storefront) => {
                return state.filteredStorefrontIDs.indexOf(storefront.id) >= 0;
            });
        });



        const assets = computed(() => {
            if (state.loading) return []

            return accountStore.assets.filter((asset) => {
                if (state.filteredStorefrontIDs.length == 0 || state.filteredStorefrontIDs.indexOf(asset.storefront.id) >= 0) {
                    return true;
                }

                return false;
            }).filter(function(asset) {
                if (storeStore.state.activeFilters['Listed'] !== undefined) {
                    if (storeStore.state.activeFilters['Listed'].indexOf('Yes') >= 0) {
                        if ((asset.listings ?? []).length == 0) {
                            return false;
                        }
                    }
                }

                if (state.filteredStorefrontIDs.length != 1) {
                    return true;
                }

				const activeFilters = Object.values(storeStore.state.activeFilters).filter(f => f.length)

                if (activeFilters.length == 0) {
                    return true
                }

				let matches = true;
				for (const key in storeStore.state.activeFilters) {
					if (key == 'Listed') continue;

					let keyMatches = false
					for (const attr of asset.attributes) {
						if (attr.trait_type == key) {
							if (storeStore.state.activeFilters[ key ].indexOf(attr.value) >= 0) {
								keyMatches = true
								break;
							}
						}
					}

                    matches = matches && keyMatches;
                    if ( ! matches) {
                        break;
                    }
                }

                return matches;
            });
        });



        const totalItems = computed(() => {
            return assets.value.reduce((total, asset) => total + asset.balance.available, 0)
        });

        function storefrontSelected(storefront:Storefront) {
            return state.filteredStorefrontIDs.indexOf(storefront.id) >= 0;
        }

        function toggleStorefront(storefront: Storefront) {
            let currentIndex = state.filteredStorefrontIDs.indexOf(storefront.id);
            if (currentIndex >= 0) {
                state.filteredStorefrontIDs.splice(
                    currentIndex, 1
                )
            } else {
                state.filteredStorefrontIDs.push(storefront.id)
            }

            if (state.filteredStorefrontIDs.length == 1) {
                storeStore.loadStore(state.filteredStorefrontIDs[0])
            }

            storeStore.clearFilters()
        }

        function clearFilters() {
            state.filteredStorefrontIDs = [];
            storeStore.clearFilters();
        }

        function toggleCollectionFilters(force: boolean) {
            state.showCollectionFilters = undefined == force
                ? ! state.showCollectionFilters
                : force;

            appStore.mobileOnlyOverlay = state.showCollectionFilters;
        }

        return {
            state,
            marketStore,
            accountStore,
            storeStore,
            assetStorefronts,
            filteredStorefronts,
            assets,
            totalItems,
            toggleStorefront,
            route,
            storefrontSelected,
            clearFilters,
            toggleCollectionFilters,
            activeFilterCount,
			loadUserCollection,
        }
    }
})
</script>
