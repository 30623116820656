import { useRoute } from 'vue-router';
import api from '@/util/api';
import { Asset } from '@/types/Asset';

export async function useAsset(slug?: string, assetId?: number, user?): Promise<Asset> {
	const route = useRoute();
	console.log(route);
	const id = assetId || route.params.assetId;
	const storefront = slug || route.params.slug;
	let aUser = user || {};

	console.log(slug, id, storefront, aUser);

	const queryPath = {
		eth: 'ethereum',
		poly: 'polygon',
	};

	/* @ts-ignore */
	const asset = await api.loadAsset(storefront, id, aUser.id);

	return {
		/* @ts-ignore */
		asset,
	}
};
