<template>
	<transition name="fade" mode="out-in">
		<slot v-if="! state.connected" name="default"></slot>
		<slot v-else name="connected" :wallet="metamask.state.wallet" />
	</transition>
</template>
<script lang="ts">
import { defineComponent, reactive, onMounted } from 'vue';
import metamask from '@/util/metamask';

export default defineComponent({
	setup(props, { emit: $emit }) {
		let ethInterval : any;
		const state = reactive({
			connected: metamask.state.connected,
		});

		onMounted(() => {
			checkEthereum();
			ethInterval = setInterval(checkEthereum, 300);
		});

		function checkEthereum() {
			if (window.ethereum !== undefined) {
				clearInterval(ethInterval);
				attachEvents();
				metamask.init(window.ethereum);
			}
		}

		function attachEvents() {
			metamask.on('connected', async(wallet : string) => {
				state.connected = true;
				$emit('connected', wallet)
			});
		}

		return {
			state,
			metamask,
		};
	}
})
</script>
