<template>
	<div class="relative">
		<div class="mt-2 flex justify-start items-stretch rounded-xl overflow-hidden border border-gray-200 bg-white">
			<div class="flex-shrink-0 bg-gray-200 text-gray-500 aspect-square px-4 text-lg flex justify-center items-center">
				<i class="fa-solid fa-at" />
			</div>
			<input type="text"
				ref="input"
				class="flex-grow bg-transarent
				w-full text-lg text-gray-600
				border-none ring-0 outline-none
				focus:border-none focus:ring-0 focus:ouline-none"
				v-model="state.query"
				@focus="emit('focus')"
				@keyup="handleInput">
		</div>

		<div v-if="state.hasSearched"
			class="absolute border border-gray-200 rounded-xl w-full bg-white
			top-full mt-2 overflow-hidden">
			<div v-if="state.results.nbHits == 0">
				<p>No results found</p>
			</div>
			<div v-else>
				<div v-for="(item, idx) in state.results.hits.slice(0, 3)" :key="`result-${item.objectID}`"
					@click="selectItem(item)"
					class="flex justify-start items-center space-x-2 py-2 px-4
					cursor-pointer hover:bg-gray-100"
					:class="[ idx == state.highlightedIndex? 'bg-blue-100' : 'bg-white' ]">
						<div class="flex-grow-0 flex-shrink-0">
							<div class="aspect-square w-8 h-8 rounded-full bg-gray-400"></div>
						</div>
						<div class="flex-grow">
							@{{ item.username }}
						</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { useUserProfileStore } from "@/stores/UserProfileStore";
import { withDefaults, defineProps, defineEmits, PropType, reactive, ref, onMounted } from "vue";
const userProfileStore = useUserProfileStore();
const props = withDefaults(defineProps<{
	focusOnLoad?: boolean | null;
}>(), {
	focusOnLoad: true
});
const emit = defineEmits<{
	selected: [result: { username: string; objectID: string; } | null]
	focus: []
}>();
const state = reactive({
	query: null,
	results: [],
	highlightedIndex: 0,
	selected: null,
	searching: false,
	hasSearched: false,
	lastSelected: null,
});

const input = ref(null);

onMounted(() => {
	if (props.focusOnLoad) {
		input.value.focus();
	}
});

function handleInput($evt) {
	if ($evt.code == 'Escape') {
		// hide search results
		cancelSearch();
		return false;
	} else if ($evt.code == 'Enter') {
		// select the currently-highlighted index item
		const hit = state.results.hits[ state.highlightedIndex ] ?? null;
		if (hit) {
			selectItem(hit);
			$evt.target.blur();
			return false;
		}
	} else if (['ArrowUp', 'ArrowDown'].indexOf($evt.code) >= 0) {
		// handle moving highlighted index.
		handleResultNavigation( $evt.code == 'ArrowDown'? +1 : -1 );
		$evt.preventDefault();
		$evt.stopPropagation();
		$evt.cancelBubble = true;
		return false;
	}

	search();
}

async function search() {
	if (state.query.trim().length == 0) {
		state.hasSearched = false;
		state.results = [];
	}

	state.searching = true;
	state.results = await userProfileStore.userIndex.search( state.query )
	emit('selected', null);
	state.searching = false;
	state.hasSearched = true;
	state.highlightedIndex = 0;
}

function selectItem(item) {
	state.query = item.username;
	state.lastSelected = item;
	state.hasSearched = false;

	emit('selected', item);
}

function handleResultNavigation(step: number) {
	state.highlightedIndex = Math.abs((state.highlightedIndex + step) % (state.results.hits?.length ?? 0));
}

function cancelSearch() {
	if (state.lastSelected) {
		selectItem(state.lastSelected);
	}

	state.hasSearched = false;
}
</script>
