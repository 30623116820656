<script lang="ts">
import { computed, defineComponent } from 'vue'
import metamask from '@/util/metamask'
import WordmarkSvg from "@/../public/image/logo-wordmark.v2.min.svg";

const addTokenToMetamask = async () => {
	await metamask.watchAsset(
		import.meta.env.VITE_BPX_ADDRESS,
		'BPX',
		9,
		'https://bpx.auction/image/bpx-logo@2000.png'
	)
}

const bpxAddress = import.meta.env.VITE_BPX_ADDRESS
const auctionAddress = import.meta.env.VITE_AUCTION_ADDRESS

export default defineComponent({
	components: { WordmarkSvg },
	setup(props) {
		function shortenAddress(address: string | undefined): string {
			if (undefined == address || address.length == 0) {
				return ''
			}

			return `${address.substr(0, 8)}...${address.substr(-6)}`
		}

		const shortBpxAddress = computed(() => {
			return shortenAddress(bpxAddress)
		})

		const shortAuctionAddress = computed(() => {
			return shortenAddress(auctionAddress)
		})

		return {
			auctionAddress,
			bpxAddress,
			shortAuctionAddress,
			shortBpxAddress,
			props,
			addTokenToMetamask,
		}
	},
})
</script>

<template>
	<footer class="bg-gray-900 text-center py-12 text-slate-300">
		<div class="max-w-screen-lg mx-auto w-full grid gap-y-8">
			<div>
				<WordmarkSvg class="max-w-[18rem] sm:max-w-[24rem] mx-auto bg-slate-" fill="rgb(71, 85, 105)" />
				<div class="inline-flex gap-2 mt-4">
					<a
						href="https://bpxcollect.com/terms"
						target="_blank"
						rel="noreferrer nofollow"
						class="block underline-offset-2 underline hover:no-underline"
					>
						Terms
					</a>
					&middot;
					<a
						href="https://bpxcollect.com/privacy"
						target="_blank"
						rel="noreferrer nofollow"
						class="block underline-offset-2 underline hover:no-underline"
					>
						Privacy
					</a>
				</div>
			</div>

			<div v-if="false"
				class="text-slate-300/60 grid md:grid-cols-2 gap-x-4 gap-y-8 max-w-prose mx-auto mt-12 md:mt-6"
			>
				<div class="text-sm text-slate-300/80 space-y-2">
					<div class="text-base font-poppins">$BPX Token</div>

					<div class="leading-6">
						<a
							:href="`https://etherscan.io/address/${bpxAddress}`"
							target="_blank"
							rel="noreferrer nofollow"
							class="text-sm font-mono underline decoration-dotted hover:decoration-slate-300/0 text-slate-300/60 hover:text-slate-300/100 transition-colors underline-offset-4"
							:title="bpxAddress"
						>
							{{ shortBpxAddress }}
						</a>
					</div>

					<div>
						<button
							class="border border-orange-400/80 text-white/50 hover:border-orange-400/100 hover:text-white transition-colors rounded px-2 py-1 inline-block w-full"
							@click="addTokenToMetamask"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								xml:space="preserve"
								id="Layer_1"
								x="0"
								y="0"
								version="1.1"
								viewBox="0 0 318.6 318.6"
								class="w-6 aspect-square inline-block"
							>
								<path
									fill="#e2761b"
									stroke="#e2761b"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="m274.1 35.5-99.5 73.9L193 65.8z"
								/>
								<path
									d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3zm-204.4.9L50.1 263l56.7-15.6-26.5-40.6z"
									fill="#e4761b"
									stroke="#e4761b"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="m103.6 138.2-15.8 23.9 56.3 2.5-2-60.5zm111.3 0-39-34.8-1.3 61.2 56.2-2.5zM106.8 247.4l33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"
									fill="#e4761b"
									stroke="#e4761b"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									fill="#d7c1b3"
									stroke="#d7c1b3"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="m211.8 247.4-33.9-16.5 2.7 22.1-.3 9.3zm-105 0 31.5 14.9-.2-9.3 2.5-22.1z"
								/>
								<path
									fill="#233447"
									stroke="#233447"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z"
								/>
								<path
									fill="#cd6116"
									stroke="#cd6116"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="m106.8 247.4 4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1 20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z"
								/>
								<path
									fill="#e4751f"
									stroke="#e4751f"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="m87.8 162.1 23.6 46-.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z"
								/>
								<path
									d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z"
									fill="#f6851b"
									stroke="#f6851b"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									fill="#c0ad9e"
									stroke="#c0ad9e"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="m180.3 262.3.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z"
								/>
								<path
									fill="#161616"
									stroke="#161616"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z"
								/>
								<path
									fill="#763d16"
									stroke="#763d16"
									stroke-linecap="round"
									stroke-linejoin="round"
									d="m278.3 114.2 8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z"
								/>
								<path
									d="m267.2 153.5-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4 3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z"
									fill="#f6851b"
									stroke="#f6851b"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>

							Add to Metamask
						</button>
					</div>

					<div>
						<a
							href="https://app.uniswap.org/#/tokens/ethereum/0x313408eb31939a9c33b40afe28dc378845d0992f"
							target="_blank"
							rel="nofollow noreferrer"
							class="border border-[#e6006e] text-white/50 hover:border-[#FF007A] hover:text-white transition-colors rounded px-2 py-1 inline-block w-full"
						>
							<svg
								viewBox="0 0 65 64"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								class="w-6 aspect-square inline-block"
							>
								<path
									d="M28.6536 27.4729C29.8667 29.6182 27.1537 30.2831 25.8806 30.4055C23.9513 30.5939 23.5519 29.477 23.9288 28.0738C24.0524 27.5714 24.3269 27.1194 24.7149 26.7791C25.103 26.4388 25.5859 26.2267 26.0981 26.1715C26.6028 26.125 27.1103 26.2224 27.5623 26.4527C28.0144 26.6829 28.3926 27.0365 28.6536 27.4729V27.4729Z"
									fill="#FF007A"
								/>
								<path
									d="M36.2271 23.5039C34.8171 34.3378 53.8254 32.0832 53.4635 40.7058C55.3384 38.2573 56.1447 31.6048 50.6248 28.1806C45.7068 25.1275 39.2983 26.7981 36.2271 23.5039Z"
									fill="#FF007A"
								/>
								<path
									d="M47.1758 19.419C47.052 19.3041 46.9245 19.1911 46.8008 19.0781C46.9264 19.193 47.052 19.3136 47.1758 19.419Z"
									fill="#FF007A"
								/>
								<path
									d="M51.5943 27.3155L51.583 27.2986C51.4115 26.9784 51.2159 26.6717 50.9981 26.3813C50.3572 25.5092 49.438 24.8842 48.3938 24.6108C47.6987 24.4306 46.9879 24.3183 46.2713 24.2756C45.5457 24.2228 44.8051 24.1927 44.057 24.1531C42.5571 24.0702 41.0215 23.9158 39.5215 23.4864C39.1465 23.379 38.7715 23.2622 38.4097 23.121C38.2222 23.0532 38.0478 22.9797 37.8622 22.9006C37.6766 22.8215 37.4872 22.7311 37.2997 22.635C36.5944 22.2469 35.9411 21.7704 35.3554 21.2168C34.2004 20.1375 33.293 18.9132 32.3836 17.7266C31.5263 16.5355 30.6063 15.3912 29.6274 14.2987C28.6617 13.2284 27.5201 12.3327 26.2525 11.6505C24.94 10.9864 23.5144 10.5774 22.0508 10.4451C23.5692 10.2794 25.1051 10.4676 26.5394 10.995C27.9867 11.5594 29.3097 12.4035 30.4337 13.4794C31.1675 14.1707 31.8564 14.9087 32.4961 15.6887C37.2585 14.7469 41.1209 15.5832 44.0889 17.2068L44.1564 17.2407C45.1011 17.7554 45.9864 18.3732 46.7963 19.0828C46.9257 19.1958 47.0532 19.3088 47.1713 19.4237C47.8035 20.0072 48.3919 20.6367 48.9319 21.3072L48.9731 21.3618C50.7262 23.5918 51.5924 25.9104 51.5943 27.3155Z"
									fill="#FF007A"
								/>
								<path
									d="M51.5931 27.3154L51.582 27.293L51.5931 27.3154Z"
									fill="#FF007A"
								/>
								<path
									d="M22.2917 11.8398C23.5067 12.0169 24.746 12.5028 25.5447 13.4295C26.3435 14.3562 26.6378 15.5616 26.8684 16.7087C27.0559 17.5977 27.2041 18.5037 27.5528 19.3456C27.7215 19.7562 27.969 20.1159 28.1753 20.5039C28.3459 20.826 28.6571 21.1161 28.7771 21.4607C28.7986 21.5102 28.8066 21.5645 28.8003 21.6181C28.7941 21.6718 28.7738 21.7227 28.7415 21.7659C28.3178 22.2368 27.1741 21.7131 26.7428 21.5022C25.9973 21.131 25.3296 20.6195 24.776 19.9954C23.0454 18.0648 22.1511 15.2866 22.2055 12.7571C22.2175 12.45 22.2463 12.1438 22.2917 11.8398Z"
									fill="#FF007A"
								/>
								<path
									d="M42.613 33.6133C39.988 40.9721 51.894 45.9087 47.4335 53.39C52.0102 51.4914 54.1833 45.7562 52.284 41.2056C50.6227 37.2108 45.7104 35.7548 42.613 33.6133Z"
									fill="#FF007A"
								/>
								<path
									d="M26.8672 43.2331C27.5825 42.6915 28.3646 42.2452 29.194 41.9052C30.0331 41.5678 30.9036 41.3154 31.7927 41.1518C33.557 40.8147 35.3026 40.7318 36.7688 40.1385C37.4932 39.8544 38.1598 39.4394 38.7356 38.9142C39.2929 38.3941 39.7196 37.7486 39.9806 37.0307C40.2479 36.2733 40.3564 35.4685 40.2993 34.667C40.2331 33.8059 40.039 32.9597 39.7237 32.1562C40.3772 32.8391 40.8531 33.6733 41.1092 34.5849C41.3653 35.4966 41.3936 36.4576 41.1918 37.3829C40.9624 38.3625 40.4672 39.2589 39.7612 39.9728C39.0624 40.6643 38.2197 41.1918 37.2938 41.5172C36.4101 41.8285 35.4887 42.0186 34.5545 42.0823C33.6545 42.1576 32.7845 42.1783 31.9295 42.2292C30.2009 42.302 28.4937 42.6406 26.8672 43.2331V43.2331Z"
									fill="#FF007A"
								/>
								<path
									d="M43.6594 56.1698C43.3951 56.3808 43.1307 56.6049 42.8401 56.8008C42.5476 56.9944 42.2412 57.1656 41.9232 57.3131C41.2613 57.6375 40.5335 57.8032 39.797 57.7972C37.8021 57.7595 36.3921 56.2678 35.5672 54.582C35.0047 53.4331 34.6166 52.1919 33.9491 51.0976C32.9947 49.5324 31.3617 48.2723 29.4492 48.5059C28.6693 48.6038 27.938 48.956 27.5049 49.636C26.3649 51.4121 28.0018 53.9002 30.0886 53.548C30.2661 53.5209 30.4398 53.4728 30.6061 53.4049C30.7718 53.334 30.9265 53.2394 31.0654 53.1242C31.3569 52.8804 31.5767 52.5613 31.701 52.2013C31.8381 51.8259 31.8686 51.4197 31.7892 51.0279C31.7035 50.6184 31.4629 50.2585 31.1179 50.024C31.519 50.2126 31.8315 50.5502 31.9898 50.9657C32.1539 51.3933 32.1961 51.8583 32.1116 52.3087C32.0297 52.7779 31.8238 53.2163 31.5154 53.5781C31.3516 53.7643 31.1622 53.926 30.9529 54.0584C30.7455 54.1893 30.5234 54.2949 30.2911 54.373C29.8201 54.5347 29.3188 54.5869 28.8249 54.5255C28.1314 54.4263 27.4772 54.1413 26.9312 53.7006C26.2862 53.1883 25.8062 52.5177 25.2231 51.9414C24.5546 51.2368 23.766 50.6582 22.8944 50.2331C22.2934 49.9679 21.6644 49.772 21.0195 49.6492C20.6951 49.5814 20.367 49.5324 20.0389 49.491C19.8889 49.4759 19.1539 49.312 19.0508 49.4081C20.0649 48.4702 21.1705 47.6373 22.3507 46.9219C23.5625 46.1995 24.8636 45.6403 26.2206 45.2587C27.6275 44.8609 29.099 44.7476 30.5498 44.9254C31.2966 45.0155 32.0281 45.2055 32.7247 45.4904C33.4547 45.7833 34.1285 46.2014 34.7159 46.726C35.2974 47.2762 35.7673 47.9344 36.0996 48.6641C36.3997 49.3473 36.6236 50.0617 36.7671 50.7943C37.1946 52.9848 37.0371 56.3808 39.8927 56.8818C40.0415 56.9108 40.1917 56.9321 40.3426 56.9458L40.8095 56.9571C41.1305 56.9342 41.4494 56.8882 41.7639 56.8196C42.4153 56.6657 43.0502 56.4481 43.6594 56.1698V56.1698Z"
									fill="#FF007A"
								/>
								<path
									d="M27.0671 53.7814L26.9922 53.7227L27.0671 53.7814Z"
									fill="#FF007A"
								/>
								<path
									d="M24.5051 22.8672C24.4011 23.2686 24.2195 23.6456 23.9707 23.9766C23.5076 24.5804 22.8946 25.0513 22.1933 25.3421C21.5624 25.6152 20.8944 25.7917 20.2115 25.8657C20.0633 25.8846 19.9096 25.8959 19.7615 25.9072C19.3289 25.9237 18.913 26.0789 18.5746 26.35C18.2362 26.6211 17.9931 26.994 17.8809 27.414C17.8295 27.6229 17.7913 27.8349 17.7665 28.0487C17.699 28.6025 17.6878 29.1788 17.6278 29.8757C17.4809 31.0616 17.1394 32.2148 16.6172 33.2886C15.9385 34.7238 15.1772 35.8803 15.3535 37.534C15.4697 38.6076 16.0172 39.3271 16.7447 40.0692C18.0571 41.4177 20.9952 42.0205 20.3389 45.3429C19.9433 47.3263 16.6697 49.4075 12.0686 50.1346C12.5261 50.0649 11.4817 48.2963 11.4161 48.1833C10.923 47.4073 10.383 46.6765 9.99113 45.8345C9.2224 44.2015 8.86616 42.3124 9.18115 40.5231C9.51302 38.6396 10.9005 37.1968 12.0536 35.7578C13.426 34.0457 14.866 31.8025 15.1847 29.58C15.2597 29.0413 15.3122 28.367 15.4322 27.6965C15.5466 26.9536 15.7783 26.2339 16.1184 25.5644C16.3506 25.1253 16.6563 24.7296 17.0222 24.3947C17.2129 24.2169 17.3387 23.98 17.3795 23.7218C17.4203 23.4636 17.3737 23.1992 17.2472 22.9708L9.91238 9.71852L20.4477 22.7787C20.5677 22.93 20.7193 23.053 20.8919 23.1388C21.0645 23.2247 21.2538 23.2713 21.4463 23.2755C21.6388 23.2796 21.8299 23.2411 22.006 23.1628C22.1821 23.0844 22.3388 22.9681 22.4651 22.822C22.5986 22.6657 22.6743 22.4679 22.6794 22.262C22.6845 22.0561 22.6188 21.8548 22.4933 21.6919C21.8052 20.8086 21.0777 19.9082 20.3727 19.023L17.7215 15.7269L12.4004 9.14594L5.14453 0L13.0829 8.55641L18.7471 14.8473L21.5727 18.0003C22.5101 19.0607 23.4476 20.0872 24.3851 21.2022L24.5388 21.3906L24.5726 21.6825C24.6183 22.0784 24.5954 22.4792 24.5051 22.8672Z"
									fill="#FF007A"
								/>
								<path
									d="M26.9321 53.6932C26.423 53.2981 25.9701 52.8349 25.5859 52.3164C25.9981 52.8101 26.4482 53.2704 26.9321 53.6932V53.6932Z"
									fill="#FF007A"
								/>
							</svg>

							Swap for more $BPX
						</a>
					</div>
				</div>

				<div class="text-sm text-slate-300/80 space-y-2">
					<div class="text-base font-poppins">Auction Contract</div>

					<div class="leading-6">
						<a
							:href="`https://etherscan.io/address/${auctionAddress}`"
							target="_blank"
							rel="noreferrer nofollow"
							class="text-sm font-mono underline decoration-dotted hover:decoration-slate-300/0 text-slate-300/60 hover:text-slate-300/100 transition-colors underline-offset-4"
							:title="auctionAddress"
						>
							{{ shortAuctionAddress }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>
