<template>
	<div
		:class="{
			'shadow-md rounded-xl overflow-hidden bg-cover aspect-square relative transition-all hover:scale-105 hover:shadow-2xl': true,
			'saturate-0': (! props.event.active || eventFinished) && inExtendedBidding == 0
		}"
		:style="`background-image: url('${$cdn(props.event.image)}')`"
	>
		<router-link
			:to="{
				name: 'auction-listings',
				params: { group: props.event.slug },
			}"
			class="absolute inset-0"
		>
			<countdown :to="props.event.end_at" v-if="props.event.active">
				<template #default="slotProps">
					<div
						class="inline-block bg-white/80 rounded-xl absolute right-0 bottom-0 mb-4 mr-4 text-center px-6 py-4 backdrop-blur"
					>
						<p class="text-xl text-black leading-none">
							{{ formatCountdown(slotProps.duration) }}
						</p>
						<p class="text-black/60">Remaining</p>
					</div>
				</template>
				<template #finished>
					<div v-if="inExtendedBidding[ props.event.id ]"
						class="inline-block bg-white/80 rounded-xl absolute right-0 bottom-0 mb-4 mr-4 text-center px-6 py-4 backdrop-blur"
						>
						In Extended Bidding
					</div>
					<div v-else
						class="bg-black/60 rounded-xl absolute right-0 bottom-0 mb-4 mr-4 text-center px-6 py-4 text-white/60 backdrop-blur"
					>
						Concluded
					</div>
				</template>
			</countdown>
			<div
				v-else-if="now < props.event.start_at"
				class="bg-black/60 rounded-xl absolute right-0 bottom-0 mb-4 mr-4 text-center px-6 py-4 text-white/60"
			>
				Soon
			</div>
			<div
				v-else
				class="bg-black/60 rounded-xl absolute right-0 bottom-0 mb-4 mr-4 text-center px-6 py-4 text-white/60 backdrop-blur"
			>
				Concluded
			</div>
		</router-link>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType, onMounted, onUnmounted, computed, ref } from "vue";
import { AuctionGroup } from "@/types/Auction";
import { DateTime } from "ts-luxon";
import { countdown } from '@/util/countdown'
import { keyDuration } from '@/util/timeRemainingLabel'
import { useAuctionStore } from '@/stores/AuctionStore'
import { StatusExtended } from '@/types/Auction'
import { useAuctionStatusStore } from '@/stores/AuctionStatusStore'
import Countdown from '@/components/Countdown.vue'

export default defineComponent({
	components: { Countdown },
	props: {
		event: {
			required: true,
			type: Object as PropType<AuctionGroup>
		}
	},
	setup(props) {
		const now = ref(DateTime.now())
		const auctionStore = useAuctionStore()
		const auctionStatusStore = useAuctionStatusStore()
		let intvl

		onMounted(async () => {
			intvl = setInterval(() => now.value = DateTime.now(), 100)
		})

		onUnmounted(() => {
			clearInterval(intvl)
		})

		const formatCountdown = function (duration): string {
			if (!duration) return ''
			const keyedDuration = keyDuration(duration)

			let unitGroups: string[][] = []

			if (keyedDuration.days.val > 0) {
				if (keyedDuration.hours.val > 0) {
					unitGroups.push(['Days', 'Hours'])
				} else {
					unitGroups.push(['Days', 'Minutes'])
				}
			} else if (keyedDuration.hours.val > 0) {
				unitGroups.push(['Hours', 'Minutes'])
			} else if (keyedDuration.minutes.val > 0) {
				unitGroups.push(['Minutes', 'Seconds'])
			} else {
				unitGroups.push(['Seconds'])
			}

			// console.log(keyedDuration);

			for (const units of unitGroups) {
				let output = []
				for (const unit of units) {
					const uKey = unit.toLowerCase()
					if ( undefined == keyedDuration[ uKey ]) {
						continue
					}

					output.push(`${keyedDuration[uKey].val} ${keyedDuration[uKey].label}`)
				}

				if (output.length == units.length) {
					return output.join(', ')
				}
			}
		}

		const eventFinished = computed(() => {
			const { finished } = countdown(props.event.end_at)
			return finished.value
		})

		const inExtendedBidding = computed(() => {
			if ( ! auctionStore.auctions) return {}

			return auctionStore.auctions.filter(
				a => a.auction_group.id == props.event.id
					&& StatusExtended == auctionStatusStore.status(a.id)
			).length
		})

		return {
			props,
			now,
			formatCountdown,
			eventFinished,
			inExtendedBidding,
		}
	}
})
</script>
