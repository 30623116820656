<template>
	<modal class="z-50">
		<div class="w-full bg-white rounded-xl overflow-hidden">
			<div class="p-4">
				<h1 class="font-bold text-3xl">
					<slot name="title">
						<span class="text-red-500">
							<i class="fas fa-exclamation-triangle"></i>
							Error!
						</span>
					</slot>
				</h1>
				<slot name="body">
				</slot>
			</div>
			<div class="bg-gray-100 border-t-2 border-slate-200 p-4 text-center">
				<slot name="buttons" :close="() => $emit('close')">
					<button class="btn-primary-lg mt-2"
						@click.stop="$emit('close')">
						Ok
					</button>
				</slot>
			</div>
		</div>
	</modal>
</template>
<script lang="ts" setup>
import { defineComponent, PropType } from "vue";
import Modal from '@/components/Modal.vue'
</script>
